import logo from "./logo.svg";
import "./App.css";
import { Outlet, Link, useLocation, useNavigate } from "react-router-dom";
import _ from "lodash";
import React, { Component, useState, useCallback } from "react";
import $ from "jquery";

import "./hamburger.css";
import "./hamburger";

import Gallery from "react-photo-gallery";
import Carousel, { Modal as M2, ModalGateway } from "react-images";
import { interview } from "./interview";
import { photos } from "./photos";
import { unlockPhotos } from "./unlock-photos";
import { walkthrough } from "./walkthrough";
import {
  MenuItem,
  Menu,
  SidebarPusher,
  SidebarPushable,
  ButtonGroup,
  GridRow,
  GridColumn,
  ItemExtra,
  Button,
  Checkbox,
  Grid,
  Header,
  Icon,
  Image,
  Segment,
  Label,
  Sidebar,
  ModalContent,
  ModalActions,
  MessageContent,
  MessageHeader,
  Message,
  Modal,
  DimmerDimmable,
  Dimmer,
  Input,
  ItemMeta,
  ItemImage,
  ItemHeader,
  CommentAvatar,
  CommentContent,
  CommentAuthor,
  CommentMetadata,
  CommentText,
  CommentActions,
  CommentAction,
  ItemGroup,
  ItemDescription,
  ItemContent,
  Item,
  Breadcrumb,
  BreadcrumbDivider,
  BreadcrumbSection,
  Container,
  TableRow,
  TableHeaderCell,
  TableHeader,
  TableCell,
  TableBody,
  Table,
  Divider,
} from "semantic-ui-react";
import { usePapaParse } from "react-papaparse";
import my_csv from "./SkyGunner Bestiary - Bestiary.csv";

const Hentai = () => {
  const [open, setOpen] = React.useState(true);
  const navigate = useNavigate();
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  return (
    <>
      <Breadcrumb>
        <BreadcrumbSection as={Link} to="/">
          Home
        </BreadcrumbSection>
        <BreadcrumbDivider />
        <BreadcrumbSection as={Link} to="/manga">
          Manga
        </BreadcrumbSection>
        <BreadcrumbDivider />
        <BreadcrumbSection>
          Hentai Doujinshi (Femme is kidnapped by Ventre)
        </BreadcrumbSection>
      </Breadcrumb>
      <Modal
        basic
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
        size="small"
        dimmer="blurring"
      >
        <Header icon>
          <Icon name="archive" />
          Are you 18 or older?
        </Header>
        <ModalContent>
          <p>
            Warning. This page has adult content and is intended for mature
            audiences.
          </p>
        </ModalContent>
        <ModalActions>
          <Button
            basic
            color="red"
            inverted
            onClick={() => {
              setOpen(false);
              navigate("/manga");
            }}
          >
            <Icon name="remove" /> No
          </Button>
          <Button color="green" inverted onClick={() => setOpen(false)}>
            <Icon name="checkmark" /> Yes
          </Button>
        </ModalActions>
      </Modal>
      <br />
      <br />
      <br />
      <br />
      <div style={{ textAlign: "center" }}>
        <Gallery photos={photos.hentai} onClick={openLightbox} />
        <ModalGateway>
          {viewerIsOpen ? (
            <M2 onClose={closeLightbox}>
              <Carousel
                currentIndex={currentImage}
                views={photos.hentai.map((x) => ({
                  ...x,
                  srcset: x.srcSet,
                  caption: x.title,
                }))}
              />
            </M2>
          ) : null}
        </ModalGateway>
      </div>
    </>
  );
};

const Comic1 = () => (
  <>
    <Breadcrumb>
      <BreadcrumbSection as={Link} to="/">
        Home
      </BreadcrumbSection>
      <BreadcrumbDivider />
      <BreadcrumbSection as={Link} to="/manga">
        Manga
      </BreadcrumbSection>
      <BreadcrumbDivider />
      <BreadcrumbSection>
        Part 1 - Ciel and Copain meet (flashback)
      </BreadcrumbSection>
    </Breadcrumb>
    <br />
    <br />
    <br />
    <br />
    <div style={{ textAlign: "center" }}>
      <img width="100%" src="/comic/atlus/skygunner1.jpg" />
      <br />
    </div>
  </>
);

const Comic2 = () => (
  <>
    {" "}
    <Breadcrumb>
      <BreadcrumbSection as={Link} to="/">
        Home
      </BreadcrumbSection>
      <BreadcrumbDivider />
      <BreadcrumbSection as={Link} to="/manga">
        Manga
      </BreadcrumbSection>
      <BreadcrumbDivider />
      <BreadcrumbSection>
        Part 2 - Ventre steals the Machine Soldier
      </BreadcrumbSection>
    </Breadcrumb>
    <br />
    <br />
    <br />
    <br />
    <div style={{ textAlign: "center" }}>
      <img width="100%" src="/comic/atlus/skygunner2.jpg" />
      <br />
    </div>
  </>
);

const Comic3 = () => (
  <>
    {" "}
    <Breadcrumb>
      <BreadcrumbSection as={Link} to="/">
        Home
      </BreadcrumbSection>
      <BreadcrumbDivider />
      <BreadcrumbSection as={Link} to="/manga">
        Manga
      </BreadcrumbSection>
      <BreadcrumbDivider />
      <BreadcrumbSection>
        Part 3 - Ventre invents Poulets (flashback)
      </BreadcrumbSection>
    </Breadcrumb>
    <br />
    <br />
    <br />
    <br />
    <div style={{ textAlign: "center" }}>
      <img width="100%" src="/comic/atlus/skygunner3.jpg" />
      <br />
    </div>
  </>
);

const Comic4 = () => (
  <>
    {" "}
    <Breadcrumb>
      <BreadcrumbSection as={Link} to="/">
        Home
      </BreadcrumbSection>
      <BreadcrumbDivider />
      <BreadcrumbSection as={Link} to="/manga">
        Manga
      </BreadcrumbSection>
      <BreadcrumbDivider />
      <BreadcrumbSection>
        Part 4 - Femme boards the Merveilleux
      </BreadcrumbSection>
    </Breadcrumb>
    <br />
    <br />
    <br />
    <br />
    <div style={{ textAlign: "center" }}>
      <img width="100%" src="/comic/atlus/skygunner4.jpg" />
      <br />
    </div>
  </>
);

export const MagazineZAugust = () => {
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };
  return (
    <>
      {" "}
      <Breadcrumb>
        <BreadcrumbSection as={Link} to="/">
          Home
        </BreadcrumbSection>
        <BreadcrumbDivider />
        <BreadcrumbSection as={Link} to="/manga">
          Manga
        </BreadcrumbSection>
        <BreadcrumbDivider />
        <BreadcrumbSection>The Devil's Hand - Part 1</BreadcrumbSection>
      </Breadcrumb>
      <br />
      <br />
      <br />
      <br />
      <div style={{ textAlign: "center" }}>
        <Gallery photos={photos.august} onClick={openLightbox} />
        <ModalGateway>
          {viewerIsOpen ? (
            <M2 onClose={closeLightbox}>
              <Carousel
                currentIndex={currentImage}
                views={photos.august.map((x) => ({
                  ...x,
                  srcset: x.srcSet,
                  caption: x.title,
                }))}
              />
            </M2>
          ) : null}
        </ModalGateway>
      </div>
    </>
  );
};

export const MagazineZSeptember = () => {
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };
  return (
    <>
      {" "}
      <Breadcrumb>
        <BreadcrumbSection as={Link} to="/">
          Home
        </BreadcrumbSection>
        <BreadcrumbDivider />
        <BreadcrumbSection as={Link} to="/manga">
          Manga
        </BreadcrumbSection>
        <BreadcrumbDivider />
        <BreadcrumbSection>The Devil's Hand - Part 2</BreadcrumbSection>
      </Breadcrumb>
      <br />
      <br />
      <br />
      <br />
      <div style={{ textAlign: "center" }}>
        <Gallery photos={photos.september} onClick={openLightbox} />
        <ModalGateway>
          {viewerIsOpen ? (
            <M2 onClose={closeLightbox}>
              <Carousel
                currentIndex={currentImage}
                views={photos.september.map((x) => ({
                  ...x,
                  srcset: x.srcSet,
                  caption: x.title,
                }))}
              />
            </M2>
          ) : null}
        </ModalGateway>
      </div>
    </>
  );
};

const Media = () => (
  <>
    {" "}
    <Breadcrumb>
      <BreadcrumbSection as={Link} to="/">
        Home
      </BreadcrumbSection>
      <BreadcrumbDivider />
      <BreadcrumbSection>Media</BreadcrumbSection>
    </Breadcrumb>
    <br /> <br />
    Wallpapers:
    <br />
    <div style={{ textAlign: "center" }}>
      <a
        class="wallpaper-thumb-a"
        href="/wallpapers/skygunner-wallpaper-characters-1.jpg"
        target="_blank"
      >
        <img
          class="wallpaper-thumb"
          src="/wallpapers/skygunner-wallpaper-characters-1.jpg"
        />
      </a>
      <a
        class="wallpaper-thumb-a"
        href="/wallpapers/skygunner-wallpaper-characters-2.jpg"
        target="_blank"
      >
        <img
          class="wallpaper-thumb"
          src="/wallpapers/skygunner-wallpaper-characters-2.jpg"
        />
      </a>
      <br />
      <a
        class="wallpaper-thumb-a"
        href="/wallpapers/skygunner-wallpaper-ciel-and-copain.jpg"
        target="_blank"
      >
        <img
          class="wallpaper-thumb"
          src="/wallpapers/skygunner-wallpaper-ciel-and-copain.jpg"
        />
      </a>
      <a
        class="wallpaper-thumb-a"
        href="/wallpapers/skygunner-wallpaper-femme-bicycle.jpg"
        target="_blank"
      >
        <img
          class="wallpaper-thumb"
          src="/wallpapers/skygunner-wallpaper-femme-bicycle.jpg"
        />
      </a>
      <a
        class="wallpaper-thumb-a"
        href="/wallpapers/skygunner-wallpaper-hardi-armored-car.jpg"
        target="_blank"
      >
        <img
          class="wallpaper-thumb"
          src="/wallpapers/skygunner-wallpaper-hardi-armored-car.jpg"
        />
      </a>
      <br />
      <a
        class="wallpaper-thumb-a"
        href="/wallpapers/skygunner-wallpaper-ventre-gang.jpg"
        target="_blank"
      >
        <img
          class="wallpaper-thumb"
          src="/wallpapers/skygunner-wallpaper-ventre-gang.jpg"
        />
      </a>
      <a
        class="wallpaper-thumb-a"
        href="/wallpapers/skygunner-wallpaper-ventre-photo.jpg"
        target="_blank"
      >
        <img
          class="wallpaper-thumb"
          src="/wallpapers/skygunner-wallpaper-ventre-photo.jpg"
        />
      </a>
    </div>
    <br />
    <br />
    Videos:
    <div style={{ textAlign: "center" }}>
      <br />
      <iframe
        width="560"
        height="315"
        src="https://www.youtube.com/embed/0-31LU4ldms?si=ZHdfp7_---_m5-g4"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerpolicy="strict-origin-when-cross-origin"
        allowfullscreen
      ></iframe>
      <br />
      <iframe
        width="560"
        height="315"
        src="https://www.youtube.com/embed/pANb8JEPhyU?si=5CaLQYQo8wnLDejX"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerpolicy="strict-origin-when-cross-origin"
        allowfullscreen
      ></iframe>
      <br />
      <iframe
        width="560"
        height="315"
        src="https://www.youtube.com/embed/0QclbuZlIic?si=CoRs975Euyn6GXxf"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerpolicy="strict-origin-when-cross-origin"
        allowfullscreen
      ></iframe>
      <br />
      <iframe
        width="560"
        height="315"
        src="https://www.youtube.com/embed/RX6GvpbAMX0?si=HSqriZerZCqrIbLA"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerpolicy="strict-origin-when-cross-origin"
        allowfullscreen
      ></iframe>
      <br />
      <iframe
        width="560"
        height="315"
        src="https://www.youtube.com/embed/vAgRjKtj8ZA?si=0NOMqSqTS8mDNfNu"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerpolicy="strict-origin-when-cross-origin"
        allowfullscreen
      ></iframe>
      <br />
      <iframe
        width="560"
        height="315"
        src="https://www.youtube.com/embed/rH3iFSX44Mg?si=xgxWiI_Gy5uMPOvh"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerpolicy="strict-origin-when-cross-origin"
        allowfullscreen
      ></iframe>
      <br />
      <iframe
        width="560"
        height="315"
        src="https://www.youtube.com/embed/ZacItUqPtC8?si=TgQ9QMN8etZGxxgs"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerpolicy="strict-origin-when-cross-origin"
        allowfullscreen
      ></iframe>
      <br />
      <iframe
        width="560"
        height="315"
        src="https://www.youtube.com/embed/aF6HwA0RPQQ?si=yyUUPQ9pdprVXWVZ"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerpolicy="strict-origin-when-cross-origin"
        allowfullscreen
      ></iframe>
      <br />
      <iframe
        width="560"
        height="315"
        src="https://www.youtube.com/embed/Oe7rDvAj7O0?si=BgxAd_hRtZpWzZUg"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerpolicy="strict-origin-when-cross-origin"
        allowfullscreen
      ></iframe>
      <br />
      <iframe
        width="560"
        height="315"
        src="https://www.youtube.com/embed/AP8GF8lLa9I?si=geHhoVsYSIC9KaAJ"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerpolicy="strict-origin-when-cross-origin"
        allowfullscreen
      ></iframe>
      <br />
      <br />
      <br />
    </div>
  </>
);
function Layout() {
  const location = useLocation();
  const { hash, pathname, search } = location;

  return (
    <>
      <Menu stackable fixed="top">
        <MenuItem header as={Link} to="/">
          Welcome to the Gunner's Cafe
        </MenuItem>
        <MenuItem active={pathname == "/"} as={Link} to="/">
          Home
        </MenuItem>

        <MenuItem active={pathname == "/manga"} as={Link} to="/manga">
          Manga
        </MenuItem>

        <MenuItem active={pathname == "/media"} as={Link} to="/media">
          Media
        </MenuItem>
        <MenuItem active={pathname == "/faq"} as={Link} to="/faq">
          FAQ
        </MenuItem>

        <MenuItem
          active={pathname == "/encyclopedia"}
          as={Link}
          to="/encyclopedia"
        >
          Encyclopedia
        </MenuItem>
        <MenuItem
          onClick={() => {
            var $el = $("#jukebox"); //record the elem so you don't crawl the DOM everytime
            var bottom = $el.css("bottom");
            if (bottom != "0px") {
              $("#jukebox").animate({
                bottom: "0px",
              });
            } else {
              $("#jukebox").animate({
                bottom: "-" + $("#jukebox").height() + "px",
              });
            }
          }}
          icon="music"
        ></MenuItem>
        <div class="hamburger">
          <span class="hamburger-bun"></span>
          <span class="hamburger-patty"></span>
          <span class="hamburger-bun"></span>
        </div>
      </Menu>
      <div id="menu-margin"></div>
      <Container>
        <Outlet />
      </Container>
      <div
        id="jukebox"
        style={{ position: "fixed", bottom: "0px", width: "100%" }}
      >
        <iframe
          width="100%"
          height="400"
          scrolling="no"
          frameborder="no"
          // allow="autoplay"
          src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1815435441&color=%23ff5500&auto_play=true&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"
        ></iframe>
        <div
          style={{
            fontSize: "10px",
            color: "#cccccc",
            lineBreak: "anywhere",
            wordBreak: "normal",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            fontFamily:
              "Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif",
            fontWeight: "100",
          }}
        >
          <a
            href="https://soundcloud.com/gunners-cafe"
            title="Gunner&#x27;s Cafe"
            target="_blank"
            style={{
              color: "#cccccc",
              textDecoration: "none",
            }}
          >
            Gunner&#x27;s Cafe
          </a>{" "}
          ·{" "}
          <a
            href="https://soundcloud.com/gunners-cafe/sets/skygunner-ost"
            title="SkyGunner O.S.T."
            target="_blank"
            style={{
              color: "#cccccc",
              textDecoration: "none",
            }}
          >
            SkyGunner O.S.T.
          </a>
        </div>{" "}
      </div>
    </>
  );
}

const Encyclopedia = () => {
  const { readRemoteFile } = usePapaParse();

  const [data, setData] = useState(0);
  const [filter, setFilter] = useState("");

  if (!data) {
    readRemoteFile(my_csv, {
      complete: (results) => {
        // console.log("---------------------------");
        // console.log("Results:", results);
        // console.log("---------------------------");
        setData(results.data);
      },
    });
  }

  // return (
  //   <>
  //     {_.map(Enemy_Data, (elem) => (
  //       <>{elem["Make"]}</>
  //     ))}
  //   </>
  // );
  return (
    <>
      {" "}
      <Breadcrumb>
        <BreadcrumbSection as={Link} to="/">
          Home
        </BreadcrumbSection>
        <BreadcrumbDivider />
        <BreadcrumbSection>Encyclopedia</BreadcrumbSection>
      </Breadcrumb>
      <br />
      <br />
      <br />
      <br />
      <Input
        placeholder="Filter..."
        onChange={(a, b) => {
          setFilter(b.value);
        }}
      />
      <Divider></Divider>
      {/* <ItemGroup> */}
      {_.chain(data)
        .drop(1)
        .filter((to_filter) => {
          // console.log(to_filter);
          // console.log(to_filter[0].toLowerCase());
          // console.log(filter.toLowerCase());
          // console.log(to_filter[0].toLowerCase() == filter.toLowerCase());
          return to_filter[0].toLowerCase().includes(filter.toLowerCase());
          // return to_filter[0].toLowerCase() == filter.toLowerCase();
        })
        .map((elem, elem_i) => {
          return (
            <>
              <br></br>
              <Item>
                {/* <ItemImage size="tiny" src="/images/wireframe/image.png" /> */}
                <ItemContent>
                  <ItemHeader>
                    {elem[0]} - (English translation "{elem[4]}")
                    <br />
                    {(() => {
                      switch (elem[1]) {
                        case "Horloger":
                          return <img src="/company-horloger.png" />;
                        case "Associe":
                          return <img src="/company-associe.png" />;
                        case "Ventre Hand-made":
                          return <img src="/company-ventre.png" />;
                        default:
                          return <></>;
                      }
                    })()}
                  </ItemHeader>
                  <ItemMeta>
                    <Table size="small">
                      <TableHeader fullWidth={true}>
                        <TableRow>
                          <TableHeaderCell colSpan={7}>
                            Machine Data
                          </TableHeaderCell>
                        </TableRow>
                        <TableRow>
                          {_.map(data[0][2].split("\n").slice(1), (w_and_p) => {
                            return <TableHeaderCell>{w_and_p}</TableHeaderCell>;
                          })}
                        </TableRow>
                      </TableHeader>

                      <TableBody>
                        {_.map(elem[2].split("\n"), (part) => {
                          return (
                            <>
                              <TableRow>
                                {_.map(part.split(","), (part_e) => {
                                  return (
                                    <TableCell collapsing>{part_e}</TableCell>
                                  );
                                })}
                              </TableRow>
                            </>
                          );
                        })}
                      </TableBody>
                    </Table>
                    <Table size="small">
                      <TableHeader fullWidth={true}>
                        <TableRow>
                          <TableHeaderCell colSpan={7}>
                            Weapons & Parts
                          </TableHeaderCell>
                        </TableRow>
                        <TableRow>
                          {_.map(
                            data[0][3].split("\n").slice(1),
                            (w_and_p, header_i) => {
                              return (
                                <TableHeaderCell
                                  textAlign={header_i > 0 ? "center" : "left"}
                                >
                                  {w_and_p}
                                </TableHeaderCell>
                              );
                            }
                          )}
                        </TableRow>
                      </TableHeader>

                      <TableBody>
                        {_.chain(elem[3].split("\n"))
                          .groupBy((t) => {
                            return t.split(",")[0];
                          })
                          .map((part) => {
                            // console.log(part);
                            // console.log(part); // this is grouped
                            return (
                              <>
                                {_.map(part, (part_e, part_i) => {
                                  return (
                                    <TableRow>
                                      {_.map(
                                        part_e.split(","),
                                        (part_sub_e, part_sub_i) => {
                                          if (
                                            part_sub_i <= 4 &&
                                            part_i > 0 &&
                                            part.length > 1
                                          ) {
                                          } else {
                                            return (
                                              <TableCell
                                                textAlign={
                                                  part_sub_i > 0
                                                    ? "center"
                                                    : "left"
                                                }
                                                rowSpan={
                                                  part_sub_i <= 4 &&
                                                  part_i == 0 &&
                                                  part.length > 1
                                                    ? part.length
                                                    : 1
                                                }
                                              >
                                                {part_sub_e}
                                              </TableCell>
                                            );
                                          }
                                        }
                                      )}
                                    </TableRow>
                                  );
                                })}
                              </>
                            );
                          })
                          .value()}
                      </TableBody>
                    </Table>
                  </ItemMeta>
                  <ItemDescription>{elem[5]}</ItemDescription>
                </ItemContent>
              </Item>
              <Divider />
            </>
          );
        })
        .value()}
      {/* </ItemGroup> */}
    </>
  );
};

export const UnlockPhotos = () => {
  return (
    <>
      {" "}
      <Breadcrumb>
        <BreadcrumbSection as={Link} to="/">
          Home
        </BreadcrumbSection>
        <BreadcrumbDivider />
        <BreadcrumbSection>
          {" "}
          <BreadcrumbSection as={Link} to="/faq">
            FAQ
          </BreadcrumbSection>
        </BreadcrumbSection>
        <BreadcrumbDivider />
        <BreadcrumbSection>How to Unlock All Photos</BreadcrumbSection>
      </Breadcrumb>
      <br /> <br />
      <br />
      <br />
      <br />
      <pre style={{ whiteSpace: "pre-wrap" }}>{unlockPhotos}</pre>
      <br />
      <br />
      <div style={{ textAlign: "center" }}></div>
    </>
  );
};

export const Walkthrough = () => {
  return (
    <>
      {" "}
      <Breadcrumb>
        <BreadcrumbSection as={Link} to="/">
          Home
        </BreadcrumbSection>
        <BreadcrumbDivider />
        <BreadcrumbSection>
          {" "}
          <BreadcrumbSection as={Link} to="/faq">
            FAQ
          </BreadcrumbSection>
        </BreadcrumbSection>
        <BreadcrumbDivider />
        <BreadcrumbSection>Walkthrough Translation</BreadcrumbSection>
      </Breadcrumb>
      <br /> <br />
      <br />
      <br />
      <br />
      <pre style={{ whiteSpace: "pre-wrap" }}>{_.unescape(walkthrough)}</pre>
      <br />
      <br />
      <div style={{ textAlign: "center" }}></div>
    </>
  );
};

export const DeveloperInterview = () => {
  // console.log(interview);
  return (
    <>
      {" "}
      <Breadcrumb>
        <BreadcrumbSection as={Link} to="/">
          Home
        </BreadcrumbSection>
        <BreadcrumbDivider />
        <BreadcrumbSection>
          {" "}
          <BreadcrumbSection as={Link} to="/faq">
            FAQ
          </BreadcrumbSection>
        </BreadcrumbSection>
        <BreadcrumbDivider />
        <BreadcrumbSection>Developer Interview</BreadcrumbSection>
      </Breadcrumb>
      <br /> <br />
      <br />
      <br />
      <br />
      <Header as="h2">Skygunner Developer Interview </Header>
      <Header as="h3"> --Bonus-- Visiting Pixel Arts</Header>
      <Header as="h4">Town of Esaka, Osaka-fu 15:00 p.m. </Header>
      <br />
      <Message icon compact size="small" info={true}>
        <Icon>
          <Image src={"/character-ventre.png"} size="mini" />
        </Icon>
        <MessageContent>
          <Message.Header>Interviewer:</Message.Header>
          Tell us about the secrets of Sky Gunner. The exhilarating sense of
          play and the playful worldview of "Sky Gunner" are captivating. What
          kind of thoughts and feelings did the staff put into the creation of
          this game?
        </MessageContent>
      </Message>
      <br />
      <Header as="h4"> We interviewed the developer, Pixel Arts Inc.</Header>
      <br />
      <Grid columns={5} divided size="mini">
        <GridRow>
          <GridColumn>
            <Image src="/character-rival.png" size="mini" floated="left" />
            <div style={{ fontSize: ".92857143em", textAlign: "center" }}>
              Yuji Nakamura
              <br /> Co-Director
            </div>
          </GridColumn>
          <GridColumn>
            <Image src="/character-femme.png" size="mini" floated="left" />
            <div style={{ fontSize: ".92857143em", textAlign: "center" }}>
              Hiroyuki Kotani
              <br /> Director
            </div>
          </GridColumn>
          <GridColumn>
            <Image src="/character-ciel.png" size="mini" floated="left" />
            <div style={{ fontSize: ".92857143em", textAlign: "center" }}>
              Daisuke Emura
              <br /> Main System Programmer
            </div>
          </GridColumn>
          <GridColumn>
            <Image src="/character-copain.png" size="mini" floated="left" />
            <div style={{ fontSize: ".92857143em", textAlign: "center" }}>
              Yoshikazu Hiraki
              <br /> Game Designer
            </div>
          </GridColumn>
          <GridColumn>
            <Image src="/character-hardi.png" size="mini" floated="left" />
            <div style={{ fontSize: ".92857143em", textAlign: "center" }}>
              Kei Watanabe
              <br /> Character Designer
            </div>
          </GridColumn>
        </GridRow>
      </Grid>{" "}
      {/* <Label as="a" image>
        <img src="/character-copain.png" />
        Joe Hisaishi | tester
      </Label> */}
      <br />
      <br />
      {_.map(interview, (s) => {
        if (typeof s === "object") {
          let v;
          _.forEach(_.keys(s), (k) => {
            // v = s[k];
            let color = "";
            let image = "";
            switch (k) {
              case "Emura":
                color = "green";
                image = "/character-ciel.png";
                break;
              case "Kotani":
                color = "teal";
                image = "/character-femme.png";
                break;
              case "Hiraki":
                color = "blue";
                image = "/character-copain.png";
                break;
              case "Nakamura":
                color = "violet";
                image = "/character-rival.png";
                break;
              case "Watanabe":
                color = "violet";
                image = "/character-hardi.png";
                break;
              default:
                color = "default";
                image = "/character-ventre.png";
                break;
            }
            v = (
              <>
                <Message icon compact size="small" info={k !== "Interviewer"}>
                  <Icon>
                    <Image src={image} size="mini" />
                  </Icon>
                  <MessageContent>
                    <Message.Header>{k}:</Message.Header>
                    {s[k]}
                  </MessageContent>
                </Message>
                <br />
              </>
            );
          });
          return v;
        } else
          return (
            <Message size="small" style={{ textAlign: "center" }}>
              {s}
            </Message>
          );
      })}
      <br />
      <br />
      <div style={{ textAlign: "center" }}></div>
    </>
  );
};
const FAQ = () => {
  return (
    <>
      {" "}
      <Breadcrumb>
        <BreadcrumbSection as={Link} to="/">
          Home
        </BreadcrumbSection>
        <BreadcrumbDivider />
        <BreadcrumbSection>FAQ</BreadcrumbSection>
      </Breadcrumb>
      {/* <div style={{ textAlign: "center" }}> */}
      <br />
      <br />
      <br />
      <br />
      FAQ:
      <br />
      <Link target="_blank" to="http://home.e-catv.ne.jp/skywind/g-sg/guide/">
        スカイガンナー攻略
      </Link>
      <br />
      <br />
      Official Skygunner Guidebook (fan translation):
      <br />
      <Link to="/walkthrough">Walkthrough</Link>
      <br />
      <Link to="/developer-interview">Developer Interview</Link>
      <br />
      <Link to="/unlock-photos">How to Unlock All Photos</Link>
      <br />
      <br />
      Opening Theme:
      <br />
      <Link to="/official-lyrics">
        "Wishing for an Eternal Sky" - Official Translation
      </Link>
      <br />
      <Link to="/official-lyrics-2">
        "Wishing for an Eternal Sky" Kazco Live Part 2 addendum - Original
        Japanese and Fan Translation
      </Link>
      {/* </div> */}
    </>
  );
};

export const TakeTwoTranslation = () => {
  return (
    <>
      <div
        style={{
          textAlign: "center",
        }}
      >
        Japanese:
        <br />
        <br />
        赤く燃える空が　夜空に　変わるとき
        <br />
        輝いた　星たち　降り注ぐ
        <br />
        <br />
        夜明けの眩しさに　目を細め　見上げたら
        <br />
        鳥たちは　羽ばたき　空を漕ぐ
        <br />
        <br />
        海越えて　廻り（渡り？）来る
        <br />
        向かい風　身に纏い
        <br />
        <br />
        そう高く　舞い上がり
        <br />
        この空で　翼を広げよう
        <br />
        <br />
        Hiragana:
        <br /> <br />
        akaku moeru sora ga, yozora ni kawaru toki
        <br />
        kagayaita hoshitachi furi sosogu
        <br />
        <br />
        yoake no mabushisa ni me wo hosome, miagetara
        <br />
        tori tachi wa habataki sora wo kogu
        <br />
        <br />
        umi koete mawari (watari?) kuru
        <br />
        mukai kaze mi ni matoi
        <br />
        <br />
        sou takaku maiagari
        <br />
        kono sora de tubasa or hirogeyou
        <br />
        <br />
        English:
        <br /> <br />
        When the sky turns from crimson to evening
        <br />
        The glowing stars come falling down
        <br />
        <br />
        I look up and squint at the brightness of dawn
        <br />
        The birds flutter and beat against the sky
        <br />
        <br />
        And come across the ocean
        <br />
        I dress myself in the headwinds
        <br />
        <br />
        Soaring high
        <br />
        Let's spread our wings in this sky.
        <br />
        <br />
      </div>
    </>
  );
};

const OpeningTranslation = () => {
  return (
    <>
      <div
        style={{
          textAlign: "center",
        }}
      >
        Wishing for an eternal sky
        <br />
        A vast sea of endless dreams
        <br />
        <br />
        Emotions will brighten
        <br />
        Emotions will cloud
        <br />
        Nothing comes easy
        <br />
        But I'll keep on believing
        <br />
        Hope will turn into wings
        <br />
        You still don't realize
        <br />
        It'll guide you through the map of tomorrow
        <br />
        <br />
        So, stand up to the wind
        <br />
        As the tears dry from your cheeks
        <br />
        Look into the distant sky
        <br />
        And see yourself in your dreams
        <br />
        <br />
        Catch a rainbow
        <br />
        And chase a shooting star
        <br />
        Like a shapeless cloud
        <br />
        Your adventure lies on an unknown path
        <br />
        <br />
        So, stand with your face against the wind
        <br />
        As you sing with a smile on your face
        <br />
        Wishing for an eternal sky
        <br />
        A vast sea of endless dreams
        <br />
      </div>
    </>
  );
};
const Manga = () => (
  <>
    {" "}
    <Breadcrumb>
      <BreadcrumbSection as={Link} to="/">
        Home
      </BreadcrumbSection>
      <BreadcrumbDivider />
      <BreadcrumbSection>Manga</BreadcrumbSection>
    </Breadcrumb>
    {/* <div style={{ textAlign: "center" }}> */}
    <br />
    <br />
    <br />
    <br />
    Official Comic (Fan Translation):
    <br />
    <Link to="/comic-1">Part 1 - Ciel and Copain meet (flashback)</Link>
    <br />
    <Link to="/comic-2">Part 2 - Ventre steals the Machine Soldier</Link>
    <br />
    <Link to="/comic-3">Part 3 - Ventre invents Poulets (flashback)</Link>
    <br />
    <Link to="/comic-4">Part 4 - Femme boards the Merveilleux</Link> <br />
    <br />
    <br />
    Magazine-Z Comic (Fan Translation):
    <br />
    <Link to="/magazine-z-august">The Devil's Hand - Part 1</Link>
    <br />
    <Link to="/magazine-z-september">The Devil's Hand - Part 2</Link>
    <br />
    <br />
    <br /> Hentai Doujinshi (Fan Translation):
    <br />
    <Link to="/hentai">Femme kidnapped by Ventre</Link> <br />
    {/* </div> */}
  </>
);
const Homepage = () => (
  <>
    <div style={{ textAlign: "center" }}>
      <br />
      Welcome to the Gunner's Cafe
      <br />
      <br />
      <br />
      <br />
      News:
      <br />
      <br />
      Skygunner has been re-realeased and is available now for PS4 and PS5 for
      only $9.99 USD{" "}
      <Link
        target="_blank"
        to="https://store.playstation.com/en-us/product/UP9000-PPSA23535_00-SLUS203840000000"
      >
        here
      </Link>{" "}
      at the Playstion Store!!!
      <br />
      <br />
      See trophies at{" "}
      <Link
        target="_blank"
        to="https://psnprofiles.com/trophies/29482-skygunner"
      >
        PSNProfiles
      </Link>
      <br />
      <br />
      See speed runs at{" "}
      <Link target="_blank" to="https://www.speedrun.com/skygunner">
        Speedrun.com
      </Link>
      <br />
      <br />
      Our Mission:
      <br />
      <br />
      <p style={{ width: "50%", textAlign: "center", margin: "auto" }}>
        To provide a place for Gunners to relax and enjoy the atmosphere of
        Rive. Meanwhile, Gunners can read a comic, enjoy a coffee, or admire the
        various collections at the cafe.
      </p>
      <p>
        It is intended as a resource for any and all things related to
        Skygunner.
      </p>
      <br />
      <br />
      <br />
      Our Story:
      <br />
      We played Skygunner when it first came out in America on June 24th, 2002.
      <br />
      We fell in love with the gameplay and the story.
      <br />
      Truly a classic, Skygunner will forever be in our hearts.
      <br />
      <br />
      <br />
      <br />
      <br />
    </div>
  </>
);
export {
  Layout,
  Homepage,
  Media,
  Comic1,
  Comic2,
  Comic3,
  Comic4,
  Hentai,
  Encyclopedia,
  OpeningTranslation,
  Manga,
  FAQ,
};
