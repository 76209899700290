export const walkthrough = `1

SKYGUNNER®

Sky Gunner Official Guidebook



2



Story



...A world where flying is not difficult...

There are people who operate combat boats and take on various requests in the sky. People who pilot combat boats equipped with guns are called &quot;gunners.&quot; This story is about the &quot;Leve Fair Incident,&quot; in which gunners Ciel and Copain, who are active in the city of Leve, first met the genius criminal Vantre. The luxury cruise ship Merville called in to Leve to commemorate the opening of the fair, and a party was being held to unveil the &quot;Perpetual Motion Machine,&quot; a dream machine that keeps moving forever. However, the genius criminal Vantre was also on board the Merville, who was trying to obtain the perpetual motion machine.



3

Ciel and Copain take to the sky to protect the perpetual motion machine from Vantre.

At the party are rookie gunner Fam and Leev City&#39;s talented police inspector Ardi.

Each of them boards their prized combat craft and heads towards Vantre.

And so the incident began.

Will the Gunners be able to protect the perpetual motion machine? And will Reeve&#39;s exhibition be able to take place safely?



4

SKYGUNNER OFFICIAL GUIDE BOOK

CONTENTS

Table of Contents



Character Introduction

・Ciel &amp; Avenir P6

・Copain &amp; Chevalier P8

・Fam &amp; Blanche P10

・Rival &amp; Phantom P12

・Ardi &amp; Courage P14

・Ventre P16

・Poulet P17



System Description

・Main menu P20

・Operation method P21

・Game screen P22

・Flight operation P23

・Viewpoint P27

・Target P28

・Attack on enemy aircraft P30

・Aircraft temperature P35

・Story progression P36

・Prize P38



Vantor Superweapon Encyclopedia	

・Petit combat boat Premier P43

・Combat boat Progre P44

・Combat boat Dernie P45

・Exoze P46 surface combatant

・Surface combat boat Detrois P47

・Pule &amp; Assault Pod P48

・Feather Bomb P48

・Large combat boat Starbuck P49

・Towed Battleship Gauche &amp; Drowat P50

・Balloon-type air battleship Balene P52

・Floating Battleship Grandir P54

・Heavy Armored Floating Battleship Dorado P56

・Fortress Battery &amp; Tank P57

・Walking Tank Skret P58

・Flying Fortress Battleship Grand Magasin P60

・Mechanical Soldier Vaincour Fidele P68

・Machine Soldier Vaincour Sage P69

・Machine Soldier Vaincour Garde P70



Story Walkthrough

・Scene 1: The genius criminal Vantor appears P76

・Scene 2: Assault! Ventre Collection Team P84

・Scene 3: Landing of Machine Soldiers P92

・Scene 3 Jet Black Gunner P96

・Scene 3: Monster of the Waterway Street P98

・Scene 4 Lakeside Castle P102

・Scene 5: Battle! Sky Fortress Battleship P112

・Ranking Certification P124



Secret File

・Hidden element appearance conditions P126

・Time Attack P127

・Survival P128

・Album P130

・Rival Story P132

・Scene Select P135

・Cutout Camera P135

・Bonus Developer Interview P136



Tea Time Column No. 1

A World with Gunners P18

Tea Time Column No. 2

Gunner machine manufacturer P40

Tea Time Column No. 3

Supporting vehicles P72



5



Character Introduction







6



Ciel &amp; Avenir



A well-known and skilled gunner

[Ciel]

Ever since he was a child, Ciel grew up with the image of the brave Gunners fighting in the sky burned into his mind, and he has now fulfilled his dream of becoming a combat boat pilot and Gunner. Though he still has some childish aspects, he is now a skilled Gunner whose name is known not only in the city of Leeve but also in towns far away. Ciel takes pride in his job as a Gunner and handles even the toughest requests with ease. He used to work alone, but since getting to know Copan, they often work together. His machine maintenance skills are also top-notch.





7



Crimson wings take flight into the future

[Avenir]

Avenir



The Avenir, whose name means &quot;future,&quot; is the oldest combat boat among the gunner machines still in existence. However, its basic design and aircraft performance are amazing, and it is in no way inferior to the latest machines. In particular, the Heavy Bullet, which temporarily redirects the engine&#39;s output to the machine gun to launch a powerful attack, is a technology unique to Artisan. The Avenir was created by Ciel&#39;s grandfather and his friends, who worked at Artisan at the time, and was passed down to Ciel, making it a rare and excellent machine.



Manufacturer: Artisan

Total length: 5.8m

Weight 960kg

Minimum speed 229km/h

Maximum speed 780km/h

Machine gun firing rate: 20 rounds/sec

Optional Weapons: Hound Missile/Fireworks Missile/Cross Missile

Maximum locks: 3

EX Action: Steam Brake/Heavy Bullet (Concentration)

Aircraft durability B

Balance Recovery B

Turning performance B



8



Copain &amp; Chevalier



Shell&#39;s slightly flirtatious partner

[Copain]

Copan is a self-proclaimed &quot;genius gunner&quot; who works as a gunner because it pays well and is popular with girls. His skills are almost on par with his partner Ciel. He used to be a gunner in Associe City, but when he heard rumors that there was a great gunner in Reeve, he fought Ciel. In the end, the match with Ciel was not conclusive, but they hit it off and he and Shell teamed up, and now he&#39;s settled in as a gunner in Reeve. He has a very easy-going personality, but he also has a mysterious side as his background before becoming a gunner is completely unknown.



9



A knight who cuts through the sky

CHEVALIER

A combat boat made by Associe, it is the most expensive gunner machine on the market. It has capabilities that are commensurate with its price, but because of its high engine performance, it is delicate and difficult to control. Rolling, in which the aircraft rotates while flying at high speed and rams into the enemy, is an attack method unique to the Chevalier, which uses a new special steel material for the aircraft. Copan treats this machine like an extension of his body, but there are very few gunners who are able to fully utilize the Chevalier&#39;s performance.



Manufacturer: Associé

Total length: 5.65m

Weight 1280kg

Minimum speed 300km/h

Maximum speed 835km/h

Machine gun firing rate: 20 rounds/sec

Optional weapons: Hound missile/Fireworks missile/Pumpkin bomb

Maximum number of locks: 4

EX Action Rolling

Aircraft durability B

Restoring balance C

Turning performance B





10

Women &amp; Branches

White Bird: A fusion of tradition and cutting-edge technology

[Branché]

Fam was assigned to guard the Merville, which was carrying a perpetual motion machine, and received this Blanche as a gift from Horloger. Blanche is a cutting-edge gunner machine that is not yet commercially available, and Fam himself has never actually piloted it. However, Blanche, which can be said to be the culmination of Horloger&#39;s technology, has high stability and turning performance, so a skilled gunner like Fam can easily handle it. Furthermore, active turn, which allows it to make sharp turns in any direction, is a powerful function that is installed for the first time on a gunner machine, Blanche.



By Horloger

Total length: 5.2m

Weight 735kg

Minimum speed 120km/h

Maximum speed 798km/h

Machine gun firing rate: 30 rounds/sec

Optional Weapons: Hound Missile/Fireworks Missile/Cross Missile

Maximum number of locks: 3

EX Action Active Turn

Aircraft durability A

Balance Recovery A

Turning performance A





11



A new gunner from Neige

[Femme]

Fam came from Neige, a large industrial city far north of Leeve, at the request of the Leeve City Inspectorate to provide security for the fair. After graduating top of her class from Neige Academy, she became a gunner in Neige and has completed several difficult requests. Bright, lively and sociable, it seems that many men have become fans of Fam, captivated by the sight of her operating a gunner machine. Although she has little experience as a gunner, she is guarding the Leeve Fair with enthusiasm that rivals Ciel and the others, and piloting techniques that are beyond what you would expect from a rookie.



12



Rival &amp; Fantom

The mysterious jet-black gunner [Rival]rivalThe mysterious gunner Rival suddenly appears before Ciel. His true identity is the current head of the immensely wealthy Rival family. He works as a gunner not for money or fame, but to distract himself from the monotony of everyday life, and he only takes on dangerous jobs that give him thrills. It is no exaggeration to say that his combat boat piloting skills are the best among the gunners currently active. He is usually a dignified and gentlemanly person, but he loves fighting, and his personality changes completely when he gets into his beloved machine, the Phantom.



[Steward]

A loyal butler who has served the Rival family since the time of Rival&#39;s father. Rival trusts him, and the steward also manages the Rival family&#39;s assets.





13



Manufacturer: Artisan

Total length: 4.8m

Weight 915kg

Minimum speed 160km/h

Maximum speed 980km/h

Machine gun firing rate: 60 rounds/sec

Optional weapons: Hound missile/Fireworks missile/Bat missile

Maximum number of locks: 4

EX Action Steam Brake/Heavy Palette (Diffusion)

Aircraft durability C

Restoring balance E

Turning performance A



A jet-black gunner machine that Rival has been pouring all his financial resources into modifying. Its attack power has been increased to the limit through numerous modifications, and the destructive power of its machine guns is unmatched by other gunner machines. It is a sister machine to Ciel&#39;s beloved machine, Avenir, which was made at the same time, so the machine itself is old, but it is the strongest combat boat among the existing gunner machines. The steam brake that stops it in the air has not been modified, but the heavy bullet that fires powerful bullets has been strengthened so that it can fire up to 16 bullets at once over a wide area.





14



Hardi&amp;Courage







A sturdy flying boat that fights bravely

[Currage]

courage

This is a Duell company combat boat used by the flying boat unit of Leev City. Compared to the machines used by Gunna and his men, its flight speed and turning ability are lower, but it is an excellent machine in terms of ease of maintenance and high durability. Ardi uses this Courage, but the police officers ride the Ordol, a machine one rank lower than the Courage. Neither machine is equipped with special functions, but the officers of the flying boat unit are trained to fly in formation following Ardi&#39;s orders and coordinate to launch concentrated attacks on the enemy.



Manufacturer Dur

Total length: 4.65m

Weight 1140kg

Minimum speed 80km/h

Maximum speed 580km/h

Machine gun firing rate: 20 rounds/sec

Optional equipment weapon: Command bullet (formation/scatter/concentration

Maximum Locks -

EX Action -

Aircraft durability: S

Balance Recovery S

Turning performance C



15-check



[Liev City Police Officer]police

The police officers of Leev City are a little bit clumsy, but they are loved by the citizens. The entire airship unit led by Ardi has been mobilized to provide security for the upcoming expo.



A skilled police inspector who protects the citizens of Reeve

[Aldi] hardi



Ardi is a skilled inspector who leads the Leev Police Department&#39;s flying boat unit, and was selected for his skills as security chief for the Leev Fair. He was famous as a gunner in his younger days, but suddenly retired and has since stayed in Leev City, solving many difficult cases. Due to his past experience, he has close ties with active gunners, and supports Ciel and the others in many ways. Although he&#39;s not very good at desk work, he is a detective who is dedicated to the scene and is the first to rush out when a case occurs. He currently lives with his only daughter, Fille.





16-check



Ventre



A fearless and brilliant criminal

[Ventre]



Vantle is a genius criminal who was active for only a year a dozen years ago, and made his way into numerous works of art and treasures. He has now appeared in public once again, targeting the exhibits and inventions to be exhibited at the Rive Exposition. He is also a genius inventor who uses his vast wealth to create various machines, and Poulet, an automaton that acts as Vantle&#39;s hands and feet, is his masterpiece. He does not steal with any malicious intent, but rather due to his troublesome personality of assuming that beautiful or valuable things are his own when he sees them.



17



Poulet

An automaton that never breaks

[Poulet]

Poulet is a perfect automaton created by Vantle. Although it has no learning ability, it never breaks down and enjoys carrying out even the most reckless missions as if it were a game. Although all Poulets look the same, there are slight differences between each one in terms of personality and abilities, and those who perform brilliantly will be promoted. Vantle created Poulet 10 years ago, and 28 Poulets were born in one night. Vantle has continued to create Poulets since then, and now he has over 100 Poulets working for him.



Poole can be promoted or demoted as follows:



Poole&#39;s class system



Butler Poulet

They do not participate in battles, but serve tea to Lord Vantre and take care of his personal needs. These are the top two professions created first.



Captain Poole

A managerial Phule manages the regular Phules. When they become a captain, they are given a yellow battle suit and cool goggles by Lord Vantle.



Regular Poulet

Their job is to ride on combat boats and repair the hulls. Among them, the brave Phules who are launched from assault pods are close to being promoted to captain.



18



INTERMISSION Tea Time Column -Vol.1-

Tea Time Column No. 1

A World with Gunners



The world of &quot;Sky Gunner&quot; is a little different from our Earth. Let me explain the world that Ciel and his friends live in and their job, gunner.



Countries and cities

There are several entities in the world known as countries, but the nations themselves do not have strong authority, and each of the scattered towns governs itself like an autonomous region. However, the police force is managed by the country and dispatched to each town. The number of personnel dispatched varies depending on the size of the town, so not many officers are sent to small towns like Reeve. A Gunner&#39;s main job is to help out with areas that the police cannot cover. For this reason, the quality of Gunners tends to be higher in more rural towns with fewer police officers.



Basically, Gunners and the police have a cooperative relationship, so they sometimes work together.



Rive and surrounding towns



Reeve



It is one of the cities belonging to a country whose territory is around the Recif Sea, which stretches from north to south. It is divided into an old town on a peninsula and a new town built on an island offshore. It is a warm, peaceful city that is easy to live in.



Neige



A large industrial city located far north of Reeve, it is the most populous city in the country. There are many gunners, but most of them are nobles who have used their wealth to get their position recognized by the city, so the quality is not so good.



Associate



It is a town located west of Leev across the Recif Sea. Before Associe was established, it was a quiet rural town, but now the population has increased and it has transformed into a lively town. There are a few gunners here.





Gunner



Gunners take on a variety of jobs, from guarding and security to even searching for people. They are so called because they are allowed to carry guns and combat boats. Since it is a profession that involves weapons, not everyone can become a gunner. To become a gunner, you must first train under an experienced gunner and improve your skills. After that, only those who are deemed suitable as gunners in terms of personality and ability will be recommended by their teacher to each town. Once you are registered with a town, you can finally open a gunner business. At first, you won&#39;t get many jobs, but once you become known as a skilled gunner, you will be entrusted with important jobs like Ciel and his friends. As an exception, there are lone wolf gunners who are not registered with a town. These gunners take on jobs that are close to illegal activities at exorbitant fees.



img-caption

The gun held by the gunner is called the ignition gun because it is also necessary to ignite the engine of the combat boat. The shape of the slot into which the ignition is inserted differs for each machine, so it also serves as a key.



19



System Description



20



Main Menu



Game Modes and Environment



When you press the START button on the title screen, you will first be taken to the main menu screen. From here, you can select the game mode you want to play, set up the controller operation method, and set up your play environment. On the left side of the screen, available characters and the current state of your play environment are displayed as icons so that you can see them at a glance.



story



In the main game mode, you choose a character and progress through a story divided into several scenes. At first, you can only choose Fam and Shell, but if you meet certain conditions, you will also be able to play the stories of Copan and Rival (→ P126).



training



This is a tutorial that will teach you the controls, basic system, and EX actions for each character in several steps. Once you have completed each step, you will be able to practice freely. Be sure to play it at least once before starting the story.



Score



You can view &quot;Highest Records&quot; to check various records, and &quot;Rankings&quot; to check the highest bounty earned and gunner rank for each character.



data



Save your game progress and preferences to a memory card.

You can load the game data from the game drive or memory card. The data will be loaded automatically when you start the game.



Environment settings



You can change the controller&#39;s key operations, vibration, screen adjustments, audio output format, and volume settings.



?



This mode cannot be selected at the time. It will become available to play once certain conditions are met (→ P126).



Key operations



Operation Mode



Easy Mode



This mode allows you to turn the aircraft directly using only the directional keys. It is intuitive and easy to operate.



Ex Expert Mode



This mode allows you to rotate by combining the directional pad roll and the 1 and R1 buttons yaw. Subtle control is possible.





Target Mode



SIMPLE

This mode mainly targets the enemy aircraft&#39;s main body and the captain&#39;s aircraft, making it easy to see which targets you need to attack and take down.



ALL

This mode allows you to target not only the enemy aircraft itself, but also its weapons and parts, allowing you to fine-tune your attack target.





Up and down operation



Reverse



Press the directional key up to go down and down to go up.



normal



Press the directional key up to go up and down to go down.



21-unfinished



Access



Two Operation Modes



The way you operate the controller will change depending on the mode you set when you start the game. In particular, adjusting the direction of movement and performing EX actions will have completely different operating sensations, so once you have found the settings you like, it&#39;s best to avoid changing them frequently. It&#39;s important to focus on one mode while you&#39;re still getting used to it and make sure you completely master the controls.



twenty two



Game Screen



How to view the screen



During the battle part, various information is displayed on the screen, and changes from moment to moment. The instruments grouped on the left side of the screen are particularly important for knowing the status of your aircraft. Make sure you understand the meaning of each one. Please note that the screen display changes slightly depending on the game mode and mission.





1. Own aircraft

2 Gunsite

This is where the machine guns are fired from. When using an optional weapon, it changes to a locking sight.

3 Target Display

Displays targetable enemy aircraft and formations. The enemy aircraft selected here will have a target sight.

4. Target Site

This indicator will be displayed on the enemy plane you have selected as your target. It will tell you how much durability the enemy plane has left.

5 Bonus Multiplier

This is the bonus multiplier for the winnings when you get a chain or a hit.

6 Bonus Count

This number increases when you shoot down an enemy plane, and the bonus multiplier increases accordingly.

7 Prize Money (Battle Bonus)

This is the amount of money earned since the start of the scene. If you receive damage or crash, it may become negative (in the red).

8 Altimeter

This shows how high your aircraft is flying. When it reaches the maximum altitude, a limiter is applied.

9 Aircraft temperature gauge

When using optional weapons or EX actions, the temperature rises, and when it reaches its limit, it will overheat for a certain period of time.

10 Fuel Gauge

This shows how much fuel is left. If it runs out, you will crash.

11 Optional Weapons

Shows available optional weapons and their remaining ammo.

12 Balance Meter

When the durability of your ship decreases, it will shake. When the durability reaches 0, the metal fittings on the balance meter will come off and the ship will crash.

13 Ongoing Missions

This is the objective of the mission. If there are special rules such as cautions or bonus conditions, they will be written in blue or red below.

14. Cumulative prize money and rankings

It shows the total amount of winnings you have earned since you started playing and who among your friends has earned the most.

15 hits

The total number of enemy planes you have shot down since you started playing.



Pause Menu



Back to the game

Close the pause menu and return to playing.



Only displayed when using the Retry Scene Select function or in Time Attack The High Haru. Start over from the beginning.



Operating Instructions

Displays the key operations of the controller being set.



Endless Story Camera

This will be displayed when the Uncut Camera feature is added. It will separate the camera view from the player&#39;s ship.



Save and exit



When you select this item, the options &quot;Save and Exit&quot; and &quot;Exit As Is&quot; are displayed. &quot;Save and Exit&quot; saves the gameplay up to the previous scene to the memory card and then returns to the main menu. &quot;Exit As Is&quot; returns you to the main menu immediately.



Quit

Only displayed when using the Scene Select function, or in Time Attack and Sahaihar. Returns to the main menu without saving any changes.





twenty three



Flight Controls



Aircraft movement



Depending on the operation mode, the key operations, especially for turning, change significantly. In easy mode, you turn directly by pressing the directional keys, whereas in expert mode, you determine the nose direction with yaw and tilt the aircraft with roll to turn. Because the operations are more complex, expert mode allows for more delicate operation and also increases turning speed.



Differences in travel radius depending on operation mode



In easy mode, you turn parallel to the ground, but in expert mode, you turn by twisting the aircraft around using a combination of roll and ascent and descent, resulting in a smaller turning radius and faster turns.



roll

The action of tilting the aircraft left or right

If you keep rolling, it will be 1

It rotates. It can also fly upside down.

It&#39;s possible for a short time.



Yo

This action swings the nose of the plane left and right. You can also finely control the aim of the machine guns and optional weapons.



Beginners can play in easy and normal mode.

The most basic and important thing in combat with aircraft is to operate the aircraft as you want and secure an advantageous attack position. If you are playing &quot;Sky Gunner&quot; for the first time, you will be able to operate your aircraft more intuitively if you set the operation mode to easy and the up and down operation to normal. However, if you are used to traditional flight simulators or 3D shooting games, you may find it easier to operate the aircraft as you want in expert and reverse modes.



This setting is recommended for beginners of 3D shooting games.

In the old days, oars were recommended.







twenty four

Combat Area

The area in which the battle takes place is a box-shaped flying area as shown in the diagram below, and you cannot fly outside of this area. This flying area is called the battle area. If you try to fly outside the side of the battle area, your teammates will warn you, and if you continue flying, your plane will automatically turn 180 degrees and be pulled back into the battle area.



The mission time limit will not decrease, but you will lose bonus counts, so be careful not to leave the battle area.



Limit altitude

When you reach the upper or lower limit of the battle area, a limiter will be applied and you will not be able to go any further. You can see the altitude of your aircraft on the altimeter. Be careful of the altimeter so that you don&#39;t get cornered by the enemy at the limit altitude.



upper limit

Altimeter

lower limit



Obstacles



Even if you are about to hit an enemy battleship or a city building, the flight assist system will function autonomously and will not hit the obstacle, but will only cause you to stumble. However, be careful as control of the aircraft will be taken away for a moment.



Thanks to the flight assist system, even if you hit an obstacle, it won&#39;t be damaged or crash.





fuel



Your plane can fly for roughly 20 minutes before it runs out of fuel, which means it will crash and the game will be over immediately, so keep an eye on your fuel gauge, especially if you&#39;re trying to win a prize.



The fuel gauge will be restored to full when you clear a scene or continue. Fuel will not be reduced by anything other than the passage of time, and boosting or performing EX actions will not consume any extra fuel.



Fuel gauge



The remaining fuel on the fuel gauge is displayed as a colored gauge. The gauge starts out all green, but gradually the percentage of red increases, and after about 110,000 fuel, it turns all red. After about another three minutes, the plane will run out of fuel and crash.





twenty five



Boost



While boosting, your ship&#39;s speed will increase to its maximum. Use the boost when you want to quickly approach a distant enemy or quickly get away from a dangerous enemy. However, your turning ability will decrease, so be careful not to boost too much when chasing a close enemy or aiming at a stationary target.



Boosting



When boosting, maneuverability becomes less effective and the turning radius becomes larger than during normal flight.



Power Slide



This action spins your plane around and moves in any direction to dodge enemy attacks. It makes you completely invincible to any attack for a short time, so it is very effective when you want to attack your target while diving right into the middle of enemy attacks. Also, because the power slide has the tendency to turn the nose of the plane towards the target, it is possible to instantly capture the enemy even from a position where the enemy plane would normally not be visible in the gunsight.



Even if you swing your body widely with a power slide, the direction will be corrected toward the target, so you can avoid it without shifting your aim.



While being chased by Reborn, if you power slide when he tries to bite you you can easily get past him.





How to perform a power slide



Press any directional key firmly and release it quickly



Press the left stick in and then tilt it in any direction



Tilt the left stick in any direction and then press



Power slide up, down, left

You can do this in the four directions on the right.

It&#39;s a nick, so learn how to use it.



Useful for dodging guided missiles and heavy enemy fire.





26



Aircraft balance



When your ship is attacked by enemies, it will lose its balance, the needle on the balance meter will shake, and a ringing warning sound will sound. After a while, the ship will regain its balance and return to normal, but if it is attacked repeatedly or receives a large amount of damage all at once, it will lose its balance completely, the metal fittings on the balance meter will come off, and it will crash.



If you get hit by an enemy attack, you will lose your balance.



If you lose balance completely you will fall.



Balance Meter



Durability and balance recovery of each machine



Aircraft

Durability

Balance recovery



Avenir

185

B (fast)



Chevalier

145

C (slow)



Blanche

210

A (Very fast)



Phantom

160

E (Very slow)



Phantom Custom

210

D (Very slow)



Courage

540

S (very fast)



Although you cannot see it on the screen, your ship also has a durability setting, which decreases depending on the power of enemy attacks. However, unlike enemy ships, your ship recovers its lost durability over time. While the warning sound is playing, your ship&#39;s durability is gradually recovering at a speed that corresponds to the ship&#39;s balance recovery ability. When durability is restored to its maximum, the warning sound will stop. However, if your ship receives damage that exceeds its durability at once, or if damage accumulates faster than its balance recovery ability and its durability reaches 0, it will crash.



Fall and recovery



If you fail, the crash avoidance system will be activated. Press the directional keys and buttons repeatedly within the 10-second time limit. If you hit it the specified number of times, the balance meter will return to normal and your ship will be re-elevated, and you will be invincible for the 0.5 seconds while the letters are displayed.



Each time you fall, the number of times you need to tap the buttons increases, making it more difficult to revive. Each time you clear a scene, the number of times you need to tap the buttons resets to 1.



Number of hits required to recover after falling



Number of falls Number of consecutive hits

1st 20th

2nd time 30 times

3rd time 50 times

4th 70th

5th 90th

6th 110th

7th 130th

8th 160th

9th 180th

10th 200th

11th 230th

12th 260th

13th 290th

14th 320th

15th 400th





If the enemy is possessed



If you are possessed by Phules that will reduce the performance of your ship, or Triborns that will bite you and then explode and cause damage, you can detach them by repeatedly pressing the directional pad. Note that repeatedly pressing the buttons does not have any effect.



The homing missile Triborn will persistently track your ship, bite you, and explode after a certain amount of time has passed. Try to remove it before that happens.



One or two isn&#39;t a problem, but if a large number of Puules attach to it, turning ability decreases and eventually you won&#39;t be able to power slide.



27



perspective



Two Perspectives



You can freely switch between the following two viewpoints while playing. The viewpoint at the start of a mission is normal view, and the viewpoint moves so that the targeted enemy is always on-screen. Also, if you hold down a button in normal view, it will switch to behind view. This is a viewpoint that captures your ship from behind, and is useful for understanding the situation directly in front of your ship. Make good use of the two viewpoints to quickly respond to the situation around you.



Normal View



The viewpoint moves to keep track of the targeted enemy. This is useful when targeting fast-moving light combat aircraft, as you won&#39;t lose sight of the enemy. It also lets you know in which direction the enemy is facing relative to your own aircraft, which is useful when attacking stationary targets such as artillery.



Behind the View



The viewpoint is fixed in front of the player&#39;s ship. It is mainly used when attacking enemies in front of you that cannot be selected as a target. If you get too close to the target and can no longer capture the enemy on the screen, and the viewpoint shifts, you can switch to behind view to make it easier to attack.



Effective use of viewpoint switching



When using normal view, if the enemy you are targeting circles around to the rear of your ship, the field of view shown on the screen will be the field of view behind your ship. In this state, if a battleship or other object approaches from the front of your ship, you may not be able to see the enemy and may fly into their range. If you start to receive attacks and no longer have time to switch your target to the enemy in front, you can switch to behind view to deal with the enemy in front immediately.



Scroll Mode



This is a special viewpoint that is only used in Copan scene 3. Basically, you chase the target like in normal view. However, since the target (ExoZ) in this scene keeps fleeing from the player&#39;s ship, it is positioned in front of the player&#39;s ship throughout the entire scene, making it a rearward viewpoint similar to the behind view. Another feature of this scene is that the course to follow is set, and you cannot move freely off course.



In scroll mode, you cannot switch to another viewpoint or target anything other than Exoze.



28



target



Target Selection



During gameplay, one of the enemies in the area will always be targeted, and if that enemy is defeated or escapes, the next enemy will automatically be targeted. You can also select the enemy you want to target by selecting the target. The targeted enemy will have a target sight, and various information such as durability will be displayed. Basically, you can select up to 16 units as targets, which are displayed on the target display in the upper right corner of the screen.



Target Site

When the target mode is set to Simple, you can select a target with the △ button, and when it is set to All, you can select a target with the R2 button. When the enemy is in a formation, you can only select the leader&#39;s unit.





Target Mode



There are two ways to select targets: simple and all. The targets you can target and the display format of the target display will vary slightly depending on the mode. Simple only displays important enemies, so it is recommended if you want to prioritize clearing the game. All displays almost all enemies and parts, so it is recommended for more advanced play such as bounty hunting. Note that the walkthroughs in this book are performed with the target mode set to all.



simple



Only the enemies that are the highest priority for the mission are displayed on the target display. Other enemies cannot be selected as targets, so you cannot turn on the target sight unless you use a change marker.



&lt;How to select a target&gt;

△ Button

You can target the leader of a formation, the main body of a large aircraft, and specific weapons and parts.





Oar



Nearly all enemies within the area will be displayed on the target display. [After changing units with the R2 button, you can use the △ button to select more specific targets, such as wingmen in the formation or battleship weapons.



&lt;How to select a target&gt;

R2 button

The leader of a formation and the main body of a large aircraft can be selected as targets.

△ Button

You can target weapons and parts of other aircraft in the formation, including larger aircraft.



29



Change target with change marker



Even if an enemy is not included in the normal target selection, you can use the change marker to target it as long as it is within your field of vision. When you aim your gunsight at an enemy that is not a target, a semi-transparent change marker (the word &quot;Change&quot;) will appear. If you press the △ button at this time, the target will switch to the enemy you are aiming your gunsight at, and from then on, the target sight will be on.



You can also change targets for battleship armaments using change markers. Use them in conjunction with the standard target selection.



This is especially useful when you have simplified targeting mode, as it allows you to target targets that are not on the target display.





Target change by Fuhorin



During escort missions, an alarm will sound if the escort target is under enemy attack. If you press the △ button at this time, you can quickly change your target to the dangerous enemy that is attacking your allies. However, if you do not respond immediately after the alarm bell sounds, pressing the △ button will not bring the target sight into focus on the enemy you need to kill in one shot. In that case, a rotating triangle will appear on the icon on the target display, so use that as a guide to find the enemy using the normal target selection method.



The bell has rung. An ally is being attacked somewhere. Immediately press the △ button to change the target.



Enemies that need to be killed immediately will be targeted. Hurry and approach the enemy with the targeting sight and destroy them.



Enemies that need to be defeated will have a triangle mark on their icon on the target display.



Formation and escape



Small combatants are usually in formations of three or more aircraft, but there is only one captain aircraft in the formation that leads the formation. If you shoot down this captain aircraft, in most cases the remaining aircraft in the formation will enter escape mode, stop fighting, and begin to flee outside the area. The mark on the target icon will change from TEAM, which indicates a unit, to ESCAPE. Enemies in escape mode will no longer attack, and there is no danger, so you can let them escape.



30



Attacking enemy aircraft



Machine gun attack



The machine gun is the basic means of attack, and will deal damage to enemies caught within the gun sight. It can be fired unlimitedly, but each shot will reduce the bounty (battle bonus) by 1. The gun sight is always directly in front of the player&#39;s aircraft, and is not displayed on the screen when the aircraft&#39;s nose is facing forward, but it can still attack enemies that are not visible. Note that the number of machine guns equipped and the rate of fire per second vary depending on the aircraft, which is why the performance of the machine guns differs.



Gunsite



Press the square button to fire the machine gun at the gunsite!



When the target is close enough for the machine gun to reach,

A green circle will appear in the get site to let you know you are within range.



Machine gun performance



Aircraft name

Machine gun performance evaluation

Number of machine guns

The power of one bullet

Rapid-fire performance

Attack power per second

Range

Site Size



Avenir

B

x 2 (twin-engine)

8 damage

20 rounds/sec

Approximately 320 damage

Approximately 340m

standard



Chevalier

B

x 2 (twin-engine)

8 damage

20 rounds/sec

Approximately 320 damage

Approximately 340m

standard



Blanche

A

x 2 (twin-engine)

8 damage

30 shots/sec

Approximately 480 damage

Approximately 340m

A little big



Phantom

S

x1 (single shot)

10 damage

60 rounds/sec

Approximately 600 damage

Approximately 400m

small



Phantom Custom

A

x 1 (single shot)

8 damage

60 rounds/sec

Approximately 480 damage

Approximately 400m

big



Courage

B

x 2 (twin-engine)

8 damage

20 rounds/sec

Approximately 320 damage

Approximately 340m

standard



Enemy aircraft durability



When you target an enemy aircraft, its durability is displayed under the targeting site. If you can reduce its durability to 0, you can shoot down the target. As its durability decreases, the color of the target icon on the target display also changes, so you can see how much damage you have done.



When you damage an enemy aircraft, your durability gauge decreases.



Target icon color change

Icon Blue White Yellow Red Shot Down

Durability 100% 75% 50% 25% 0%





For enemy aircraft with many weapons, you can see at a glance how much damage you have done to each part by looking at the target icon. However, if you do not set the target mode to All, the detailed target icon of the Khan will not be displayed.



31



Revived enemy parts



Even if you destroy certain enemy weapons or parts, the Phules will appear and begin repairing them, restoring their durability to full over and over again. They are troublesome enemies, but on the other hand, you can use this to your advantage and earn bounties over and over again.



Take the target icon on the Scret for example: the icons for the destroyed pistons and twin machine guns have been brought back.



Poole will usually let you know in advance if a destroyed part will be repaired - keep an eye on the target icon.



Gunner Machine as an Enemy



Gunner machines appear as enemy aircraft in Ciel and Rival&#39;s story and survival modes, but these will recover their lost durability over time. You can only shoot them down by dealing a large amount of damage in one go.



Even if you deal damage and reduce durability



After a while, your stamina will gradually recover.



■ Enemy Gunner Machine Durability



Aircraft name

Durability



Phantom

2000



Phantom Custom

2200



Avenir

1600





Chain and hit



When enemy planes are lined up or in formation, you can aim for a chain that will cause the enemies to explode in a chain reaction, or a hit that will take them down all at once. If you defeat an enemy using the above methods, the prize money you earn will be increased by a bonus multiplier (for details on prize money earned, see page 39). In addition, chaining creates an explosion that will hit adjacent enemies and deal damage to them. The more chains you make, the greater the damage, so you can easily defeat even highly durable enemies by using chains.



chain



Attack the enemy that explodes!



Enemies from which you can obtain chains include fortress cannons, tanks, and battleship parts.

For more details, please see the Vantor Superweapon Encyclopedia starting on page 41.



They are defeated one after another by the wind, and damage is also increasing!



If you kill an enemy by catching them in the chain blast as shown in the image, the damage of the next blast will double.



Damage x2

Damage x4

Damage x8

Damage x16



hit



It doesn&#39;t have any special effects like Chain, but if you can kill multiple enemies with one attack, you can take it from any opponent.



This is an essential technique for earning plenty of winnings!



32



Optional Weapon Attacks



Optional weapons are special weapons for each unit, and can only be fired up to the number of bullets possessed. When you lock on to an enemy unit, the bullets will have a tracking function, and if you focus the lock on the same target, the power will increase. However, since the temperature of the unit will rise with each shot, excessive continuous firing is not possible. When clearing a scene, the cost used will be deducted from the bounty as OW settlement, and conversely, if you acquire an option during the battle and have more bullets possessed than at the start of the battle, the bounty will be increased by that amount.



There are three types of optional weapons available for each aircraft, and you can switch between them with the L1 button (L2 button for expert mode).



Selected optional weapon



Remaining ammo for selected optional weapon



Defeat certain enemies to obtain optional weapons



During the story, you start with a certain number of optional weapons for each scene, but in other game modes or if you want to increase your remaining ammo, you can obtain them by defeating enemies. When you target a specific enemy, such as an enemy squadron leader or a large combat boat, an optional weapon icon may appear in the upper left corner of the targeting sight. If you shoot down such an enemy, you can obtain the optional weapon that was displayed.



Lock Site



When you press the ◎ button, the gun sight will say &quot;complete.&quot; When you release the ◎ button, the selected optional weapon will be fired. Optional weapons fired without locking on will be treated as single shots.



Number of locks



Number of locks available



When you place the lock sight over an enemy aircraft within the lock-on distance, a green lock mark will appear and you will lock on to it. If you fire an optional weapon in this state, it will track the target. The lock will be released if 6 seconds have passed since you locked on, or if you select another optional weapon.





When you lock on to the same target multiple times, the number of locks on the lock mark machine increases, and the effect of the optional weapon increases. Furthermore, if you focus all locks on the same target up to the maximum number of locks possible, the lock mark will change from green to white, it will be the maximum lock, and the power and effective range will increase.



--------------

Hound Missile



OW settlement cost: 500 bonus per shot

Temperature increase: 11% per shot

Lock-on distance: Approx. 900m



Carrier

Avenir

Chevalier

Blanche

Phantom/modified



For a certain period of time, it will repeatedly bite and release the targeted enemy. It will not deal damage, but it will slow down the movement speed of the target only while it is biting, and will block the attack. The effect time will increase as the number of locks increases. However, it is ineffective against some enemies, who will scream and bounce off.





Number of shots Time to immobilize enemy aircraft

Single shot: approx. 22 seconds

1 lock: approx. 27 seconds

2 locks approx. 30 seconds

Maximum lock (Avenir, Blanche) approx. 35 seconds

3 Lock (Chevalier, Phantom) approx. 32 seconds

Maximum lock (Chevalier, Phantom) approx. 45 seconds

--------------

--------------

Fireworks Missile



OW settlement cost: 4000 reward per shot

Temperature increase: 24% per shot

Lock-on distance: Approximately 600m



Carrier

Avenir

Chevalier

Blanche

Phantom/modified



When it hits an enemy unit, it explodes into a sphere, hitting a wide range of enemies. The range of the explosion increases as the number of locks increases, and the power doubles when the lock is at its maximum. It may be destroyed by enemy bullets before it hits the target, but its durability also increases according to the number of locks.



Number of shots, power, bullet durability, explosion range

Single shot 120 Destroy with one enemy bullet 13.3m

1 lock 120 Destroyed by 2 enemy bullets 13.3m

2 lock 120 Destroyed by 2 enemy bullets 26.6m

Maximum lock (Avenir, Blanche): 240 Destroyed by 3 enemy bullets: 39.9m

3 Lock (Chevalier, Phantom): 120 Destroyed by 3 enemy bullets 39.9m

Maximum lock (Chevalier, Phantom): 240 Destroyed by 3 enemy bullets: 53.2m

--------------



33



--------------

Cross Missile



OW settlement cost: 2000 prize money per shot

Temperature increase: 20% per shot

Lock-on distance: Approximately 800m



Carrier

Avenir

Blanche



After it is stuck in the target, it can be detonated with a machine gun or similar to cause a localized explosion while emitting light, dealing large amounts of damage. Only when it is fully locked on can it become a large cross missile, dealing a lot of damage at once. If it remains stuck in the target for more than 10 seconds, it will self-destruct.



Number of shots Power Range of effect when detonated Time until self-destruct Power when self-destruct

Single shot Approx. 560 4.0m 12 seconds 100

1 lock approx. 560 4.0m 12 seconds 100

2 lock approx. 980 4.0m 12 seconds 200

Maximum lock approx. 1680 6.0m 18 seconds 600



A large-looking Cross Missile. It takes 6 more seconds to self-destruct than a small Cross Missile.

--------------

--------------

Capocha Bomb



OW settlement cost: 1000 reward per shot

Temperature increase: 15% per shot

Lock-on distance: Approximately 600m



Carrier

Chevalier



Because it flies in a unique trajectory, you have to get close to the target to hit it, but its power is strong. As the number of locks increases, the damage doubles. It can be destroyed by enemy bullets, but increasing the number of locks will also increase its durability.



Number of shots Power Range when detonated (typo? this is not detonation range but durability)

Single shot 300 Destroy with two enemy bullets

1 lock 300 Destroyed with 2 enemy bullets

2 lock 600 Destroyed with 3 enemy bullets

3 Lock 1000 Destroy with 3 enemy bullets

Maximum lock: 2000 Destroyed with 4 enemy bullets



It falls in a parabolic trajectory towards the target, so it will not hit enemies above your ship.

--------------

--------------

Bat Missile



OW settlement cost -

Temperature increase: 24% per shot

Lock-on distance: Approximately 700m



A guided missile with high tracking ability, each shot has a power of 350. Increasing the number of locks does not have any particular effect, but it is unlikely that you can avoid the bat missiles that attack from multiple directions at once. If it does not reach the target even after flying a certain distance, it will explode.

--------------

--------------

Command Ammo &quot;Formation&quot;



OW settlement cost -

Temperature rise: 100% per shot

Lock-on distance -



Carrier

Courage



Command bullets are not actually fired, but are attacks in which Ardi issues a command to his subordinates to change the formation of the seven wingmen. &quot;Formation&quot; is the most standard horizontal line formation. It slightly increases the rate of fire of the wingmen, Ordol, who normally only fires bullets sporadically.

--------------

--------------

Command Ammo &quot;Concentration&quot;



OW settlement cost -

Temperature rise: 100% per shot

Lock-on distance -



Carrier

Courage



The Ordols gather in a circle around Courage, concentrating the attacks of all the units on one point. The most notable feature is that it can destroy the shields of Gosh and Drowat, who cannot be damaged by the week. The attack power itself is high, so it can defeat enemies with high durability in a short time.

--------------

--------------

Command Ammo &quot;Scatter&quot;



OW settlement cost -

Temperature rise: 100% per shot

Lock-on distance -



Carrier

Courage



All of the Ordols will fly towards the targeted enemy and launch separate attacks. Surrounding enemies will also join in the attack, but Courage, which has low attack power until it recovers from overheating, will be left alone and may find itself in a difficult situation.

--------------



34



EX Action



Each unit has a special function that allows them to perform powerful attacks or emergency evasions. If you can use them well, they can be a great asset, but each time you use an EX action, the unit&#39;s temperature will rise rapidly.



However, some EX actions can be canceled immediately by pressing the X button after they are performed.



Avenir &amp; Phantom/Kai



Steam Brake Heavy Pallet



This EX action makes you suddenly stop in the air and stay in the air for up to 3 seconds. It is useful when you want to keep damaging your target for as long as possible while approaching it, or when you want to pass an enemy plane chasing you from behind. However, while steam braking, the damage you receive increases by 1.5 times, so avoid staying in areas with a lot of enemy fire. If you cancel after applying steam braking, you will be able to move again immediately. If you fire the machine gun while hovering in the air with steam braking, it will become a heavy bullet with tremendous power. Heavy bullets can kill armored enemies that cannot be damaged by normal machine gun attacks. Avenir fires a concentrated heavy bullet with a short range but high attack power, while Phantom fires a scattered heavy bullet with slightly lower power but 16 bullets at once.



Heavy Bullet Performance



Aircraft name Power (per shot) Range Prize reduction (per shot)

Avenir 240 approx. 185m 750

Phantom/modified 144 approx. 200m 1500



Chevalier



rolling



It&#39;s an EX action that combines offense and defense, where the unit spins around and charges, deflecting enemy bullets while dealing damage to enemies it comes into contact with. The power of the rolling increases the faster the Chevalier&#39;s speed. Assuming that the unit remains in contact with the enemy for 1.5 seconds from the start to the end of the rolling, it can deal about 900 damage at the slowest speed and about 1700 damage at the fastest speed. It can also be canceled one second after starting the rolling.

Blanche



Active Turn



This EX action performs a sharp turn and points the nose towards the target site in an instant. Since it can instantly capture the target from any aircraft position, it can circle close to a highly durable enemy while continuing to deal damage, or reliably pursue a quickly fleeing enemy. Although the active turn itself has no offensive power, it becomes a powerful attack method when combined with optional weapons or machine gun attacks. Since it can target enemy aircraft one after another, it will come in handy when earning bonus counts.





35



Aircraft temperature



overheat



Each time you use an optional weapon or EX action, the unit&#39;s temperature rises, and when it reaches 100%, it will overheat. When this happens, you will be unable to use optional weapons and EX actions for a while until the unit cools down completely, and boost performance will also decrease. The time it takes to recover from overheating to normal differs depending on the unit. You can check the unit&#39;s temperature status with the unit temperature gauge on the left side of the screen.



Normal state

Temperature Rise

overheat



OverheatingThe disadvantages of overheating

EX actions become unusable

Optional weapons become unusable

The maximum boost speed is reduced



Overheating reduces boost performance, but you can still fly fairly fast by repeatedly pressing the boost button.



If you acquire a new engine in Scene 2, the performance of the aircraft will improve. The increased temperature of the aircraft will cool down more quickly.



■ Factors that cause the aircraft temperature to rise



Action Bullet &amp; Action Name Temperature Increase

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Hound Missile 11%

Fireworks Missile 24%

Cross Missile 20%

Optional Weapon Pumpkin Bomb 15%

(per shot) Bat Missile 24%

Command Ammo &quot;Formation&quot; 100%

Command Bullet &quot;Concentration&quot; 100%

Command Ammo &quot;Scatter&quot; 100%

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Steam brake 25%

Heavy Bullet (Avenir) 5%

EX Action Heavy Bullet (Phantom) 16%

(Per time) Rolling 70%

Active Turn 30%

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー



■ Cooling time of the aircraft



Aircraft name Aircraft temperature gauge Overheat recovery time

Cooling rate

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Avenir 6%/sec Approx. 16 seconds

Avenir [New engine] 7%/sec Approx. 14 seconds

Chevalier 6%/sec Approx. 16 seconds

Chevalier [New engine] 7%/sec Approx. 14 seconds

Blanche 7%/sec approx. 14 seconds

Blanche [New engine] 8%/sec Approx. 12 seconds

Phantom 6%/sec Approx. 16 seconds

Phantom Kai 10%/sec. approx. 10 seconds

Courage 5%/sec Approx. 20 sec

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー



The aircraft thermometer

Be careful not to get to 100%



36



Story progression



mission



In the story, Fam, Shell, and Copan basically work together to carry out operations, and the role each of them is assigned is called a mission. By clearing these missions in stages, the scene will be cleared. While your ship is carrying out a mission, your allies will also be fighting somewhere in the area, and you can earn more bounty money by defeating their attack targets first. If you complete a mission too quickly or fail, you may be transferred to another mission.

(?typo sentence ends abruptly)



A new Mitsunmin or Hashimaru will appear with instructions. It will disappear immediately, but you can check it again by pausing.



If you complete a mission efficiently and have some extra time, you can help other characters with their missions.



Allied protection



If your cargo ship, reconnaissance vehicle, or allied machine is under attack by the enemy, an alarm bell may sound to warn you of the danger. In such cases, you must shoot down the enemy machine that is causing the danger as soon as possible. If you leave it alone, your ally will be shot down, your bounty will be reduced, and in some cases, the game will be over. In many cases, the number of remaining allies, the time limit, and the degree of danger will be displayed in the upper left corner of the screen, so head to escort them before it&#39;s too late.



Cargo ships and police vehicles also have durability, just like your own plane and enemy planes, and if their durability reaches 0 due to enemy attacks they will be destroyed.



When your teammates&#39; machines or Ardi&#39;s armored car are attacked, the danger level in the upper left corner of the screen will increase. When it reaches 100%, the game is over.



Game Over and Continue



If you fail to revive because you can&#39;t use the wall fall avoidance system in time, if you run out of fuel, or if you fail to protect your allies, the game will be over.



You can continue and restart the game up to three times per game, but any winnings you earned in that scene will be reset to zero and you will be sent back to that specific mission.



The commemorative photo taken by Vantle will change depending on the character used and the circumstances of the Game Over (→ P.130).



When the game is over, I&#39;ll take a picture to commemorate my victory.



37



event



Among the missions, those that affect the bonus money awarded when a scene is cleared are called events. Below each mission instruction, the conditions are displayed in blue for those that will give you a bonus if you succeed, and in red for those that will result in game over if you fail. Most of the missions are quite difficult, such as shooting down enemy planes within a time limit or protecting allies from multiple enemies, but if you succeed, you will be evaluated in the ranking certification when you clear them all (→P124).



ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

FAM (Number of events: 9)



----

Scene 1

----

Applicable missions



Defeat the defense forces guarding the tow plane (→ P79)

Get a bonus if you defeat the tow plane defense force!

● If you &quot;shoot down the tow plane defense team plane&quot; the event will be successful.





----

Scene 2

----



Applicable missions



Defeat the combat boats and protect the cargo fleet (→ P86)

If the cargo fleet is destroyed, the operation will fail.

● If the number of cargo ship crashes is 8/15, the event is successful.



Applicable missions



Defeat many enemies and defeat Grandir (→P87)

Whoever earns the most prize money wins an engine!

● If you destroy the lightning generator, the event will be a success.





----

Scene 3

----



Applicable missions



Stop the mechanical soldiers from invading (→P93)

Bonus if you thwart the invasion!

Recover the telescope from the mechanical soldier (→ P94)



● If you &quot;Defend the Canal Bridge&quot;, the event will be successful.

● If you &quot;Defend the gate&quot;, the event will be successful.

● If you &quot;Defend the Telescope&quot;, the event will be successful.



----

Scene 4

----



Applicable missions



Defeat the defense forces guarding the fortress (→P104)

Get a bonus if you defeat the defenses before they charge in!



●If you &quot;shoot down all the planes in the fortress defense force&quot;, the event will be completed.





Applicable missions



Defeat the Skrets while protecting the armored vehicle (→P105)

If the armored vehicle is destroyed, the operation will fail.

● If the number of police planes crashing is 4/6, the event is successful.



----

Scene 5

----



Applicable missions



Defeat Vantor (→P121)

Bonus for defeating Vantor!

● If the &quot;Vantol ship is destroyed&quot;, the event will be successful.



ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Ciel (Number of events: 9)



----

Scene 1

----

Applicable missions



Defeat Star Bull in front of the hangar (→P80)

Bonus if you defeat Fam before she escapes!

● If you &quot;Rescue Fam&quot;, the event will be successful.





----

Scene 2

----



Applicable missions



Defeat the combat craft and protect the cargo fleet (→P88)

Defeat Balaine and protect the cargo fleet (→P88)

If the cargo fleet is destroyed, the operation will fail.

● If the number of cargo ship crashes is 8/15, the event is successful.



Applicable missions



Defeat the fleeing combat boats (→P89)

Bonus if you defeat all the combat boats!

● If you &quot;shoot down all Star Bulls,&quot; the event will be a success.





Applicable missions



Defeat many enemies and defeat Grandir (→P89)

Whoever earns the most prize money wins an engine!

● If you destroy the lightning generator, the event will be a success.





----

Scene 3

----



Applicable missions



Defeat the black gunner (→P97)

Bonus if you defeat the black gunner!

● If you defeat Rival, the event will be a success.



----

Scene 4

----



Applicable missions



Defeat the Drad guarding the fortress

If you defeat the two Dorados before entering, you&#39;ll get a bonus!

● If you sink two Dorados, the event is a success.





Applicable missions



Defeat the Skrets while protecting the armored vehicle (→P107)

If the armored vehicle is destroyed, the operation will fail.

● If the number of police planes crashing is 4/6, the event is successful.



----

Scene 5

----



Applicable missions



Defeat Rival (→P117)

Bonus for defeating Rival

● If you defeat Rival, the event will be a success.



Applicable missions



Defeat Vantor (→P121)

Bonus for defeating Vantor!

● If the &quot;Vantol ship is destroyed&quot;, the event will be successful.



ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Copan (Number of events: 8)



----

Scene 1

----

Applicable missions



Defeat the mechanical soldiers on the hill (→ P82)

Bonus if you defeat Fam before she escapes!

● If you defeat the mechanical soldiers, the event will be a success.





----

Scene 2

----



Applicable missions



Defeat the combat craft and protect the cargo fleet (→P88)

If the cargo fleet is destroyed, the operation will fail.

● If the number of cargo ship crashes is 8/15, the event is successful.



Applicable missions



Defeat the assault pods and protect the cargo fleet (→P90)

Bonus if you defeat all the pods!

● If you &quot;shoot down all the assault pods&quot; the event will be a success.





Applicable missions



Defeat many enemies and defeat Grandir (→ P87)

The person who earns the most prize money will win an engine!

● If you destroy the lightning generator, the event will be a success.





----

Scene 3

----



Applicable missions



Defeat Exoze until you get out of the waterway (→P99)

Get a bonus if you destroy all the Exozes!

● &quot;Exoze Gold Destruction&quot;,

&quot;Exozered Destruction&quot;,

If you destroy the Exoze Blue, the event will be a success.





----

Scene 4

----



Applicable missions



Destroy the artillery batteries guarding the fortress (→P108)

Get a bonus if you destroy all the artillery turrets before they charge in!

● If you &quot;destroy all the artillery&quot;, the event will be successful.





Applicable missions



Defeat the Skrets while protecting the armored vehicle (→P109)

If the armored vehicle is destroyed, the operation will fail.

● If the number of police planes crashing is 4/6, the event is successful.



----

Scene 5

----



Applicable missions



Defeat Vantor (→P121)

Bonus for defeating Vantor!

● If the &quot;Vantol ship is destroyed&quot;, the event will be successful.



ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー



Demo Skip



You can skip the demo scenes at the turning points of the story part and the battle part with the SELECT button. During the demo scene, you may be able to move your ship while the characters are talking, but the enemy ships are also moving during that time. If you skip the demo scene immediately without watching it to the end and start the mission, the enemy ship will be in the position where you skipped, so it will often be easier to attack.



In Copan Scene 3, if you skip the demo without watching it to the end, you can increase the distance that Exoze can escape from you.





38



Prize Money



Prize Money



The goal of the story is to clear all scenes, but as a full-fledged gunner, it&#39;s also important to earn as much bounty money as possible. If you clear all of them and have earned more total bounty money than your comrades, hidden elements will appear (→ P124). You earn bounty money each time you clear a scene, depending on how you played. Your play is evaluated based on the following five items, and a bounty is calculated for each. The total amount is earned as the scene total.



Clear Bonus



This is the prize you get when you clear a scene. The further you progress to the next scene, the higher the prize becomes.



Scene Clear Bonus

1 50000

2 100000

3 150,000

4 200000

5 250000



Event Bonuses



This is the prize money you can earn when you successfully complete the events that occur for each character in each scene. However, if the event fails, the prize money may become negative.



Scene Character Event Price

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Fam: Shoot down all tow planes in the defense force 100,000

1. Rescue Ciel Fam 200,000

Copan: Defeat the Machine Soldier 200,000

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Ciel: Shoot down all Star Bulls 400,000

Copan: Shoot down all assault pods 400,000

2 All Cargo ship crashes (per ship) - 50,000

All Lightning Generator Destruction 400000

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Fam Canal Bridge Defense 300000

Fam Floodgate Defense 200000

Fam Telescope Defense 1000000

3 Fam Telescope Destruction - 1,000,000

Ciel Rival Defeat 1500000

Copan Exoze Gold Destruction 300,000

Copan Exozered Destruction 500000

Copan Exoze Blue Destruction 700000

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Fam: Shoot down all planes in the Citadel Defense Force 1,000,000

4 Ciel 2 Dorados sunk 1,000,000

Copan Destroy all artillery turrets 1,000,000

All Police plane crashes (per plane) -150,000

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

5. Ciel Defeat Rival 1,500,000

All Vantor ships destroyed 2,000,000

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー



Number of kills



The prize money you receive depends on the number of enemy aircraft and weapons you destroy. Regardless of the type of enemy, the more you destroy, the higher the prize money will be.



● Earned prize money = number of knockdowns x 500





OW settlement



The amount of prize money you receive depends on the number of optional weapons you have. The more optional weapons you obtain by defeating enemies, the more the amount increases, and the more you use them, the less the amount decreases. Please note that the OW settlement cost varies depending on the optional weapon (→ P32).



The following calculation will be done for each optional weapon type, and the total of the three amounts will be the prize money you will receive when you settle your OW account.



● Cultivation prize money =

(Number of items owned when scene is cleared - number of items owned at scene start) x OW settlement cost



Battle Bonus



This is the bounty that is displayed on the screen during gameplay. When you defeat an enemy, you will receive a bounty according to the type of enemy, and if you get a chain or a hit, the amount will increase. On the other hand, if you are attacked by an enemy or fall, the amount will decrease.



● Bonus reduction when damage is received = Enemy attack power x 15

● Reduced prize money in case of crash = Approximately 19,000 per second













39



Bonus Multiplier



Battle bonuses are usually awarded according to the enemy you defeated, but if you defeat an enemy and your bonus count is increasing, and you score a hit or a chain, a bonus multiplier according to the count will be added to your winnings. In order to earn a larger prize, it is important to aim for hits and chains while increasing the ever-decreasing bonus count.



Bonus Multiplier



The more bonus counts you get, the higher it goes (maximum x99).



Bonus Count



The counter increases with each enemy you defeat, and decreases if you do nothing.



■ Bonus count and bonus multiplier



Bonus count Bonus multiplier

0x1

1-10 x 2

11-20 x 3

21-30 x 4

31-40 x 5

41-50 x 6

51-60 x 7

61-70 x 8

71-80 x9

81~90 x10

91~100 x11

101~110 x12



After that, the bonus multiplier will increase by 1 for every 10 bonus counts.





Chain and hit prize calculation



When you get a chain and a bit, the number will be displayed on the screen, followed by the calculated prize amount and bonus multiplier. Basically, you don&#39;t have to think too hard about it, just aim for a larger number of chains and hits, but for reference, we have posted the prize calculation method for chains and hits below. Please use it for research to win more prizes.



Calculating winnings during chain



● Bounty of defeated enemy + {(Bounty of defeated enemy x a) + (Bounty of defeated enemy x a) + (Bounty of defeated enemy x a)...} x bonus multiplier



*a is the chain multiplier according to the number of chains when the enemy is defeated. Please refer to the table below. The maximum number of chains is 10.



The bounty money for the enemies killed in the second chain through the final chain is calculated individually, and the total is multiplied by the bonus multiplier. Please note that the bounty money for the enemy that started the chain is added as is and does not have a bonus multiplier.



Chain number Chain multiplier

2 x 2

3 x 4

4 x 8

5 x 16

6 x 32

7 x 64

8 x 128

9 x 256

10 x 256



&lt;Example&gt;

When Tank A, with a bounty of 400, is defeated with a 5-chain (bonus multiplier is x2)

400+1(400x2)+(400x4)+(400x8)+(400x16)}x2=24400



Winnings calculation when hitting



●Total bounty of defeated enemies x number of hits x bonus multiplier



Calculating the bounty for a hit is simpler than for a chain. Just add up all the bounties of enemies hit in a single hit, then multiply that by the number of hits shown and the bonus multiplier to get the bounty you&#39;ll receive.



&lt;Example&gt;

When Tank A, with a bounty of 400, is defeated with 5 hits (bonus multiplier is x2)

(400+400+400+400+400)x5x2=20000



40



INTERMISSION Tea Time Column -Vol.2-



Tea Time Column No. 2

Gunner machine manufacturer



In the world of &quot;Sky Gunner,&quot; there are several companies that manufacture gunner machines. Here, we will introduce the corporate philosophy and product features of each manufacturer.



-------------------------------------------------- --------------------

Horloger

Horloger Company



Horloger, a large domestic industrial manufacturer with its headquarters in Neige, started out as a watch workshop and began making flying machines. The company was founded during the early days of flying machines, so it is no exaggeration to say that Horloger&#39;s history is the history of flying machines. The company values tradition and formality, and their aircraft, which use a lot of wood, offer a unique sense of ownership. However, they do not make outdated machines, but rather use cutting-edge technology to produce high-performance flying machines. Furthermore, Horloger&#39;s flying machines are more expensive than those of other companies, and can be said to be products for the upper class.



The Blanche, operated by the luxury cruise ships Merville and Fam, is a beautiful aircraft that exudes the elegance and dignity of the Orloger company.



-------------------------------------------------- --------------------

Associate

Associe Company



The company focuses on weapons-related products and has its headquarters in the town of Associés. Although it is a relatively new flying machine manufacturer, it has produced many famous machines and has now grown to be a manufacturer on par with Horloger. Associés has become a huge company in a short period of time and has an ambitious image, which is also reflected in the characteristics of the products it produces. It prefers to use materials from new technologies and creates flying machines with unusual designs. Incidentally, the Chevalier that Copain rides in also uses a special pot that was recently developed.



The manufacturer prefers to use iron pots, and the Vaincourt, which was created for the Rive Exposition, is on full display in its entirety.



-------------------------------------------------- --------------------

Artisan

Artisan Company



Artisan is a company with a strong sense of craftsmanship. Its headquarters are in Reeve, but it is small, with only small workshops spread across the country. It is a manufacturer that pursues functionality and rationality, and is known for using the right materials for parts in the right places, effectively combining metal and wood. Although they are not good at mass production, they assemble each machine with care, so they can accept various orders as if they were custom-made. All of the machines they create are high-performance, and they sometimes complete rare masterpieces, such as Ciel&#39;s beloved machine Avenir and Rival&#39;s Phantom.



-------------------------------------------------- --------------------

DuR

Dürr



Deul, which developed from an automobile manufacturer, has its headquarters in the southern part of Neige. The company excels at mass production and mass sales, and with the motto of &quot;usefulness and durability,&quot; it provides inexpensive flying machines that are affordable for the common people. All of its products are durable and hard to break, and Deul&#39;s machines are also used in general patrols. The Courage that Ardi rides and the Ordre used by the Leev city patrol flying boat unit are also standard Deul products. However, in terms of machine performance, Deul lags behind other manufacturers, and the reality is that there are very few drivers who use Deul&#39;s machines.



-------------------------------------------------- --------------------





41



Vantor Superweapon Encyclopedia







42



How to read the Vantor Superweapon Encyclopedia



This is a comprehensive illustrated guide to all the weapons used by the Vantles that appear in the game. They are listed by type, roughly in the order in which they appear. You can use it to find ways to deal with them based on their attack methods and behavior patterns, use it as a reference for earning bounties, or just admire the machine designs.



1. Name and manufacturer

It indicates the name of the aircraft and the manufacturer. The meaning of the manufacturer&#39;s mark is as follows.



- Handmade by Ventre

- Dürr product (stolen goods)

- Associe products (stolen goods)

- Horloger product (stolen goods)



2. Appearance

This is the exterior of the unit. Weapons and destructible parts are pulled out. The numbers correspond to the target icon and weapon &amp; part data.



3. Target Icon

The icon displayed on the target display in the upper right corner of the screen. This is when the target mode is set to all, and some aircraft have parts other than the main body. The numbers on the parts correspond to the appearance drawers and the weapon &amp; parts data.



4. Aircraft data

Overall length: The size of the aircraft.

Maximum speed: The maximum speed of the aircraft. Something that doesn&#39;t move doesn&#39;t have a maximum speed.

Durability: This is the durability of the unit. You can attack if you deal damage equal to this amount. However, if the unit has a () in it, you cannot attack unless you destroy a specific part.

Prize money: Prize money (battle bonus) that you receive when you shoot down a plane.

Count: This is the bonus count that increases when you shoot down an enemy.



5. Weapon and parts data

Name: Corresponds to the appearance drawer. Numbers correspond to the appearance drawer and target icon.

Number of equipment: The number of each weapon and part equipped. Weapons that can be destroyed at one time are considered as a group and expressed as a base number, and the number of each group is shown.

Durability: The durability of each weapon and part. If you inflict damage equal to this value, it will be destroyed. If the value is not a number and is equal to 1, it will be destroyed along with the unit itself.

Prize money: The prize money (battle bonus) you earn for each unit destroyed.

Count: This is the bonus count that increases for each unit destroyed.

Attack method: The type of bullets each weapon fires and its special attack. Some weapons have multiple attack methods. Please refer to the table on the right for bullet types. Some bullets can be destroyed.

Power: The damage of each hit.



6. Chain Point

The diagram shows points where chain reactions can occur due to secondary explosions.



7. Special Attacks

Each aircraft has its own unique attack method and data is compiled.



-------------------------------------------------- ----------------

Ammo Type

-------------------------------------------------- ----------------

Machine gun bullets

Power 30

Range: Approximately 300m

Durability -



This is the bullet most weapons fire. It has low power but is fired in rapid succession.

-------------------------------------------------

Shells

Power 40

Range: Approximately 450m

Durability -



The bullets look like glowing balls of light. Their flight speed varies depending on the weapon used to fire them.

-------------------------------------------------

Iron ball bullet

Power 90

Range: Approximately 450m

Durability 20



When fired, the projectile travels relatively slowly and explodes upon reaching its range.

-------------------------------------------------

Pistol Bullets

Power 90

Range: Approximately 480m

Durability -



A huge projectile that travels in a straight line at high speed and explodes when it reaches its range.

-------------------------------------------------

High Velocity Bullets

Power 150

Range: Approximately 1,500 m

Durability -



A high-velocity, long-range pistol bullet that leaves a cloud behind it.

-------------------------------------------------

Triborn

Power 180

Tracks the aircraft to some extent

Durability -



After biting, it will explode after a certain amount of time. If you are bitten, you can release it by repeatedly pressing the directional buttons.

-------------------------------------------------

Shark Missile

Power 70

Tracks the aircraft to some extent

Durability 60



After being launched, it will hover for a while, then glow red and fly towards your ship.

-------------------------------------------------- ----------------



43



Petit Combat Boat Premier



PREMIER



A small combat boat made for the Phule. Its performance is not particularly high, but it is a highly versatile machine that can be equipped with a variety of weapons by replacing parts.





ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Premier A

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

The most standard Premier. It flies by spinning the wings on both sides of the fuselage. It is equipped with a machine gun as a fixed weapon.



MACHINE DATA

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Total length: 6.0m

Maximum speed 420km/h

Durability 10

Prize money: 100

Count 2 seconds

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

WEAPONS &amp; PARTS

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Name Number of equipment Durability Prize Count Attack method Power

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Machine gun 1 unit x 1 - - - Machine gun bullets 30

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー





ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Premier B

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

This type is equipped with a pod for the guided missile &quot;Triborn&quot; on the bottom of the fuselage. It is also capable of towing an assault pod.





MACHINE DATA

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Total length: 6.0m

Maximum speed 420km/h

Durability 10

Prize money: 100

Count 2 seconds

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

WEAPONS &amp; PARTS

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Name Number of equipment Durability Prize Count Attack method Power

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Machine gun 1 unit x 1 - - - Machine gun bullets 30

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Pod 1 unit x 1 - - - Triborn 180

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー





ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Premier C

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

A highly maneuverable model with its blades removed and a propeller engine installed. It is mainly piloted by Captain Poulet, who leads the Premier formation.





MACHINE DATA

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Total length: 6.0m

Maximum speed 620km/h

Durability 20

Prize money: 100

Count 2 seconds

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

WEAPONS &amp; PARTS

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Name Number of equipment Durability Prize Count Attack method Power

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Machine gun 1 unit x 1 - - - Machine gun bullets 30

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Pod 1 unit x 1 - - - Triborn 180

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー



ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Premier D

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

A type equipped with a movable pistol cannon for taking down airships, etc. It can fire powerful bullets in any direction.



MACHINE DATA

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Total length: 6.3m

Maximum speed 400km/h

Durability 20

Prize money: 100

Count 2 seconds

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

WEAPONS &amp; PARTS

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Name Number of equipment Durability Prize Count Attack method Power

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Machine gun 1 unit x 1 - - - Machine gun bullets 30

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Pistol gun 1 unit x 1 - - - Pistol bullets 180

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー



* The Bloomiers aboard Gauche and Drowat are blue in color, and when defeated the bonus count will be 1 second.









44



Combat Boat Progress



PROGRES



A capital combat boat with improved performance based on the Premier. Equipped with a powerful searchlight as standard, it is fully capable of night operations.





ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Progressive A

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

This is the standard type among the Progres. It is equipped with a float with a built-in cannon on the bottom of the fuselage, allowing it to take off and land on water.



MACHINE DATA

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Total length: 7.0m

Maximum speed 320km/h

Durability 15

Prize money: 200

Count 2 seconds

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

WEAPONS &amp; PARTS

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Name Number of equipment Durability Prize Count Attack method Power

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Machine gun 1 unit x 1 - - - Machine gun bullets 30

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Float 1 unit x 1 - - - Shells 40

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー



ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Progressive B

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー



An enhanced model with increased firepower. In addition to shells, the pod can also fire powerful guided missiles called &quot;Triborns.&quot;





MACHINE DATA

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Total length: 7.0m

Maximum speed 320km/h

Durability 15

Prize money: 200

Count 2 seconds

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

WEAPONS &amp; PARTS

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Name Number of equipment Durability Prize Count Attack method Power

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Machine gun 1 unit x 1 - - - Machine gun bullets 30

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Shells: 40

Pod 1 unit x 1 - ...

Triborn 180

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー





ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Progressive C

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー



The balloon has been removed and a high-output engine has been installed, enabling boost acceleration. It is used as a squadron leader&#39;s machine.





MACHINE DATA

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Total length: 8.6m

Maximum speed 650km/h

Durability 25

Prize money: 200

Count 2 seconds

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

WEAPONS &amp; PARTS

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Name Number of equipment Durability Prize Count Attack method Power

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Machine gun 1 unit x 1 - - - Machine gun bullets 30

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Shells 40

Pod 1 unit x 1 - ...

Triborn 180

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー





45



Combat boat Dernie



DERNIER



A cutting-edge combat boat for the Pulau, modeled after the Gunner Machine. Equipped with a defensive mechanism that rotates to deflect enemy bullets, it is a small but powerful machine.





ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Dernie A

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー



A prototype Dernier. Normally it only uses machine guns, but when forming the following formation, it also uses other weapons.



MACHINE DATA

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Total length: 5.6m

Maximum speed 420km/h

Durability 25

Prize money: 300

Count 4 seconds

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

WEAPONS &amp; PARTS

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Name Number of equipment Durability Prize Count Attack method Power

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Machine gun 1 unit x 1 - - - Machine gun bullets 30

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Pistol Ammo 90

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Pod 1 x 1 - - - Shark Missile 70

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Shells: 40

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー





ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

(Old Castle Defense Force)

Dernie A

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

An improved version of the Ace-spec Dernier with enhanced mobility and durability. Its distinctive feature is its green body.



MACHINE DATA

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Total length: 5.6m

Maximum speed 550km/h

Durability 90

Prize money: 300

Count 2 seconds

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

WEAPONS &amp; PARTS

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Name Number of equipment Durability Prize Count Attack method Power

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Machine gun 1 unit x 1 - - - Machine gun bullets 30

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Pod 1 x 1 - - - Shark Missile 70

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー





ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Dernie B

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー



A finished model modeled after the Chevalier, based on the data from the prototype. The weapon has been replaced with one with superior rate of fire.



MACHINE DATA

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Total length: 6.5m

Maximum speed 420km/h

Durability 40

Prize money: 300

Count 2 seconds

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

WEAPONS &amp; PARTS

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Name Number of equipment Durability Prize Count Attack method Power

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Enhanced machine gun 1 unit x 1 - - - Machine gun bullets 30

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Pod 1 unit x 1 - - - Triborn 180

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー





ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

(Old Castle Defense Force)

Dernie B

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー



This is the ace version of the Dernier B, and has the best performance. Only three of these will take part in the defense of the old castle.



MACHINE DATA

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Total length: 6.5m

Maximum speed 550km/h

Durability 150

Prize money: 300

Count 2 seconds

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

WEAPONS &amp; PARTS

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Name Number of equipment Durability Prize Count Attack method Power

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Enhanced machine gun 1 unit x 1 - - - Machine gun bullets 30

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Pod 1 x 1 - - - Shark Missile 70

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー





Dernier A formation



The Derniers of the Formation Corps, who are defending the old castle, fight in three different formations. During formation, only the leader machine at the front can be damaged. If the leader machine is shot, the formation will break, but a new leader machine will be erected and the formation will be reformed.



Fish formation



This is the first formation that Dernier sets up, and is characterized by its fish-like shape when viewed from the side. It attacks approaching enemies with shark missiles and distant enemies with pistol bullets.



Dragon Formation



When the fish formation falls apart, this is the next formation to form. All the Derniers line up in a vertical line and move in a wavy motion while scattering shells.



Snake formation



A formation formed after the third reorganization. It is characterized by large spiral rotations. The formation is the same as the Dragon formation, but the attacks have changed to machine gun bullets.



46



Exoplanet Combat Vessel



EXOCET



It is a snake-shaped surface combatant that flies with a leader aircraft and 15 sub-aircraft. As long as the leader aircraft is not destroyed, it can dive and instantly repair damage.



ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Exoz Gold

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー



The Exoze commander&#39;s machine appears in the waterways of the city of Lyvne with its lights flashing mysteriously. Its appearance has led to rumors that it is a mysterious monster.



MACHINE DATA

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Total length: 15.0m

Maximum speed 780km/h

Durability 1500

Prize money: 10,000

Count 10 seconds

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

WEAPONS &amp; PARTS

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Name Number of equipment Durability Prize Count Attack method Power

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Machine gun bullets 30

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Gun turret 1 unit x 1 - - - Ammo 50

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Lightning 80

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー



Blitz



Power 80. Places child units around it and fires a ring of electric shocks in rapid succession. Chevalier&#39;s rolling attack cannot block this.







ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Exozered

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー



The ExoZe commander&#39;s unit is made up of two balloon-type artillery turrets connected by wires. It fires pistol bullets and shark missiles.



MACHINE DATA

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Total length: 9.0m

Maximum speed 780km/h

Durability 1900

Prize money: 10,000

Count 10 seconds

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

WEAPONS &amp; PARTS

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Name Number of equipment Durability Prize Count Attack method Power

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Machine gun bullets 30

Gun turret 1 x 2 - ...

Shark Missile 70

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Pistol gun 1 unit x 2 - - - Pistol bullets 60

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー



Pistol Bullet



Power 60. Place child units around the target and fire pistol bullets in rapid succession. Power is slightly lower than that of a normal pistol bullet.





47



ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Exoze Blue

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー



The ExoZe&#39;s commanding officer has vertical wings like fish fins. Poole operates the searchlight and machine gun and also throws bombs.



MACHINE DATA

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Total length: 9.0m

Maximum speed 780km/h

Durability 2200

Prize money: 10,000

Count 10 seconds

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

WEAPONS &amp; PARTS

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Name Number of equipment Durability Prize Count Attack method Power

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Machine gun bullets 30

Gun turret 1 x 2 - ...

Bomb 80

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー



Explosive



Power 80. A bomb that Bule throws at your ship. It has a durability of 250 and can be destroyed by shooting it.





ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Exoze child device

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー



It is a mobile artillery platform that has no power source and is towed by the commander&#39;s unit. By attaching different weapons, it can carry out the same attacks as the commander&#39;s unit.



MACHINE DATA

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Total length: 5.3m

Maximum speed -

Durability 100

Prize money: 400

Count 2 seconds

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー





Surface attack craft Detroit



DETROIT



A small attack boat that glides across the water&#39;s surface. It has low durability, but excellent maneuverability, and the machine gun bullets it fires from its turrets are a force to be reckoned with.



ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Detroit A

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー



A standard aircraft of the Detroit Squadron. Led by the captain, it races across the water. The gun turret can aim in any direction, 360 degrees.



MACHINE DATA

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Total length: 4.5m

Maximum speed 220km/h

Durability 50

Prize money: 300

Count 1 second

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

WEAPONS &amp; PARTS

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Name Number of equipment Durability Prize Count Attack method Power

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Gun turret 1 unit x 1 - - - Machine gun bullets 30

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー





ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Detroit B

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー



The Detrois unit&#39;s captain&#39;s machine. Aside from a slight increase in durability, there is no particular difference in performance from a regular machine.



MACHINE DATA

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Total length: 4.5m

Maximum speed 220km/h

Durability 60

Prize money: 300

Count 1 second

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

WEAPONS &amp; PARTS

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Name Number of equipment Durability Prize Count Attack method Power

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Gun turret 1 unit x 1 - - - Machine gun bullets 30

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー





48



Phule &amp; Assault Pod



POULET &amp; MISSILE



An assault weapon with five brave Phules riding on a Durr-made assault pod. When the pod gets close to the target, it explodes and the Phules jump out and attach themselves to it.







ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Assault Pod A

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー



In scene 2, an assault pod attacks, aiming for the cargo ship&#39;s engine.



MACHINE DATA

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Total length: 10.0m

Maximum speed 320km/h

Durability 30

Prize money: 10,000

Count 4 seconds

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー





ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Assault Pod B

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー



This is the assault pod that Premier tows in Scene 3 in Copan.



MACHINE DATA

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Total length: 10.0m

Maximum speed 390km/h

Durability 35

Prize money: 10,000

Count 4 seconds

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー





ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Assault Pod C

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー



In scene 4, this is an assault pod targeting Ardi&#39;s armored vehicle from underwater.



MACHINE DATA

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Total length: 10.0m

Maximum speed 320km/h

Durability 75

Prize money: 10,000

Count 4 seconds

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー





ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Assault Pod D

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー





This is the assault pod launched by the Drads and Skrets in Scene 4.



MACHINE DATA

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Total length: 10.0m

Maximum speed 540km/h

Durability 80

Prize money: 10,000

Count 4 seconds

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー





Poulet



Pullets that attach to your ship. The more pullets there are, the worse your turning performance will be. Furthermore, if you attach to more than a certain number of them, you will not be able to power slide. You can shake them off by repeatedly pressing the directional keys.





Hammer pulley



A pullet that attaches to cargo ships and armored vehicles. Hits targets to deal damage. Scares them away with a hound missile.





Feather Bomb



WING BOMB



A cannonball-like weapon with flapping wings. It tracks its target, rams into it, and explodes. It is installed on the Gauche, Droitt, and Grandir.



MACHINE DATA

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Total length: 3.5m

Maximum speed 300km/h

Durability 180

Prize money: 3,000

Count 2 seconds

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー



The power of the feather bomb when it explodes is 180, and when it is hit by lightning from a lightning generator, a halo is given to it and its tracking ability and flight speed increase.



49



Large combat boat Star Bull



STABLE



A large combat vessel that acts as the command center for the attack force. Although it is already outdated, its firepower and durability far exceed those of normal combat vessels.



ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Star Bull C

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー



The Black Winged Star Bull is an improved version of the early model, with significantly increased firepower and durability.



MACHINE DATA

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Total length: 68.0m

Maximum speed 300km/h

Durability 2200

Prize money: 8,000

Count 20 seconds

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

WEAPONS &amp; PARTS

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Name Number of equipment Durability Prize Count Attack method Power

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

1 side gun 1 unit x 4 120 1000 4 seconds shells 40

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

2 Gatling guns 1 x 2 300 2000 4 seconds Machine gun bullets 30

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Machine gun 1 unit x 1 - - - Machine gun bullets 30

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー





ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Star Bull A

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー



Early model Star Bull. Armed with only side guns and machine guns. Wings are reddish brown in color.



MACHINE DATA

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Total length: 68.0m

Maximum speed 200km/h

Durability 1500

Prize money: 3,000

Count 20 seconds

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

WEAPONS &amp; PARTS

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Name Number of equipment Durability Prize Count Attack method Power

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

1 side gun 1 unit x 4 120 1000 4 seconds shells 40

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Machine gun 1 unit x 1 - - - Machine gun bullets 30

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー





ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Star Bull B

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー



This is the most commonly used mass-produced Star Bull. It has blue wings.



MACHINE DATA

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Total length: 68.0m

Maximum speed 150km/h

Durability 1000

Prize money: 4,000

Count 10 seconds

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

WEAPONS &amp; PARTS

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Name Number of equipment Durability Prize Count Attack method Power

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

1 side gun 1 unit x 4 120 1000 4 seconds shells 40

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

2 Gatling guns 1 x 2 300 2000 4 seconds Machine gun bullets 30

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Machine gun 1 unit x 1 - - - Machine gun bullets 30

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー





50



Minebiki Battleship Gauche &amp; Drowatt



GAUCHE &amp; DROITE



A small battleship with three ship-capturing tows on its wings. Two of them operate in pairs. Using compressed fuel, they can carry away captured prey at incredible speeds.



ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Gauche

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー



This red battleship was built as the first of a series of towing battleships. The towing engine is attached to the right side of the hull.



MACHINE DATA

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Total length: 110.0m

Maximum speed 1000km/h

Durability 500

Prize money: 5,000

Count 1 second

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー



bridge



Destroying this will cause storage guns to appear on both sides.



Storage Battery



It appears when the bridge is destroyed.



shield



This armor protects the engine. It cannot withstand anything other than powerful attacks such as cross missiles and pumpkin bombs. It occasionally opens to release heat.





ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Dorwait

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー



The second of the fastest tug battleships, it is blue in color. It has different armaments from the Gauche, with the tug plane on the left side of the hull.



MACHINE DATA

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Total length: 110.0m

Maximum speed 1000km/h

Durability 500

Prize money: 5,000

Count 1 second

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー



shield



Gauche&#39;s shield has a built-in cannon, but Drowat&#39;s has been replaced with a launcher that fires four Tri-Bone projectiles.



There are hangars under the hulls of the Gauche and Droite, from which the carrier-based aircraft Premiere takes off.



51



After the shield is destroyed



When the shield is open or destroyed, the engine room, which is the weak point of the Gauche and Drowat, is exposed. If you destroy the engine room, you can sink this battleship. There are seven vacuum tubes around the engine room, and both the engine room and the vacuum tubes are usually retracted, but they periodically expand to release heat. These cannot be damaged unless they are expanded.



Launcher



Shoots feather bombs.



Built-in gun turret



When the shield is destroyed, the lid opens and the attack begins. There are four at the rear of the ship, and two on each wing (Gauche does not use the built-in turret on the starboard wing).



CHAIN POINT



The four small cannons on the shield and the three on each second floor will chain together. The seven storage cannons that appear on the sides will also chain together, but you can only destroy the wings and take them down if you chain seven from the yellow point in the diagram.



The vacuum tubes surrounding the engine section will only chain when they are glowing red. Chaining the vacuum tubes will damage the engine section, but if you chain from the yellow points in the diagram, you can chain up to 7 and sink the ship in one hit.



WEAPONS &amp; PARTS

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Name Number of equipment Durability Prize Count Attack method Power

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

1 Shield (Gauche) 1 unit x 1 3000 30000 8 seconds Iron ball bullet 90

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

1 Shield (Derowatt) 1 x 1 3000 30000 8 seconds Triborn 90

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

2 engine units 1 x 1 3600 500 1 sec. - -

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

3 bridges 1 x 1 800 5000 8 seconds - -

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

4 storage guns 7 units x 2 60 200 4 seconds Machine gun bullets 30

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

5 Main Gun 1 x 1 800 20,000 10 seconds High-velocity bullet 150

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

6 Gatling gun 1 unit x 1 400 200 4 seconds Machine gun bullets 30

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

7 Medium-sized artillery 1 unit x 3 240 1500 4 seconds Pistol bullets 90

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

8. Double-layered gun battery 3 units x 3 120 500 4 seconds Machine gun bullets 30

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

9. Small gun battery 4 units x 1 120 500 4 seconds Machine gun bullets 30

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Vacuum tube 1 x 7 80 100 1 second - -

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Built-in cannon (Gauche) 1 x 6 140 1000 4 seconds Cannonball 40

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Built-in cannon (Derowat) 1 x 8 140 1000 4 seconds Cannonballs 40

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Launcher 1 x 2 - - - Feather bomb 180

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー





ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Towing machine

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー



Three towing machines are installed on each towing battleship. They capture targets with their giant claws and have built-in defensive guns.



MACHINE DATA

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Total length: 12.0m

Maximum speed -

Durability 400

Prize money: 20,000

Count 4 seconds

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

WEAPONS &amp; PARTS

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Name Number of equipment Durability Prize Count Attack method Power

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Defensive Battery 1 x 1 - - - Iron Ball Bullet 90

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー



52



Balloon-type air battleship Balene



BALENA



A battleship made of armored balloons with high combat power. Although it is relatively small, it is widely used by the Vantors as their main weapon due to its versatility and high maneuverability.



3 Baraine Cannon



The main body is not the huge barrel, but the small turret behind it. Even if it is destroyed, the Poole and the others work hard to repair it.



ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Balaine long-range artillery type

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー



A Baleine with enhanced armament for attacking large fleets from long distances. Equipped with a long-range Baleine cannon, it is effective in anti-ship combat.



MACHINE DATA

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Total length: 78.5m

Maximum speed 300km/h

Durability 4000

Prize money: 15,000

Count 20 seconds

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

WEAPONS &amp; PARTS

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Name Number of equipment Durability Prize Count Attack method Power

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

1 engine unit 1 x 1 1000 1000 8 seconds - -

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

2 Large Cannons 1 x 2 400 4000 4 seconds Iron Ball 90

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

3 Balene Cannon 1 unit x 1 240 1500 2 seconds Balene Cannon 250

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

4 storage battery 1 unit x 3 200 1000 4 seconds shells 40

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

5 Small artillery battery 1 x 6 120 500 4 seconds Machine gun 30

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー



Balaine Cannon



Power 250, range 8100m. A special weapon only available on long-range gun types, it is capable of sinking a cargo ship sailing far away with just one shot.





ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Balene A

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー



A mass-produced Baleine with a blue hull. It has low durability as a battleship, but its lighter weight gives it slightly higher maneuverability.



MACHINE DATA

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Total length: 60.0m

Maximum speed 320km/h

Durability 1500

Prize money: 7,000

Count 10 seconds

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

WEAPONS &amp; PARTS

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Name Number of equipment Durability Prize Count Attack method Power

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

1 engine unit 1 x 1 1000 1000 8 seconds - -

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

2 Large Cannons 1 x 2 400 4000 4 seconds Iron Ball 90

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

3 Medium Cannon 1 unit x 1 240 1500 2 seconds Pistol bullets 90

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

4 storage battery 1 unit x 3 200 1000 4 seconds shells 40

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

5 Small artillery battery 1 x 6 120 500 4 seconds Machine gun 30

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー







ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Balene B

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー



A standard Baleene with a yellow hull. It has high firepower and durability, and is an excellent war fat that can be used for a wide range of missions due to its versatility.



MACHINE DATA

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Total length: 60.0m

Maximum speed 150km/h

Durability 2500

Prize money: 1000

Count 20 seconds

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

WEAPONS &amp; PARTS

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Name Number of equipment Durability Prize Count Attack method Power

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

1 engine unit 1 x 1 1000 1000 8 seconds - -

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

2 Large Cannons 1 x 2 400 4000 4 seconds Iron Ball 90

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

3 Medium Cannon 1 unit x 1 240 1500 2 seconds Pistol bullets 90

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

4 storage battery 1 unit x 3 200 1000 4 seconds shells 40

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

5 Small artillery battery 1 x 6 120 500 4 seconds Machine gun 30

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー







ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Paraine Ventre specifications

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー



A custom ship modified for private use by Vantle, who noticed the high livability of the Balaine. Its durability has been greatly increased compared to the standard model.



MACHINE DATA

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Total length: 60.0m

Maximum speed 40km/h

Durability 4000

Prize money: 15,000

Count 20 seconds

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

WEAPONS &amp; PARTS

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Name Number of equipment Durability Prize Count Attack method Power

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

1 engine unit 1 x 1 1000 1000 8 seconds - -

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

2 large artillery batteries 1x1 400 4000 4 seconds - -

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

3 medium sized artillery batteries 1 x 1 240 1500 2 seconds - -

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

4 storage battery 1 unit x 3 200 1000 4 seconds - -

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

5 small battery 1 x 6 120 500 4 seconds - -

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー



54



Floating Battleship Grandir



GRANDIR



It is a large battleship with a unique design in which the hull is suspended by two engine blocks on the left and right. Numerous guns are installed throughout the ship, giving it overwhelming offensive power.



12 Upper machine gun mount



Although the target icon shows 6 units, they will be destroyed in groups of 3 x 2.



1 Lightning Generator



The lightning generator cannot be attacked by machine guns or optional weapons, so the only way to destroy it is to chain the surrounding built-in machine guns and light turrets on the top and cause damage with an explosion.



5 Top light turret



Even if it is destroyed, it will be repaired and revived after a while.



4. Top-mounted machine gun



Even if it is destroyed, it will be repaired and revived after a while.



A hangar for aircraft is stored at the bow of the ship, and when Poulet reports, &quot;Lord Vantre, we are coming!&quot;, the hangar unfolds and six Premier aircraft take off.



A new weapon stored in the center of the Grandir&#39;s hull. It can generate clouds to reduce visibility, and can also shoot lightning at the feather bombs to enhance their performance.





55



3 Defensive Battery



When the engine room is destroyed, all surrounding defensive turrets will be destroyed, but in that case you will not receive any bounty or bonus points for the defensive turrets.



6 Light underside gun turret



Even if it is destroyed, it will be repaired and revived after a while.



7. Underside machine gun



Like the top mounted machine gun, this bottom mounted machine gun will also be revived.





CHAIN POINT



Underside of the hull. The shaft can only be destroyed by chaining the underside machine gun from the yellow point in the diagram 5 times. Similarly, the emblem of the underside machine gun cannon can only be destroyed by chaining 6 times from the yellow point.





MACHINE DATA

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Total length: 330.0m

Maximum speed 40km/h

Durability 1000x4

Prize money 10,000 x 4

Count 10 seconds x 4

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

WEAPONS &amp; PARTS

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Name Number of equipment Durability Prize Count Attack method Power

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

1 Lightning Generator 1 unit x 1 25,000 200,000 20 seconds - -

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

2 engine units 1 x 4 1000 10000 10 seconds - -

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

3 defense batteries 3 x 4 120 2000 4 seconds Artillery shells 40

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

4 Top mounted machine gun 4 units x 2 60 200 1 sec Machine gun bullets 30

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

5 Light Battery 1 x 2 300 500 1 sec. Machine gun bullets 30

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

6. Lower Light Battery 1 x 1 300 500 1 sec. Machine Gun Bullet 30

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

7 Underside built-in machine gun 5 units x 2 60 200 1 second Machine gun bullets 30

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

8. Upper Medium Cannon 1 x 1 120 5000 4 seconds Pistol bullets 90

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

9 Side Medium Cannon 3 units x 2 120 2000 4 seconds Pistol bullets 90

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

10 main guns 1x6 400 10000 4 seconds Iron ball bullet 90

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

11 Small battery 1 x 4 120 2000 4 seconds Artillery shell 40

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

12 Top machine gun battery 3 x 4 90 500 4 seconds Machine gun bullets 30

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

13 Lower machine gun battery 6 x 1 90 500 4 seconds Machine gun bullets 30

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Emblem 1 unit x 1 - 50000 4 seconds - -

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Launcher 1 unit x 2 - - - Feather bomb -

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー



56



Heavy Armored Floating Battleship Dorado



DORAD



A base defense battleship equipped with numerous weapons like a hedgehog. The red armor cannot be damaged, but attacks to the wooden part underneath are effective.



4 Crested Gun Battery



Even if it is destroyed, it will be repaired and revived after a while.



1 Fin Battery



In this particular part, the weak point is not the wooden part, but the base.



3 Pistol Battery



It fires shark missiles. In addition, it also fires assault podros. However, these attacks are only performed by the Dorado that appears in Scene 4.





There is also a version that has the pistol and float gun turrets removed and transport parts added, allowing large ground weapons such as the Scret and Vancool to be hoisted and transported.



MACHINE DATA

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Total length: 65.0m

Maximum speed 150km/h

Durability 3000

Prize money: 30,000

Count 20 seconds

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

WEAPONS &amp; PARTS

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Name Number of equipment Durability Prize Count Attack method Power

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

1 Fin Battery 1 x 2 500 10,000 4 seconds Machine gun bullets 30

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

2 float gun 1 unit x 1 500 15000 4 seconds Machine gun bullets 30

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Shark Missile 70

3 Pistol Battery 1 x 1 800 10,000 4 seconds ----

Assault Pod

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

4 Crest Cannon 1 x 4 210 5000 4 seconds High-speed bullets * 150

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Machine gun 4 units x 2 - - - Machine gun bullets 30

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー



*Fires pistol bullets in Scene 5 and Survival.





57



Fortress Battery &amp; Tank



CANNON &amp; CHAR (TANK)



These are artillery and tanks developed by Vantle for base defense. Although their individual combat power is not particularly high, they are deployed in large numbers due to their low cost.



ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Small gun battery

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー



The most numerous cannon. Fires cannonballs. Attack power and durability are not very high.



MACHINE DATA

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Total length: 21.0m

Durability 36

Prize money: 300

Count 2 seconds

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

WEAPONS &amp; PARTS

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Name Number of equipment Durability Prize Count Attack method Power

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Anti-aircraft gun 1 x 1 - - 8 seconds Shells 40

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー





ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Medium gun battery

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー



A gun with armored sides for improved defensive capabilities. It fires iron ball bullets.



MACHINE DATA

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Total length: 35.0m

Durability 200

Prize money: 1000

Count 2 seconds

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

WEAPONS &amp; PARTS

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Name Number of equipment Durability Prize Count Attack method Power

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Anti-aircraft gun 1 unit x 1 - - - Iron ball bullets 90

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー







ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Armored gun turret

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー



A shielded artillery turret that fires pistol shots and cannot be attacked from the front.



MACHINE DATA

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Total length: 34.0m

Durability 200

Prize money: 1000

Count 2 seconds

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

WEAPONS &amp; PARTS

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Name Number of equipment Durability Prize Count Attack method Power

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Anti-aircraft gun 1 unit x 1 - - - Pistol bullets 90

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー







ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Large gun emplacement

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー



A cannon covered entirely in steel armor. Standard attacks cannot cause any damage to it.



MACHINE DATA

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Total length: 58.0m

Durability 2000

Prize money: 3,000

Count 10 seconds

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

WEAPONS &amp; PARTS

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Name Number of equipment Durability Prize Count Attack method Power

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Anti-aircraft gun 1 unit x 1 - - - High-velocity bullets 150

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー





ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Tank Type A

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー



Tanks stationed at Van Thorhaus. They operate in groups of 3-5 and fire pistol ammunition.



MACHINE DATA

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Total length: 12.0m

Maximum speed 140km/h

Durability 60

Prize money: 400

Count 1 second

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

WEAPONS &amp; PARTS

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Name Number of equipment Durability Prize Count Attack method Power

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Main gun 1 unit x 1 - - - Pistol bullets 90

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー





ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Tank Type B

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー



A tank mounted on the Grand Magasin. It runs around on the deck and performs anti-aircraft attacks.



MACHINE DATA

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Total length: 12.0m

Maximum speed 156km/h

Durability 90

Prize money: 400

Count 1 second

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

WEAPONS &amp; PARTS

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Name Number of equipment Durability Prize Count Attack method Power

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Main gun 1 unit x 1 - - - Pistol bullets 90

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー





58



Walking Tank Skret



SQUELETTE



A large ground weapon developed by Associe. It can traverse any rough terrain on its four legs. It is equipped with numerous artillery turrets on both the front and back, and its attacking capabilities do not decrease even if it is flipped over.



The spikes on the ends of the legs are supported by pistons, allowing the body to move freely even on vertical walls.



3 pistons



Even if it is destroyed, it will be repaired and restored after a while.



Quadruple machine gun



Even if it is destroyed, it will be repaired and revived after a while.





59





Large machine gun bullets



Power 50. Range 220m. A large caliber machine gun bullet fired from twin machine guns. It has higher power.



Anchor Attack



Power 500. Shoots out an anchor with a chain roughly aimed at the target. It flies for a while and then is pulled back.





MACHINE DATA

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Total length 100.0m

Maximum speed 86km/h

Durability (control tower) 10000

(Engine) 600 x 3

Prize money (Commander): 30,000

(Engine) 10,000 x 3

Count (Commander) 10 seconds

(Engine) 8 seconds x 3

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

WEAPONS &amp; PARTS

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Name Number of equipment Durability Prize Count Attack method Power

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

1 control tower 1 unit x 1 10,000 30,000 10 seconds - -

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

2 engine units 1 x 3 600 10,000 8 seconds - -

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

3 pistons 1 unit x 4 210 0 4 seconds - -

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Quadruple machine gun 5 units x 4 60 200 4 seconds Large machine gun bullets 50

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

5 Main gun 1 unit x 2 800 8000 4 seconds Shell 40

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

6 Small cannon 1 unit x 1 210 1000 4 seconds Cannonball 40

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

7 Secondary gun 1 x 2 400 3000 4 seconds Shell 40

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Machine gun bullets 30

Anchor 1 unit x 4 - ...

Anchor Attack 500

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Shark Missile 70

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Launcher 1 unit x 4 - - - Triborn 180

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Assault Pod

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー





ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Poulet tank

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー



When the Scret is on its front side, it launches in a formation of four planes from holes on its back side.



MACHINE DATA

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Total length: 2.6m

Maximum speed 156km/h

Durability 30

Prize money: 100

Count 1 second

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー



60



Sky Fortress Battleship Grand Magasin



GRANDMAGASIN



This is a super-large battleship built by Vantor, with a total length of about 2 km. It is made up of a total of 9 blocks, each of which is connected by a coupler.



MACHINE DATA

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Total length 1970.0m

Maximum speed 108km/h

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー



Grand Magasin target icon change



The Grand Magasin target icon will be displayed for each block (only for Target All). However, the engine section will only be displayed during Copan and Ciel&#39;s engine section destruction missions.



61



Tip



Located at the bow of the ship, this block is equipped with numerous weapons such as main guns and machine gun turrets, giving it great offensive power. It also houses the forward launch pad for aircraft.



CHAIN POINT



Top of the tip. The couplers are chained together from the six machine gun turrets. If the couplers are destroyed, the tip will be detached from the main body.



Side of the tip. Eight machine gun turrets can be chained together on both the top and bottom. If you chain eight of the upper machine gun turrets, the attacking part itself will fall, destroying the lower machine gun turret and main gun, adding a total of nine hits.



■ Attacking team name



ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

WEAPONS &amp; PARTS

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Name Number of equipment Durability Prize Count Attack method Power

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

1 Machine Gun Battery (Top) 8 x 2 80 100 1 sec. Machine Gun Bullet 30

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

2 couplers 1 unit x 1 8000 500 4 seconds - -

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Connected machine gun battery 6 units x 2 60 100 1 second Machine gun bullets 30

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Main gun 1 x 2 - - - Pistol bullets 90

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Machine gun battery (lower) 8 x 2 80 100 1 sec. Machine gun bullets 30

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Lookout Battery 1 x 2 300 10,000 4 seconds Machine gun bullets 30

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー



62



First tip attack department/Second tip attack department



Between the nose and the command center is a block of two identical structures placed side by side, with a launch pad for the tanks on each deck.



CHAIN POINT



Chain the gun turret to a connector. If you destroy the connector, the block beyond it will be detached and fall.



The upper and lower machine gun turrets on the sides will chain together. If you chain 8 machine gun turrets (upper), they will destroy the lower machine gun turret and main gun, and you will get an additional 9 hits.



■ Attacking team name



ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

WEAPONS &amp; PARTS

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Name Number of equipment Durability Prize Count Attack method Power

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

1 Machine Gun Battery (Top) 8 x 2 80 100 1 sec. Machine Gun Bullet 30

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

2 couplers 1 unit x 1 8000 500 4 seconds - -

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Connected machine gun battery 6 units x 2 60 100 1 second Machine gun bullets 30

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Main gun 1 x 2 - - - Pistol bullets 90

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Machine gun battery (lower) 8 x 2 80 100 1 sec. Machine gun bullets 30

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Searchlight Battery 1 x 2 300 3000 2 seconds Machine Gun Bullet 30

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー





63



Port propulsion section/starboard propulsion section



These blocks are located on both the starboard and port sides of the Grand Magasin. They have huge rudders at the front and rear and are important for the ship&#39;s navigation. There is a tank launch pad on the deck.



CHAIN POINT



Rear of the propulsion unit. If you destroy the medium gun turret, you can chain up to 10 to the rear machine gun turret. However, if you chain from the rear machine gun turret, the medium gun turret cannot be destroyed.



Side of the propulsion section. If you destroy the large upper cannon, the cannon will fall and destroy the lower one as well. You will get a prize and a bonus count, but you will not get a chain or a hit.



The front of the propulsion unit. When the pumpkin bomb is destroyed, it rolls and destroys the firework missiles one after another, creating a maximum of 10 chains. It does not chain unless the pumpkin bomb is destroyed.



ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

WEAPONS &amp; PARTS

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Name Number of equipment Durability Prize Count Attack method Power

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

1 Large Cannon 1 unit x 8 300 10,000 4 seconds Iron ball bullet 90

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

2 Medium Cannon 1 unit x 1 240 1500 4 seconds Pistol bullets 90

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Machine gun 1 unit x 1 40 100 1 second Machine gun bullet 30

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Rear machine gun 1 unit x 1 80 100 1 second machine gun bullets 30

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Pumpkin Bomb 1 x 1 1000 1000 1 second - -

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Fireworks Missile 1 x 10 60 300 1 sec - -

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Connected machine gun battery 6 units x 2 60 100 1 second Machine gun bullets 30

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー



*The fingers are hearts.







64



Garden Department



The Grand Magasin&#39;s rearmost block towing the stolen museum. The museum is attached to the garden area by connecting parts. There is also a rear launch pad for the carrier-based aircraft.



The museum capture turret moves around the rails set up in the garden and has the ability to repair any damaged connecting parts.



A powerful mobile artillery platform that will pour down heavy anti-aircraft fire on any enemies approaching the museum.



MACHINE DATA

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Durability 4000

Prize money: 80,000

Count 20 seconds

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

WEAPONS &amp; PARTS

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Name Number of equipment Durability Prize Count Attack method Power

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Gatlin gun 4 units x 4 - - - Machine gun bullets 30

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Pistol gun 1 unit x 1 - - - Pistol bullets 90

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー





65



Lookout Battery



This cannon is attached to the lookout tower at the very rear of the main unit, and there is also a similar one at the tip. It moves up and down and attacks surrounding enemies.



CHAIN POINT



Chain from the gun turret to the coupler. If the coupler is destroyed, the garden will separate from the main body and fall. Also, the balloons on the sides can be chained up to 5 times, but in order to chain the balloons, you need to inflate all of them by giving them some damage beforehand.



WEAPONS &amp; PARTS

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Name Number of equipment Durability Prize Count Attack method Power

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

2 Museum connection parts 1 unit x 8 800 5000 1 second - -

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Balloon 5 x 2 200 1000 8 seconds - -

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Lookout Battery 1 x 2 300 10,000 4 seconds Machine gun bullets 30

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Connected machine gun battery 6 units x 2 60 100 1 second Machine gun bullets 30

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー



*The coupler is a part of the control unit.



66



Command/Lower Cabin



The central block of the giant battleship. Vantle is in the control tower, directing the Poulets. The lower cabin is connected to the bottom of the control room by two connectors.



WEAPONS &amp; PARTS

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Name Number of equipment Durability Prize Count Attack method Power

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Balloon 5 x 4 200 1000 8 seconds - -

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Hard balloon 1 unit x 4 120 30,000 10 seconds - -

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー





When the Grand Magasin is dismantled and only the command center and engine room remain, numerous artillery turrets will appear in the command tower at Vantle&#39;s command. These turrets are covered in thick armor and can only withstand powerful attacks such as cross missiles and heavy bullets. Even if the turrets are destroyed, they will be repaired and revived after a while.





WEAPONS &amp; PARTS

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Name Number of equipment Durability Prize Count Attack method Power

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

1 command tower 1 unit x 1 32765 - - - -

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

2 Command Tower Cannon 1 x 3 400 300 1 sec Pistol Ammo 90

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

3 Command Tower Gun A 7 units x 3 120 100 1 sec. Gun bullets 30

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Command Tower Gun B 1 x 3 245 100 1 sec. Gun Bullet 30

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Searchlight Battery 1x1 300 3000 2 seconds Machine Gun Bullets 30

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー





67





Engine



A huge engine. It can be destroyed by removing all eight cranks. There is a launch port for the tank on the bottom.



WEAPONS &amp; PARTS

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Name Number of equipment Durability Prize Count Attack method Power

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

4 fuel tanks 1 unit x 4 200 0 1 sec - -

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

5 coupler 1 unit x 1 8000 500 4 seconds - -

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Connected machine gun battery 6 units x 2 60 100 1 second Machine gun bullets 30

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー



WEAPONS &amp; PARTS

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Name Number of equipment Durability Prize Count Attack method Power

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

1 crank 1 unit x 8 400 20000 8 seconds - -

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー







CHAIN POINT



There are 4 balloons on the sides of the deck of the command center that can chain up to 5. However, you will not be able to chain them unless you inflate each balloon by dealing a certain amount of damage.



To deal damage to the command tower, you need to chain the guns below it. If you destroy the bottom command tower machine gun A with a strong attack, you can chain up to 9.



There are couplers on each side of the command center that connect the lower cabins. If both the port and starboard couplers are destroyed, the lower cabins will be detached from the command center and fall.





68



Mechanical Soldier Vaincour Fidele



Associate



VANQUEUR &quot;fidele&quot;



Associe Corporation was planning to exhibit a bipedal iron machine at the fair as a new-age weapon, but it was stolen by Vantle, ironically threatening the fair&#39;s opening.



Grab Attack



When you get close, you will twist your upper body and grab your ship with your arms, rotate your arms to gain momentum, and then throw it away. While rotating, you can escape by repeatedly pressing the directional pad.



1 Rear engine section



When destroyed, it will emit black smoke and struggle for a while, after which its movement speed will decrease, making it easier to attack.



MACHINE DATA

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Total length: 25.0m

Maximum speed 80km/h

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

WEAPONS &amp; PARTS

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Name Number of equipment Durability Prize Count Attack method Power

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

1 Rear engine section 1 unit x 1 200 10000 20 seconds - -

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Arm 1 x 2 - - - Grab attack Fall

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー





69





Mechanical Soldier Vaincour Surge



VANQUEUR &quot;sage&quot;



Associate



The Vaincour was improved by Vantle. To protect its weak spot at the shoulder, it is fitted with a shield that rotates so that it always faces the enemy.



Grab Attack



Catch your ship, spin it around, then throw it away and crash it. While spinning, you can escape by tapping the directional key repeatedly.



Hand Grenade



Power: 220. It will be thrown at your ship. Even if it doesn&#39;t hit you directly, it will explode if you get too close and scatter 5 shells.



MACHINE DATA

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Total length: 25.0m

Maximum speed 45km/h

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

WEAPONS &amp; PARTS

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Name Number of equipment Durability Prize Count Attack method Power

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

1 shield 1x2 8000 40000 4 seconds Cannonball 40

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

2 shoulder joints 1 x 2 3000 100000 8 seconds - -

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Grab Attack Fall

Arm 1 x 2 - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

Grenade 220

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー





Airborne Bomb



A defensive weapon fired by mechanical soldiers overhead. It is not dangerous, but it can prevent attacks from above.



MACHINE DATA

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Total length: 3.0m

Durability 900

Prize money: 10,000

Count 2 seconds

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー



70



Mechanical Soldier Vaincour Garde



VANQUEUR &quot;garde&quot;



With all of his weak points reinforced, Vainqueur is now invincible. He is equipped with a special Gatling lance and a perpetual motion machine, allowing him to use a number of special attacks.



1 Perpetual Motion Machine



From the second stage of rampage onwards, the cover opens and becomes exposed, becoming the only weak point capable of stopping the mechanical soldier&#39;s movement.





ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Vaincourt Garde

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー



Since he is suspended by the Dorados, he only uses his Gatling lance.



MACHINE DATA

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Total length: 25.0m

Maximum speed 150km/h

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

WEAPONS &amp; PARTS

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Name Number of equipment Durability Prize Count Attack method Power

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Gatling gun 90

Gatling lance 1 unit x 1 - ...

Charge Shot 500

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー



Gatling Gun



Power 90. Range 780m. Powerful machine gun bullets fired endlessly from the Gatling lance.



Charge Shop



Power 500. Range approximately 450m. Energy is concentrated and fired from the Gatling lance at high speed.



71



ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Vaincourt Garde (First Stage of Rampage)

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー



Although he has been powered up by incorporating a perpetual motion machine, Vainqueur has become uncontrollable and cannot be damaged by normal attacks.



ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

WEAPONS &amp; PARTS

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Name Number of equipment Durability Prize Count Attack method Power

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Gatling Lance 1 x 1 - - - Gatling Gun 90

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Floating cannon

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Launcher 1 unit x 1 - - - Spear missile 150

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Triborn 180

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー



ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Vaincourt Garde (Second Stage of Rampage

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー



The perpetual motion machine that powers it is now exposed. The number of floating cannons and spear missiles fired from the launcher on its back has increased from the first stage.



ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

WEAPONS &amp; PARTS

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Name Number of equipment Durability Prize Count Attack method Power

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

1 perpetual motion machine 1 unit x 1 9000 0 - - -

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Gatling Lance 1 x 1 - - - Gatling Gun 90

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Floating cannon

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Launcher 1 unit x 1 - - - Spear missile 150

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Triborn 180

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー





ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Vaincour Garde (third stage of rampage)

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー



Having lost his Gatling Lance, he now has three types of attacks fired from his launcher, as well as propeller throws, pipe defenses, and tank throws.



ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

WEAPONS &amp; PARTS

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Name Number of equipment Durability Prize Count Attack method Power

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

1 perpetual motion machine 1 unit x 1 18,000 0 - - -

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Floating cannon

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Launcher 1 unit x 1 - - - Spear missile 150

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Triborn 180

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Propeller Throw 500

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Arm 1 x 2 - - - Pipe defense -

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Tank Throw 250

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー





ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Vaincourt Garde (Final stage of rampage)

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー



The attacks it uses are floating cannons, spear missiles, and tri-bones, each of which can be fired in significantly greater numbers and with improved performance, and it can also fire machine gun bullets from its perpetual motion machine.



ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

WEAPONS &amp; PARTS

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Name Number of equipment Durability Prize Count Attack method Power

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

1 perpetual motion machine 1 unit x 1 32000 0 - machine gun bullets 30

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Floating cannon

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Launcher 1 unit x 1 - - - Spear missile 150

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

Triborn 180

ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー





Floating Cannon



It fires multiple cylindrical cannons from the launcher on its back, and the cannons that remain in the air fire shells.



Spear Missile



Power 150. A homing missile that is fired multiple times from the launcher on its back. It has a durability of 10 and can be destroyed.



Propeller Throw



Power 500. Throws the propeller like a boomerang. If not destroyed, it will be shot down for sure. Durability is 250.



Pipe Defense



Rotate the pipe to deflect machine gun attacks. The pipe has a durability of 1500 and can be destroyed with EX actions.



Tank Throw



Power 250. Grabs a nearby tank and throws it. Aims inaccurately, and can destroy it. Durability 60.









72



INTERMISSION Tea Time Column -Vol.3-

Tea Time Column No. 3

Vehicles standing beside me





In addition to the Gunner Machine and the Vantor weapons, several vehicles will appear in the game. We will introduce the settings for these vehicles here.



Cargo ship



The cargo ship that appears in Scene 2 has detailed containers. There are three types of cargo ships: large, medium and small, and they are named Oblon, Allongé and Etrois, from largest to smallest.



Police vehicles



These are the vehicles of the Reeve Police Department that appear in Scene 3 of Fam. There are tanks that shoot down enemies, lights that illuminate the scene, and more. Although it is not a vehicle, we will also introduce the acid mines that are used to hold back Vainqueur Sage.



Ardi&#39;s Armored Car



This is the armored car driven by Ardi who entered the Vantor House in Scene 4. We can&#39;t get a good look at it due to the attacks of Poulet and the others, but this is what it looks like.



73



Story Walkthrough



74



How to view the story



The stories of Fam, Ciel, and Copan are summarized by scene, and it explains how to clear each mission, how to defeat strong enemies, how to earn bounties, etc. Copan is the only character that cannot be selected at first, but you will be able to play him once you have cleared all of Fam&#39;s stories. Please refer to page 132 for information on Rival&#39;s story.



Fam

Difficulty

easy

The beginner-friendly guide to Fam focuses on clearing the missions as the top priority, and mainly focuses on basic techniques.



Ciel

Difficulty

ordinary

For players who have completed FAM and stepped up, we will introduce strategies that will enable you to take first place in total prize money when you clear all the levels.



Copan

Difficulty

Difficult

Copan is a character for advanced players. We will show you how to clear difficult missions and how to earn even more bounty money.



●Story &amp; Mission Chart

● Mission Strategy





75



Story &amp; Mission Chart



This is the story part of each scene, a chart of the missions to be performed, and details of the enemies that appear. Basically, information on all three characters, Fam, Ciel, and Copain, is posted together, but scene 3 is a completely different scene for each character, so it is divided into each one.



1 Story



This is a digest of the story part told at the beginning of the scene.



2 Mission Chart



This is a list of the mission flow for each character in that scene. Each mission is given a serial number unique to this book, which corresponds to the mission strategy. Missions lined up in a row also indicate that they take place on the same timeline. The letters to the left of the missions correspond to the enemies that appear, and you can find out which enemies are in the area during that time.



Chart Divergence Symbols



A mission to proceed to if the previous mission is successful.



A mission to proceed to if the previous mission fails.



3. Appearing enemies



These are the types of enemies present in the area during the mission. For enemies that are in formation with several aircraft, the composition is also shown in parentheses. The alphabet corresponds to the mission chart, and if there are differences in the number or type of appearances depending on the character, they are listed separately.



(Support) - They will not be in the area from the beginning, but will appear after a certain amount of time has passed since the mission began or when special conditions are met.

(They can appear any number of times) Even if you shoot them down, they will continue to replenish up to a certain number until the mission is cleared.



Mission Strategy



This is a guide to each mission for each character. It explains the necessary techniques and useful information to know. It is listed in the order of Fam, Ciel, and Copain, but only Mission 5-5 is a joint mission for the three of them.

Since this is a game that will be played on multiple devices, we are providing strategies for each one together.



4. Main Enemies



This is the main enemy that the character will fight during the mission. In many cases, defeating this enemy is the condition for completing the mission.



5. Special Rules



These are special rules set for each mission, such as the conditions for success or failure of the mission, or the conditions for obtaining an event bonus when clearing a scene.



Aim for 6 Coco



This is an illustration of the enemy aircraft&#39;s attack points as indicated in the text. The names of weapons and parts correspond to those in the Vantor Super Weapon Encyclopedia starting on page 41, so it would be a good idea to refer to that as well. The strategies in this book are performed with the target mode set to All.







76



SCENE I

Enter the genius criminal Vantor

2:00pm



It was early afternoon at the Gunner Cafe, which is located off the coast of the city of Reeve. Shell was preparing for Avenir&#39;s training at the security post as usual. Her partner Copan came in with a package she had received from the postman. After placing the package on a chair, Copan started listening to the radio broadcast of the Perpetual Motion Machine&#39;s unveiling party, which she had missed.



&quot;Oh, I wanted to go to the party too.&quot;



The party to celebrate the perpetual motion machine has begun on the luxury passenger liner Merville, floating in the sky above Reeve. Ardi, who is preparing the machine, and Fam, wearing a party dress, can be seen at the party venue. As the two are watching the perpetual motion machine, suddenly there is a violent explosion outside the ship. The genius criminal Vantle has come to steal Merville and the perpetual motion machine. Poulet and the others continue to enter the Merville ship. Fam and Ardi rush to the Merville&#39;s hangar, where the gunner machine is kept.



&quot;You don&#39;t like parties, Inspector?&quot;



Meanwhile, the phone rings from a package that has arrived at Gunner Cafe. Suspicious, Ciel picks up the receiver and finds out it&#39;s a challenge from Vantle to the Gunner of Reeve. The two of them get into their beloved machines, ignition guns in hand. Leaving a painful engine roar and a splash of water behind, the two machines take off into the sky above Reeve, where Merville floats.



&quot;My name is Vantor!&quot;



&quot;Who are you guys?!&quot; &quot;I&#39;ll be quiet.&quot;



77



Mission Chart



-------------------------------------------------- ------

Fam



Number of occurrences



A



B

1-1

Shoot down the hangar door, escape the hangar, and reunite with your allies.

1-2

Defeat the Combat Craft



C

1-3

Defeat the defense forces guarding the towing machine. Destroy the towing machine and recover Merville.



D

1-4

Destroy the aerial battleship&#39;s guns and support Ciel.



-------------------------------------------------- ------

Ciel



Number of occurrences



A

1-1

Defeat the Combat Craft



B

1-2

Defeat Starbull in front of the hangar

Defeat the Combat Craft



C

1-3

Destroy the towing machine and recover Merville



D

1-4

Destroy the engine room and defeat the airship.



-------------------------------------------------- ------

Copan



Number of occurrences



A

1-1

Defeat the Combat Craft



B

1-2

Defeat the mechanical soldiers on the deck

Defeat the Combat Craft



C

1-3

Destroy the towing machine and recover Merville



D

1-4

Destroy the engine room and defeat the airship.

-------------------------------------------------- ------



Appearing enemy forces



A



Ciel Copain



Premier 5-plane formation (Premier C x 1 + Premier A x 4) x 6



B



Fam



Stable AX 1

Premier 5-plane formation (Premier CX1 + Premier Ax4) x 4 (appears multiple times)

Premier 6 aircraft formation (Premier BX6) x 1



Ciel Copain





Vaincourt Fidele x1

Premier 5 aircraft formation (Premier CX1 + Premier Ax4) x 1

Premier 6 aircraft formation (Premier DX6) x 1

Premier 5-plane formation (Premier CX1 + Premier Ax4) x 1~4 (appears multiple times) *1



C



Femme Ciel Copain



Gauche x 1 (indestructible)

Drowatt x 1 (indestructible)

Towing machine x 6

Premier 5-plane formation (Premier Ax5) x1

Premier 5-plane formation (Premier CX5) x 1

Premier 6 aircraft formation (Premier DX6) x 1



D



Femme Ciel Copain



Gauche 1

Droit x 1

5-plane formation of Bloomier (Premier C x 1 + Premier BX3 + Premier Ax1) x 1 (appears multiple times)

Premier 6-plane formation (Premier C x 1 + Premiero x 5) x 1 (appears multiple times)

Premier 7-plane formation x 1 (Premier D x 1 + Premier AX6) (appears multiple times)





※1 If you shoot down the 5-plane Premier squadron and the 6-plane Premier squadron that appear at the same time, your unit will increase by ! If you shoot down all of the Dakhishi Darflbillankur and Fidele, another unit will be added (up to 4 units will appear at the same time until Fam joins you).







78



Femme SCENE 1



This is the first scene in the beginners&#39; game, so there are no particularly difficult scenes. It is more of a practice mission, so let&#39;s get used to the unique flying sensation and perspective of Sky Gunner here.



-------------------------------------------------- ----------

1-1 Shoot down the hangar door/Escape the hangar and reunite with your allies



Main Enemies

none



Special Rules

none



As long as you follow Aldi&#39;s instructions you&#39;ll be fine.



The game starts in the hangar of Merville. To take off from here, you must first destroy the door in front of you. Press the R button to fire the machine gun and attack the door. Once the door is destroyed, press the &amp; button to use a boost and take off. Once you are in the air, if you continue flying you will be shot down by the enemy, so immediately press the R1 button to perform the EX action Active Turn. Now, the battle is about to begin!



img caption

After taking off and flying for a while, enemy guided missiles will fly from behind. Quickly perform an active turn to dodge them.

-------------------------------------------------- ----------

1-2 Defeat the Combat Boat



Main Enemies

Premier 5-plane formation (CX1+AX4) x 4 None



Special Rules

none



First, let&#39;s get started by learning the basics of aerial combat.



The first enemy is the combat boat Premier. Its durability and attack power are both very low, so it is unlikely to be shot down, so don&#39;t be afraid to approach and attack with your machine guns. The enemy is in a formation of several aircraft centered around the captain&#39;s aircraft. If you destroy the entire formation, the target will change, so if you capture a new enemy in the target site, you should turn your nose in the direction of the enemy and then use the boost to approach and continue attacking. If you destroy the enemy, you will proceed to the next mission.



&lt;How to use Active Turn&gt;



If you pass an enemy...



Immediately press the R1 button for an active turn!



img caption

It&#39;s also a good idea to try out some optional weapons. I recommend the Fireworks Missile, which can shoot down multiple enemies with one shot.



78



-------------------------------------------------- ----------

1-3 Defeat the defense forces guarding the towing machine / Destroy the towing machine and recover Merville



Main Enemies

5-plane Bluemire formation (AX5) x 1, 5-plane Premier formation (CX5) x 1, 6-plane Premier formation (DX6) x 1



Special Rules

If you destroy all the defense force&#39;s Premiers within the time limit, you will receive a bonus and the mission will change.



Aim for the shiny Premier and get a bonus



Here, you will fight three squadrons of Premiers. If you can destroy all of the Premier squadrons, you will receive a bonus prize. These Premiers are green and reinforced, and are more durable than the ones you fought in 1-2, but you should be able to defeat them without any problems. Once you have destroyed the enemies, you will move on to the mission to destroy the towing machine (see page 81, Ciel 1-3). The towing machine is a tough enemy that fires iron ball bullets, but Ciel and Copan will do their best, so you can leave it to them.



img caption

If you annihilate the three green Premier units of the defense force and Poulet says this line, the mission is successful and you will receive a bonus prize.



-------------------------------------------------- ----------

1-4 Destroy the aerial battleship&#39;s guns and support Ciel



Main Enemies

Gauche x 1, Doroit x 1



Special Rules

none



In the first anti-ship battle, focus on providing support without overdoing it.



The aerial battleships Gauche and Drowat are formidable enemies equipped with many weapons. Fam&#39;s mission is to destroy the battleship&#39;s guns and support Ciel and Copan. While you&#39;re still getting used to it, it&#39;s best not to push yourself too hard and focus on destroying the guns (for those who want to defeat Gauche and Drowat, see Ciel 1-4 on page 81 and Copan 1-4 on page 83). Of the many guns, the two-layer guns and small guns on the shield can be destroyed with machine guns. However, the medium guns on both wings and the deck, the Gatling gun on the bow, and the main gun have high durability, so you&#39;ll want to use the optional weapon cross missiles. The main gun is particularly durable, but if you fire the maximum lock of the cross missiles (align the lock site with the target and hold down the ◎ button for a while to lock on three shots) and detonate them, you can destroy them in one hit.



img caption 1

img caption 2



The cannons on Gosyu&#39;s shield that shoot iron balls are indestructible. Use Cross Missiles to destroy the shield.



Once Ciel has defeated Gauche, it&#39;s time to attack Droitt. Support Copan, who is fighting alone.



-------------------------------------------------- ----------



80



Cielciel SCENE 1



If you play as Ciel, you should pay attention to how you use the EX actions Steam Brake and Heavy Bullet. In this scene 1, Scatter is still weak, so it&#39;s a good idea to use it actively and practice it here.



1-1 Defeat the Combat Boat



Main Enemies

Premier 5-plane formation (CX1, AX4) x 6



Special Rules

none



Prioritize shooting down the captain of the Premier formation.



First, a squadron of Premiers will appear. Work with Copan to destroy this enemy. Here, you should prioritize shooting down the enemy squadron leader and try to secure an optional weapon. If Copan shoots down the leader, you won&#39;t get an optional weapon for it. Don&#39;t pursue enemies in escape mode too far, and once you&#39;ve shot down the squadron leader, press the R2 button to immediately switch target lock to the next enemy and quickly aim for the squadron leader.



img caption

Optional weapons are added as prize money when you clear a scene, so there&#39;s no harm in increasing your stock.



-------------------------------------------------- ----------



1-2 Defeat the Star Bull in front of the hangar / Defeat the combat boat



Main Enemies

Star Bull CX1



Special Rules

If you defeat him before Fam escapes, you&#39;ll get a bonus and the mission will begin again.



Use optional weapons to defeat them quickly.



First, lock onto the Star Bull in front of Merville and fire two or three fireworks from the front. This will destroy the enemy&#39;s side guns. After that, move around to the side of the enemy and lock onto the Cross Missiles to the maximum and attack, which should reduce the enemy&#39;s durability to a minimum. After that, use the power slide to dodge the attacks while approaching and shoot it down with the machine gun. If you can shoot down the Star Bull before Fam escapes, you will get a bonus and return to the battle with the Premier squadron.



img caption 1

If you fire a fireworks missile from a distance, you&#39;ll be shot down by machine gun fire, so get close enough to avoid it.



img caption 2

POINT Aim for this!

When using Cross Missiles, be careful not to lock onto the Gatling guns equipped above and below the Star Bull. If you destroy the side guns and approach the enemy from the side, then try to lock onto the center of the fuselage, it should be easier to hit the main body.



-------------------------------------------------- ----------



81



1-3 Destroy the towing machine and recover Merville



Main Enemies

Towing machine x 6



Special Rules

If they don&#39;t defeat them within the time limit, the mission will fail.



Make sure to destroy the tow plane with a cross missile.



Here, you must work with Copan to destroy the six towing planes installed on the deck of the Merville within three minutes. If you fail to destroy all of the towing planes within the time limit, the game is over. If you approach Merville carelessly, you will be targeted by multiple towing planes, so it is dangerous. Shoot the cross missiles at the towing plane&#39;s guns from a long distance, and then approach as close as possible to the range of the machine guns to detonate them. It is enough to hit it with one cross missile.



&quot;Make sure your target is firmly within the square of the gunsight and shoot!&quot;



-------------------------------------------------- ----------



1-4



Main Enemies

Gauche x 1, Doroit x 1



Special Rules

none



If we can just destroy the solid shield, it&#39;s ours!



To sink Gauche and Drowat, it is necessary to destroy the engine room, which is a weak point. However, the engine room is protected by a shield that does not accept machine gun attacks. The shield opens occasionally to dissipate heat, but it does not stay open for long. The shield can be damaged by using Cross Missiles or Avenir&#39;s EX action Heavy Bullet. If you destroy the shield and expose the engine room, you can easily sink this battleship. There are also seven vacuum tubes around the engine room, and if you destroy these parts when they are glowing red, you can chain them and damage the engine room as well. If you chain 5 to 7 vacuum tubes, the engine room will also be destroyed, so it is possible to sink the battleship in one hit.



img caption 1



If you use a heavy bullet to destroy the shield, it&#39;s dangerous if there is a two-tiered cannon or a small cannon, as you may be shot by them. Destroy them first.



img caption 2

By chaining the glowing red tubes together when the shields are open, it&#39;s possible to sink a battleship without destroying the shields.



POINT Aim for this!



Machine gun attacks are ineffective against the shield that protects the engine room, so you&#39;ll need to use Cross Missiles or Heavy Bullets, which have great power.



The shield has been destroyed and removed. However, be careful because you cannot damage the mechanism or vacuum tubes if they are stored inside the main body.



-------------------------------------------------- ----------



82



Copain SCENE 1



Compared to other units, the Copan Chevalier has poor turning ability and is difficult to handle. Using the EX action of rolling can be difficult if you are not used to it. If you can master it in this stage, it will be easier later on.



-------------------------------------------------- ----------



1-1



Main Enemies

Premier 5-plane formation (CX1, AX4) x 6



Special Rules

none



Let&#39;s try the power of rolling



This mission is cleared if you destroy the Premier squadron. However, if you are aiming for a high bounty from Scene 1, you should play with bounty hunting in mind from this mission. Here, it is a good idea to use rolling to shoot down enemy squadrons at once and aim for a hit. After aligning your nose with the center of the enemy squadron, approach while boosting, and roll as you pass or overtake them, and you should be able to score 5 hits.



img caption

After using rolling, be careful of the temperature of the aircraft. Using it repeatedly in a short period of time can cause it to overheat.



-------------------------------------------------- ----------



1-2 Defeat the mechanical soldiers on the deck / Defeat the combat boat



Main Enemies

Vaincourt Fidelme 1



Special Rules

Special Rules: If you defeat the robots before Fam escapes, you will get a bonus and the mission will change to another one.



Knock indestructible robots off the deck



You can&#39;t destroy the mechanical soldier, Vainqueur, but you can knock it off the deck by charging in at full speed and rolling into it. Using Hound Missiles or destroying the engine on its back will slow down the enemy&#39;s movements, making it easier to knock it off. You&#39;ll get a bonus if you can knock down the mechanical soldier before Fam takes off. If you want to earn bounties, before attacking the mechanical soldier, use Fireworks Missiles on the surrounding Star Bulls with 2 or more locks, destroy the side turrets, and get 4 hits.



&quot;Let&#39;s go in when he&#39;s on the edge of the deck!&quot;



&lt;How to defeat Vainqueur Fidele&gt;



Point the nose at the Vainqueur and use the boost, then roll when you get close to the enemy. Be careful, though, because if you get too close without rolling, you may get grabbed and thrown by the enemy.



-------------------------------------------------- ----------

83





1-3 Destroy the towing machine and recover Merville



Main Enemies

Towing machine x 6



Special Rules

If they don&#39;t defeat them within the time limit, the mission will fail.



Even tough towing machines are a breeze with the Pumpkin Bomb!



This mission is also carried out in cooperation with Ciel. As with Shell 1-3, you must destroy all the towing planes within 3 minutes, but it will be easier if you use the powerful pumpkin bomb to attack the towing planes. However, the pumpkin bomb has a unique trajectory after launch, has low guidance capabilities, and flies downward almost directly in front of your ship. If you aim directly below the target and lock on while descending vertically to bomb it, you should be able to hit it well.



If you get hit by the towing plane&#39;s iron balls, you&#39;re in danger of falling! If you get too close to the towing plane, use a power slide or boost to escape.



-------------------------------------------------- ----------

1-4 Destroy the engine room and defeat the airship



Main Enemies

Gauche x1, Dorwat x1



Special Rules

none



Let&#39;s aim for a big prize with a stealing strategy



Copan will first take on Drowat. However, while fighting Drowat, there is a high possibility that Gauche will be sunk by Ciel and his friends. In order to earn more bounty money, leave Drowat for later and attack Gauche first. However, if Gauche is sunk, Ciel and Fam will attack Drowat. It is best to only destroy the turrets with high bounty money that can be chained, and leave the rest to Ciel and his friends and return to attacking Drowat. The most bounty money can be earned from the armaments of the two battleships is the storage turret on the side of the hull, with a maximum of 7 chains. This turret will not appear unless the bridge is destroyed, but it is an important target if you are aiming for a high bounty money.



After the shield is destroyed, an internal cannon will appear on the bottom of the ship. If you hit this cannon twice with a fireworks missile, you can get four hits.



You can also get a large prize by chaining the vacuum tubes around the engine room, but this should be done last as it will sink the battleship.



POINT Aim for this!



If you destroy the bridge at the front of the ship, the battleship will become uncontrollable. The bridge has high durability, so it is a good idea to attack it using pumpkin bombs.



After destroying the bridge, the storage battery will appear after a while (the Drowat only appears when the bridge is destroyed). If you destroy the storage battery from the bow, you can destroy the battery by detonating the chain reaction.





84



SCENE II

Assault! Vantor Collection Team

3:00pm



A cargo ship carrying 15 bags of various sizes is heading to Leeve on a regular route. Ciel and his friends&#39; job this time is to escort the cargo fleet selecting exhibits for the exposition. Fam is patrolling the perimeter of the fleet in Blanche, while Ciel and Copain are talking in a room on the cargo ship. The sky is clear and there are no suspicious planes in sight, but Copain, who had hoped that many things would happen, seems a little dissatisfied.



&quot;What a disappointment, being a guard is so boring...&quot;



The client this time promised to give Shell and the others a new engine as a reward for their work. As they were discussing whose machine should get the new engine, Fam, who was on guard, called over the radio. A battle ship carrying Poole and the others appeared from behind the clouds.



&quot;Shell, Copan, and Ventre are here!&quot;



Looking out the window, Ciel and Copan see countless aircraft approaching the fleet. After giving instructions to Fam, Ciel and Copan rush to the machine hangar. Copan&#39;s expression, which had looked bored before, is now lively.



&quot;Please wait until we get out, Fam!&quot;



The presence of a gunner on the cargo fleet was unexpected for Vantle, but there was no change to the plan. The plan was to destroy only the cargo ship&#39;s engines, land it in the sea, and then retrieve the exhibits. Vantle&#39;s battle to crash the cargo ship - Premiere. This is where Shell and the others&#39; work really begins.



&quot;Okay, aim for the freighter&#39;s engines!&quot;



&quot;Raja!&quot;



85



-------------------------------------------------- ------

Fam



Number of occurrences



A

2-1

Defeat the combat craft and protect the convoy





B

2-2

Defeat the combat craft and protect the freighter fleet



C



D

2-4

Defeat many enemies and defeat Grandir





-------------------------------------------------- ------

Ciel



Number of occurrences



A

2-1

Defeat the combat craft and protect the convoy





B

2-2

Defeat Balaine and protect the cargo fleet



C

2-3

Defeat the fleeing combat boats



D

2-4

Defeat Grandir while defeating many enemies.



-------------------------------------------------- ------

Copan



Number of occurrences



A

2-1

Defeat the combat craft and protect the freighter fleet





B

2-2

Defeat the Assault Pods and protect the freighter convoy



If you fail

Scare away the Phule with the Hound Missiles



When completed

Go to 2-3



C



D

2-3

Defeat Grandir while defeating many enemies.



-------------------------------------------------- ------



Appearing enemy forces



A



Femme Ciel Copain



Five-plane formation of Blumier (Premier C x 1 + Premier BX4) *1

Premier 5 aircraft formation (Premier Cx3 + Blumier Ax2) *1

Premier 5 aircraft formation (Premier CX1 + Premier B x 2 + Bluemier AX2) *1

Premier 15 aircraft formation (Premiero x 15) x 1 (reinforcement)

Premier 5 (Premiero 5) x 2 (reinforcements)



B



Fam



Star Bull CX1

Premier 6-plane formation x1

Star Bull C x 1 (reinforcement)

Star Bull AX1 (reinforcements)

Premier 4-plane formation x 2 (reinforcements)

Star Bull C x 1 (reinforcements) *2

Premier 4-plane formation (Premier B x 4) x 1 (reinforcement) *2

Balaine Long-range Cannon Type 1

Premier 6 aircraft formation (Premiero x 6) x 2

Premier 5-plane formation (Premier CX1 + Premier BX4) x 2



Ciel



Palane long-range gun type x 1

6-plane formation of Premiere (Premier DX6) x 2

Premier 5-plane formation (Premier CX1 + Premier BX4) x 2



Copan



Assault Pod 5-plane formation (Assault Pod Ax5) x 1

Premier 5-plane formation (Premier BX5) x 1

Assault Pod 3-plane formation (Assault Pod Ax3) x 1 (reinforcement) *3

Premier 3-plane formation (Premier B x 3) x 1 (reinforcement)

Assault Pod 3-plane formation (Assault Pod Ax3) x 1 (reinforcement) *3

Premier 3-plane formation (Premier BX3) x 1 (reinforcement)

Assault Pod 2 (Assault Pod AX2) x 1 (reinforcements) *3

Premier 2-plane formation (Premier BX2) x 1 (reinforcement)

Assault Pod 9-plane formation (Assault Pod AX9) x 1 (reinforcement) *3

Premier 9 aircraft formation (Premier B x 9) x 1 (reinforcement)

Parene long-range gun type x1

Premier 6-plane formation (Brumiero x6) x2

Premier 5-plane formation (Bluemier CX1 + Bluemier BX4) x 2



C



Ciel



Star Bull C x 10

Premier 6 aircraft formation (Premier Cx1 + Premier BX5) x2



D



Femme Ciel Copain



Grand Deal x 1

Balene Ax1 (appears multiple times)

7-plane Premier formation (1 Blumier C + 6 Premier AX) x 1

Premier 6 aircraft formation (Premier Ax6)/

Five Premiers in formation (Premier CX1 + Premierero x4 or Premier BX

5) x 3 (appears multiple times) *4





*1 Premier 5-plane formation will appear in total for 23 units.

*Only when playing with 2 Target All, they will appear as reinforcements if you shoot down the first 6-plane Star Bull and Premier formation within 40 seconds.

*3 The total number of Assault Pods that appear cannot exceed the number of remaining freighters.

*4 Premier 6-plane squadrons and Bloomier 5-plane squadrons will appear together for a total of 3 units.





86



Femme SCENE 2



In the beginning of this scene, switching the target lock is important. Also, in the final battle with Grandir, if you get a higher bounty than Ciel and Copan, you can get a new engine.



-------------------------------------------------- ----------

2-1 Defeat the combat craft and protect the freight fleet



Main Enemies

Premier 5-plane formation x 23.

Premier 15-plane formation (DX15) x 1, Premier 5-plane formation (DX5) x 1



Special Rules

If the cargo ship is destroyed, the operation fails.



Don&#39;t miss any reports from the cargo ship



Here, you will be fighting off enemies attacking a cargo fleet. When an enemy attacks a cargo ship, a general alarm will sound, so immediately press the 4 button to switch target lock and quickly repel the enemy. If a cargo ship is sunk, your bounty will be reduced for each ship, so be careful. In order to reduce time loss, do not pursue too far. Once you have defeated the leader and the enemy is in an escape state, press the R2 button to switch targets.



&quot;The golden rule is, &#39;Don&#39;t chase after those who leave.&#39; It&#39;s okay to ignore enemy planes that are in escape mode.&quot;



img caption1

The alarm bell rang. Somewhere a freighter was under attack.

You&#39;re being hit. Press the triangle button immediately.



img caption2

Press the triangle button to lock on the target.

The enemy will be changed to one attacking the freighter.



img caption3

Let&#39;s hurry and help the freighter.

If it takes a while to turn, turn actively!



-------------------------------------------------- ----------

2-2 Defeat the combat craft and protect the freight fleet



Main Enemies

Starble x 3



Special Rules

If the cargo ship is destroyed, the operation fails.



Resolutely prevent Starburst from approaching the fleet



In this mission, Fam will fight Star Bull. First the first one will appear, and after a while two more will appear. If they get too close to the cargo fleet, they will sink the cargo ships one by one, so you must shoot them down quickly. First, hit them with two or three firework missiles to destroy the side platform, then attack with maximum lock of the Cross Missile and you should be able to shoot them down. The mission will be cleared when Star Bull and Mamoru&#39;s Premier formation are wiped out.



img caption 1

If you launch a cross missile after raising your Star Bull to the same altitude as your aircraft, it should hit the enemy ship&#39;s hull.



87



-------------------------------------------------- ----------

2-3 Defeat Grandir while defeating many enemies.



Main Enemies

Grand Dirme 1



Special Rules

The winner of the most prize money among the three will win a new engine.



Defeat giant battleships armed like hedgehogs



The large aerial battleship Grandir is equipped with numerous weapons, and if you approach carelessly, you will be targeted by several cannons. When passing by an enemy, be sure to use the boost to quickly retreat or use a power slide, otherwise it is dangerous. Grandir&#39;s weak point is the four engines in total. It can be easily destroyed by firing a maximum lock cross missile from the front, so it is safer to prioritize destroying the engines and sink Grandir rather than destroying the weapons and earning bounties until you get used to it. Also, the three defensive cannons equipped on each engine are annoying weapons that fire shells in rapid succession, so if you want to be safer, it is better to attack the engines with the maximum lock fireworks scythe and destroy them. If you earn more bounties in this mission than Ciel and Copan, you will receive a new engine. Even if you don&#39;t have an engine, it won&#39;t be a hindrance to the future development of the game, but if you really want one, please refer to the bounty hunting methods for Ciel 2-4 on page 89 and Copan 2-4 on page 91.



Destroy the lightning generators to get bonuses



When the Grandir&#39;s armament is damaged to a certain extent, a lightning generator will appear on the deck. This device creates clouds that reduce visibility and powers up the feather bombs flying around. You will receive a bonus if you destroy the lightning generator, but this device is immune to normal attacks such as machine guns and optional weapons. It can only be damaged by chaining the machine guns built into the top surface around it.



img caption 1

The ranking of the prize money won in this battle will be displayed in the upper left of the screen. If you are in first place when Grandir is sunk, you will receive a new engine.



img caption 2

When you acquire a new engine, your ship&#39;s top speed, acceleration, and cooling speed will increase, making future gameplay much easier.



img caption 3

The feather bomb, strengthened by lightning, has a higher pursuit ability. If you are being chased, use a power slide to make it 9.



img caption 4

The bonus for destroying the lightning generator does not count towards the prize money needed to acquire the engine. You may want to prioritize destroying the engine.



88



Cielciel SCENE 2



Ciel is not suited to winning big prizes, but in order to get the new engine, he must win more prize money than the other two. The new engine is essential for Ciel, who is prone to running out of fuel in Scene 5.



-------------------------------------------------- ----------

2-1 Defeat the combat craft and protect the freight fleet



Main Enemies

Premier 5-plane formation x 23.

Premier 15-plane formation (DX15) x 1, Premier 5-plane formation (DX5) x 1



Special Rules

If the cargo ship is destroyed, the operation fails.



Keep damage to shopping fleets to zero



Ciel and Copain start this scene from a cargo ship. There is already fighting in the area, so change your target lock and start attacking the closest enemies. It is best not to chase distant enemies, but to stay close to the cargo fleet as much as possible. If a cargo ship is sunk, a bounty of 50,000 per ship will be deducted at the end of the scene. Be careful of the Fuho Bell and make protecting your cargo ships your top priority.



img caption1

If you lock on and fire two fireworks missiles, you can destroy the enemy&#39;s five-plane formation. Use them generously.



-------------------------------------------------- ----------

2-2 Defeat Balaine and protect the cargo fleet



Main Enemies

Special Rulervallene long-range gun type x 1



Special Rules

If the cargo ship is destroyed, the operation fails.



Don&#39;t let them fire the extremely powerful Balaine Cannon!



The enemy in this mission is the small air battleship Baleine. Baleine is located quite far away from the cargo fleet, so immediately boost and approach. The Baleine cannon, which fires at regular intervals, is powerful enough to sink a cargo ship in one hit. It&#39;s tempting to attack the long barrel of the hull, but the small cannon behind it is the main body, and attacking this will destroy the Baleine cannon. However, even if you destroy it, it will be repaired after a while, so attack the hull while the cannon is silent and sink the Baleine as soon as possible.



&lt;How to defeat Balaine&gt;

img caption1

First, lock on the fireworks missiles from the front and fire them. They will destroy some of the gun turrets.



img caption2

After firing the fireworks missile, it will rise and attack the Balaine cannon from above with machine guns, destroying it.



img caption3

Move around to the side and attack the hull with Heavy Bullets, then attack again once the Balene Cannon has been repaired.





89



-------------------------------------------------- ----------

2-3 Defeat the fleeing combat boats



Main Enemies

Star Bull CX10



Special Rules

If you beat them within the time limit you get a bonus.



Let&#39;s take down one unit with one attack.



Only Ciel has the mission to pursue the fleeing enemies after destroying the enemy forces that attacked a cargo fleet. If you can shoot down 10 Star Bulls within 3 minutes, you&#39;ll get a bonus. There&#39;s no time to waste here, so you need to hit the enemy unit with two Cross Missiles in one approach and shoot it down for sure. I don&#39;t lock on to the enemy unit, so I aim the sight at the center of the unit and tap the ◎ button twice.

You can press it to fire a cross missile.



&quot;When detonating a Cross Missile, use a power slide to get closer.&quot;



img caption 1

If you have time, use the Fireworks Missile to destroy the Star Bull&#39;s side turrets and get a hit. You can earn bounties efficiently.



-------------------------------------------------- ----------

2-3 Defeat Grandir while defeating many enemies



Main Enemies

Grand Dirme 1



Special Rules

The winner of the most prize money among the three will win a new engine.



Use your bonus count to win big prizes



In this battle, you need to earn bounties to acquire engines. The trick to earning them efficiently is to keep your bonus count high and get chains and hits while the bonus multiplier is high. The bonus count and bonus multiplier displayed in the upper left corner of the screen will increase when you defeat an enemy and decrease if you do nothing. If you just defeat enemies to gain bonus counts and then get chains and hits, your winnings should increase dramatically.



img caption 1

No matter how high the bonus multiplier is, keep in mind that unless you kill an enemy with a chain or hit, the bounty efficiency will not be high.





img caption 2

Among the turrets equipped on the Grandir, the one you want to target in particular is the one in the left photo, which has a large number of chains. Destroy them before they can be attacked by Fam or Copan. In particular, the six machine gun turrets on the underside of the ship can be destroyed all the way to the stern emblem if you chain six from the turret in the center of the ship, giving you an additional 50,000 bounty.



90



Copain SCENE 2



Have you gotten used to the unique characteristics of the Chevalier yet? The difficulty of the missions in the advanced Copan increases dramatically from Scene 2 onwards. If you don&#39;t understand the characteristics of the aircraft, there are many difficult scenes.



-------------------------------------------------- ----------

2-1 Defeat the combat craft and protect the freight fleet



Main Enemies

Premier 5-plane formation x 23.

Premier 15-plane formation (DX15) x 1, Premier 5-plane formation (DX5) x 1



Special Rules

If the cargo ship is destroyed, the operation fails.



Be careful not to stray from the charter fleet.



The Chevalier has poor turning ability, so you need to be careful in this mission. Even if the alarm bell sounds, you won&#39;t be able to respond if the enemy is behind your aircraft or far away, so try not to get too far away from the cargo convoy. However, you&#39;ll want to stretch your legs and attack the large squadron of Plumieros that appears as reinforcements. If the enemy squadron gets too close to the convoy, they&#39;ll scatter, so use the maximum lock of the Fireworks Missiles to shoot them down all at once while they&#39;re still far away.



img caption1

When fighting a large formation of Premier Ds, it&#39;s better to hit them with a Hyper Missile from behind rather than from the front, as you&#39;ll be able to hit more of them at once.



-------------------------------------------------- ----------

2-2 Defeat the Assault Pods and Protect the Freighter Convoy



Main Enemies

Special rules: 5 assault pods x 1, 3 assault pods x 2,

Assault pod 2x1, Assault pod 9x1



Special Rules

If you miss the assault pod, it will become a different mission.



Don&#39;t neglect to check the target display



An assault pod loaded with pools appears from the rear of the fleet. If you can survive the four waves of assault pods and shoot down all the enemies, you will receive a bonus. It is important to check the target display in the upper right corner of the screen one by one to make sure that you have not missed any enemies. The assault pod has low durability, so if you can get into an attacking position, you can shoot it down with a machine gun attack, but if you can&#39;t make it in time, use a fireworks missile to shoot it down.



img caption1

Once you get past your opponent, it&#39;s hard to get back into attack position again. It&#39;s easier to lock on and fire one firework missile from a distance.



&quot;If you&#39;re going to attack with a machine gun, aim from behind the enemy where the difference in relative speed is smaller!&quot;





-------------------------------------------------- ----------

2-2 Drive away the Phule with the Hound Missiles



Main Enemies

Hammer pulley



Special Rules

If the cargo ship is destroyed, the operation fails.



It is no good if even one hammer pulley is left behind.



If you let the assault pod escape in the previous mission, the Pulé that jumped out of the pod will start destroying the cargo ship&#39;s engines. If you leave it alone, the cargo ship will sink, so fire a hound missile at the Pulé to drive it away. If you let multiple pods escape, it can be hard to tell which ship the enemy is on, but in this case, you can find the Pulé by pressing the R2 button several times and checking the target display.



img caption1

If you hesitate to use the Hunting Missile, it may be too late. Lock on and fire.



&quot;If you&#39;re going to attack with a machine gun, aim from behind the enemy where the difference in relative speed is smaller!&quot;



-------------------------------------------------- ----------

2-3 Defeat Grandir while defeating many enemies.



Main Enemies

Grand Deal x 1



Special Rules

The winner of the most prize money among the three will win a new engine



Destroy the engine of the one-brained one and earn a bounty



If you want to earn bounties in the battle against Grandir, it&#39;s best to only aim for chains or hits on the turrets (see the diagram for Ciel 2-4 on page 89). If there are no more noteworthy turrets, focus on the built-in machine guns that revive even if destroyed, and keep repeating the chain until your teammates take down Grandir. There are two types of built-in machine guns, one on top and one on the bottom, so you can earn bounties efficiently by going back and forth between the deck and the underside of the ship and destroying the turrets the moment they revive. It&#39;s also important to shoot down the Premier squadrons flying around. There&#39;s no need to go off the route mentioned above to attack the machine gun turrets, but if you see a Premier squadron, try to shoot them down as much as possible to add to the bonus count as much as possible.



img caption 1

The Mid-Autumn Festival, 3 Retako in 4. You can earn more prize money by aiming for a hit with the maximum lock of the firework missiles rather than chaining them individually.



img caption 2

You don&#39;t need to sink the Balene, but if you lock on the Fireworks Missiles to the max and destroy the six small side guns, you&#39;ll earn a big bounty.



img caption 3

If you chain the built-in machine gun from below, the shaft supporting the engine will break.



img caption 4

After breaking the left and right shafts, both the front and rear engine units on one side are destroyed...



img caption 5

One side of the mechanism will fall off and the ship will tilt. This will make it easier to shoot with the built-in machine gun.



92



SCENE III

Machine Soldier Landing

7:00pm



A huge telescope stands in the middle of the Rive Cove, and when it is lit up at night, its golden body shines even more beautifully. Ventre was looking at the telescope in a hotel room near the cove. As someone who loves beautiful things, there is no way he would be satisfied with just looking. It seems he is also plotting something sinister.



At that time, Fam was sitting across the table from Ardi at the Gunner Cafe, talking to him. Ardi was going to treat them to dinner that night, but Ciel hadn&#39;t arrived at the appointed time. As the two of them were worried, they suddenly heard Yoshine coming from the ocean.



The giant black body that appeared through the waves was a mechanical soldier that had been stolen while being transported to Reeve. The mechanical soldier walked slowly towards the telescope. Fam and Ardi quickly got into their battleship and joined the other officers at the scene. A tank unit was positioned in the mechanical soldier&#39;s path, fully prepared to intercept it. Fam challenged the giant mechanical soldier to protect the telescope.



&quot;This wonderful telescope is mine.&quot;



&quot;I can&#39;t believe Ciel isn&#39;t coming, let alone Copan?&quot;



&quot;Tank formations are in place!&quot;





Mission Chart

-------------------------------------------------- ------

Fam



Number of occurrences



A

3-1

Stop the mechanical soldiers from invading



What to do if you fail

3-2

Recover the telescope from the mechanical soldier





Appearing enemy forces

-------------------------------------------------- ------

A

Fam



Vaincourt Sage x 1

Detrois 3-plane formation (Detrois BX1 + Detrois AX2) x 1 (can appear multiple times) *1

Star Bull C x 1

Five-plane formation of Premiere (Premiere Ax5) +

Detrois 5-unit squadron (Detrois Bx1 + Detrois Ax4) x 1 (reinforcements) *2

Detrois 5-plane formation (Detrois BX1 + Detrois Ax4) x 1 (can appear multiple times) *3

5-plane formation of Bloomier (Bloomier x 5) x 5 (appears multiple times) *4

3-unit Brogre squadron (Brogre C x 1 + Brogre AX 2) x 1 (appears multiple times) *4

Detrois 3-plane formation (Detrois B x 1 + Detrois A x 2) x 2 (can appear multiple times) *5



*1 They will appear from the start of the mission and reappear until the mechanical soldier breaks through the Canal Bridge.

*2After the police plane drops the electric bomb, Premier appears with Detroit hanging from his arm.

*3 They appear after the surveillance plane drops the electric bomb, and reappear until the mechanical soldiers break through the Canal Bridge.

*4 They will appear when the mechanical soldiers break through the Canal Bridge, and will reappear until the telescope is seized.

*5 It appears when the mechanical soldier seizes the telescope.







93



Femme SCENE 3



In this mission, you won&#39;t receive any help from Ciel or Copain. With the help of the police, you must defeat the mechanical soldiers that are advancing towards the telescope on display in the city of Leeve.



-------------------------------------------------- ----------

3-1 Stop the mechanical soldiers from invading



Main Enemies

Vaincourt Sage x 1



Special Rules

The bonus amount changes depending on the degree of enemy invasion. If the telescope is stolen, a different mission will start.



Vaincour&#39;s weak point is the shoulder joint.

-------------------------------------------------- ----------

The robotic soldier advances, aiming for the telescopes installed in the city. The robotic soldier&#39;s weak point is the shoulder joints that connect the robotic soldier&#39;s body to its arms. If you destroy both the left and right shoulder joints, the robotic soldier will retreat. However, the shoulder joints are protected by highly durable movable shields, so unless you destroy the shields, you will be unable to inflict any damage. During the mission, the police will stop the robotic soldier&#39;s movement several times, but while it is stopped, the troublesome shoulder shields will not function, so do not miss this opportunity to attack the shoulder joints directly.



img caption

Target icons for each part of the mechanical soldier. The one on the left is the shield, and the one on the right is the shoulder joint. Always keep an eye on the remaining durability of each part during the mission.



First, defeat Star Bull.

-------------------------------------------------- ----------

The first thing you want to do is shoot down the Star Bull flying in the sky. If you leave it, it will attack you later, which will be troublesome, so make sure to kill it now. Quickly change your target to get the Star Bull in your field of vision and attack it with Fireworks Missiles and Cross Missiles. Once you&#39;ve shot down the Star Bull, defeat the Detrois guarding the mechanical soldier to increase your stock of Cross Missiles. You can continue earning Cross Missiles until the mechanical soldier fires its hovering bomb.



img caption

Near the Detrois spawn point, defeat the enemy as soon as it appears to obtain Yamakana&#39;s Cross Missile.



How to deal with those pesky airborne bombs

-------------------------------------------------- ----------

When the robotic soldier reaches the electric bomb drop point (see page 95), the patrol plane will prepare to drop the electric bomb to hold him back, but the robotic soldier senses something is wrong and launches a defensive hover bomb above his head. If this continues, the electric bomb will be blocked. When the hover bomb is launched, a warning bell will ring, so press the 4 button to target the hover bomb and attack with a fireworks missile or machine gun. If you can destroy all the hover bombs before the robotic soldier reaches the electric bomb drop point, the patrol plane will attack and stop the robotic soldier. While the robotic soldier is stopped, you can attack the shoulder joint directly, so immediately attack with a maximum lock of the cross missile. However, be careful because attacks may not hit the shoulder joint unless you aim from the front or back of the robotic soldier.



img caption

A hovering bomb is a target that is difficult to gauge the distance from your plane. Aim it in front of you and keep firing your machine gun at close range to attack.



img caption

While the mecha is stopped, a squadron of five Premiers with Detrois hanging from them will appear from above. If you have time, shoot them down at this stage.





94



Tanks and minefields at Canal Bridge

-------------------------------------------------- ----------

If you fail to stop Vaincourt at the electric bomb drop point, six of the patrol&#39;s tanks will attack on the Canal Bridge (see page 95) further ahead. However, the tanks may be attacked by Detroit and Premier before the robot attack, so go to their aid as soon as the alarm bell rings. If you are down to just two tanks, you will not be able to hold back the enemy. If you are unable to repel the enemy at the Canal Bridge, the bridge will be destroyed and the robot will start to advance again, but you will have one more chance to hold back the robot before it reaches the telescope. That is the minefield (see page 95 for its location). When the robot reaches the minefield, detonate the mines with your machine gun or fireworks missiles. If you fail to stop them, the robot will finally take the telescope.



img caption

If you fail to protect the Canal Bridge, your event bonus upon clearing the stage will be reduced, so you&#39;ll want to repel the enemies by this point.



img caption

Immediately after destroying the Canal Bridge, the mechanical soldiers will stop moving for a while, but when they start moving again, they will move at a faster speed than before.



-------------------------------------------------- ----------

3-2 Recover the telescope from the mechanical soldier



Main Enemies

Vaincourt Sage x 1



Special Rules

The bonus amount changes depending on the degree of enemy invasion. If the telescope is destroyed, the operation will fail.



Do not damage the telescope!

-------------------------------------------------- ----------

Once the robotic soldier has taken the telescope, it will begin to retreat. It is still possible to take back the telescope, but be careful not to destroy the telescope the robotic soldier is holding. The damage the telescope has received is displayed in the upper left corner of the screen. You need to be extremely careful with your attacks, so when using a cross missile, make sure to lock onto the shoulder joint before firing. If the robotic soldier is holding a telescope, the shield will no longer function, and damage will be dealt directly to the shoulder joint.



img-caption

Telescope Destroying the telescope will not result in game over, but it will result in a penalty of minus 1 million yen in prize money.



If the floodgates of our final line of defense are breached...

-------------------------------------------------- ----------

When the robotic soldier with the telescope approaches the water gate, the gate will be destroyed by the enemy&#39;s combat boat. If you fail to defend the water gate, your bonus will be zero, so try to at least hold off the enemy with this. After this, if you can defeat the robotic soldier before it retreats into the sea, you will just barely be able to recapture the telescope. If you let the robotic soldier escape, the mission will end in failure, but you will still receive the clear bonus.



img-caption

If Iruma, the last Honus, is destroyed by the enemy, the bonus prize money earned in this scene will be reduced to zero.



img-caption

If you let the robots escape, the mission will fail. However, even if you fail here, the prize money will be reduced, and it will not result in game over.



femme-talk

Machine guns can also damage telescopes, so use them as little as possible!



95



Leeb Town South

MAP

Vaincourt&#39;s Advance Route

Vaincourt appears from the sea and heads up the canal to the telescope. There are three points where the patrol can hold back the mechanized soldiers: the electric bomb drop point, the canal bridge, and the minefield. Since you must support the patrol at each point, always pay attention to the current location of the mechanized soldiers and the instructions of Inspector Alday.



1 Detrois appearance point

Just before the mecha arrive at the Canal Bridge, a squadron of three Detrois will appear from this point. If you destroy the Detrois captain, you can get four Cross Missiles, so if you use your active turns in the early stages to circle around this point and earn optional weapons, it will be easier later.



2 Electric Bomb Drop Site

This is the first point where you can hold back the mecha. Here, you must destroy the hover bombs that the mecha shoots above your head and make the surveillance plane&#39;s bombing a success. If the mecha passes this point with the hover bombs still remaining, the electric bomb will be blocked by the enemy.



3 Canal Bridge

The second point where you can hold back the mecha. Six police tanks are deployed on this bridge, and when the mecha approach, they will fire concentrated shots at them to stop them moving. However, before that, the tanks will be attacked by enemy combat boats, so they must be protected.

●Event bonuses you can earn by defending this point

1500000



4 Minefield

This is the last point where you can hold back the mecha. The mines placed here can be detonated with machine gun attacks or firework missiles. If you detonate them when the mecha passes this point, you can stop the enemy&#39;s movement.



5. Telescope

The robotic soldier&#39;s target is this telescope. After capturing the telescope, it will start moving towards the water gate at the bottom left of the map.

●Event bonuses you can earn by defending this point

1200000



6 Floodgates

When the mecha reach this point, Premier&#39;s formation will destroy the gate.

●Defend this point to receive an event bonus of 200,000





Machinery forward momentum

The durability of the Canal Bridge tanks is as follows:

see-image





96



SCENE III

Jet Black Gunner

10:00am



The Progres have arrived in the town of Reeve, bustling with the hustle and bustle of a new morning. Their purpose is unknown, but a considerable number of them are heading towards the town. Upon receiving this message, Ciel takes off from the Gunner Cafe. An ordinary Gunner would have had a hard time, but Ciel is a master gunner. He quickly defeats the Progres. All that remains are Phule and his parachutes.



&quot;Fugite! Lord Vantor!&quot;



&quot;Oh, Reeve&#39;s gunner is quite skilled.&quot;



Someone was watching Shell&#39;s efforts from above. It was a mysterious gunner named Rival from Neige. He had come to Reeve on a certain request, but seeing such a skilled gunner, Val couldn&#39;t just leave him alone. He loves fighting, so he aimed his beloved Phantom at Ciel&#39;s red machine.



&quot;Now, let the battle begin!&quot;



As Ciel takes a breather after shooting down a Progre, the Phantom&#39;s machine gun suddenly fires at him. He manages to avoid the attack, but Rival is still pursuing him. Ciel shouts that they can&#39;t fight when there&#39;s no reason to, but Rival doesn&#39;t listen to him at all. Determined to fight, Shell boosts his machine&#39;s speed.



&quot;I have no choice, I have no choice but to do it.&quot;





Mission Chart

-------------------------------------------------- ------

Ciel



Number of occurrences



A

3-1

Defeat the Black Gunner



Appearing enemy forces

-------------------------------------------------- ------

A

Ciel



Phantom x 1



97



Cielciel SCENE 3



In the battles up until Scene 2, your opponents were the Combat Ships that Puré and his friends were riding on, or battleships that were slower than your ship. However, your opponent in this mission is the highly-functional black gunner machine, the Phantom.



-------------------------------------------------- ----------

3-1 Defeat the Black Gunner



Main Enemies

Phantom x 1



Special Rules

If you defeat Rival within the time limit, you will receive a bonus.



Rival&#39;s first appearance, an aerial battle between gunners!

-------------------------------------------------- ----------

This mission is a one-on-one battle between Avenir and Phantom in the sky above Reeve. Phantom is a formidable enemy with high firepower and maneuverability, and it rotates frequently and is very fast, so it will be difficult to get it into a position to attack. If you can defeat Phantom within 5 minutes, you will receive a bonus prize of 1.5 million, but unless you take advantage of the few opportunities you have and land a powerful attack all at once, it will be difficult to shoot it down in time.



img-caption

An unidentified gunner machine. Its eerie appearance sets it apart from the other machines used by Ciel and the others.





Make effective use of optional weapons

-------------------------------------------------- ----------

Hound Missiles are effective against the fast-moving Phantoms. While the Hound Missiles are biting, the enemy&#39;s movements will slow down, giving you more chances to attack. If you lock Cross Missiles to maximum power and attack the Phantoms, you can shoot them down with two shots. However, if you don&#39;t inflict damage on them for a while, the Phantoms&#39; durability will recover. The key is to concentrate and inflict damage in a short period of time. In addition, the enemy may stop moving for a moment during battle, but this is because they are using the Steam Brake, the same as Avenir&#39;s EX Action, to get past you. If your ship is in a position to attack the enemy, use the Steam Brake to stop them in the air and fire off Heavy Bullets.



img-caption

How to do maximum lock

Once you&#39;re in attack position, hold down the ◎ button and swing the nose left and right to quickly lock on to maximum power!



How to dodge bat missiles

-------------------------------------------------- ----------

The optional weapon used by the Phantom, the bat missile, has high homing ability and is a terrifying weapon that always hits your ship when fired. However, it will self-destruct after flying a certain distance, so if you are shot, it is best to keep boosting and run away. Another good method is to dodge it with a power slide at the moment it hits. Since you are invincible during the power slide, you can destroy the bat missile if you can get in touch with it.



img-caption

Pull the enemy bullet in as close as you can, then power slide at the exact moment it hits. This is a very effective way to avoid guided missiles.



98





SCENE III

Monster of the Waterway

11:00pm



&quot;Can you catch it, Mr. Copan?&quot;



&quot;It&#39;s just a big snake, right?&quot;



The old town of Rive is located north across the sea from the new town of Rive. The town is currently filled with rumors of a monster that appears in the waterway at night and steals the street lights. Copan has come to the old town to defeat this monster. Newspaper reporters have gathered, having heard that they will capture a monster that even the police are powerless to capture. Copan seems completely unfazed, even when he is told that it is a giant snake-like thing.



A mysterious light was seen above the waterway. It seemed that a monster was coming this way. Seeing the giant snake shining like a band of light, Copan started the engine of the Chevalier. The monster passed through the waterway in front of the journalists and spectators. The cameraman tried to capture the monster&#39;s figure - his life depended on it. The Chevalier took off and flew away amid a flickering flash.



When Copan caught up with the monster, its true identity became clear. The monster was the combat boat Exoze, on which Poulet and his men were aboard. When Poulet and his men spotted Copan, they launched an attack. Not to be outdone, Coban fought back. The sound of the engines of Exoze and Chevalier racing through the narrow waterway echoed through the night city.



&quot;Okay, let&#39;s go! Make sure you have the front page open.&quot;



&quot;Blue Gunner.

Commence attack!&quot;



Mission Chart

-------------------------------------------------- ------

Copan



Number of occurrences



A

3-1

Defeat Exoze before passing through the waterway



Appearing enemy forces

-------------------------------------------------- ------

A

Copan



Copan

Exoze Gold x1 + Exoze child unit x15 *1

Exoze Red x 1 Exoze slave x 15 (appears when Exoze Gold is defeated) *1

Exoze Blue x 1 + Exoze slave x 15 (appears when Exoze Red is defeated) *1

Detrois 6-unit squadron (Detrois B x 1 + Detrois AX 5) x 1 (reinforcement)

Premier 4 aircraft formation (Premier D x 4) /

Assault Pod 4-plane formation (Assault Pod B x 4) x 1 (reinforcement) *2

Detrois 6-unit squadron (Detrois Bx1 + Detrois AX5) x 1 (reinforcement)

Assault Pod 5-plane formation (Assault Pod C x 5) x 1 (reinforcement)

Premier 4 aircraft formation (Premier Dx4) /

Detroit 4-unit squadron (Detroit B x 1 + Detroit A x 3) x 1 (reinforcements) * 2



Detroit 4-unit squadron (Detroit Bx1 + Detroit Ax3) x 1 (reinforcements) *2

Premier 6 aircraft formation (Premiero x 6) /

Assault Pod 6-plane formation (Assault Pod BX6) x 1 (reinforcement) *2

Detrois 3-unit squadron (Detrois B x 1 + Detrois AX2) x 1 (reinforcement)

Premier 4-plane formation (Premiero x 4) x 1 (reinforcement)

Premier 4-plane formation (Premiero x 4) x 1 (reinforcement)

Detrois 6-unit squadron (Detrois Bx1 + Detrois Ax5) x 1 (reinforcement)

Premier 2 aircraft formation (Premiero x 2) /

Assault Pod 2-plane formation (Assault Pod B x 2) x 1 (reinforcement) *2



*1 The child will be revived as many times as he wants, as long as the captain&#39;s plane is not shot down.

*2 Premier will appear with an Assault Pod or Detrois hanging from him.





99



Copain SCENE 3



This is the only mission in which the viewpoint is fixed to behind-the-scenes scrolling mode. If you can pursue the enemy combat history fleeing at high speed and destroy them all, you can get a bonus prize.



-------------------------------------------------- ----------

3-1 Defeat Exoze before passing through the waterway



Main Enemies



Exoze Gold (Exoze Gold x 1 + Exoze handset x 15) x 1,

Exoze Red (Exoze Red x 1 + Exoze child unit x 15) x 1,

Exoze Blue (Exoze Blue x 1 + Exoze child unit x 15) x 1



Special Rules



If you destroy all the Exoze, you will receive a bonus for each squadron.





Only one scroll mode scene

-------------------------------------------------- ----------

In this scene, the viewpoint is fixed behind the player&#39;s ship and cannot be changed. The scene is automatically cleared when the fleeing enemy flies a certain distance, so you must destroy the enemy within the set distance. Using the boost only closes the distance to the enemy a little, and the mission ends earlier, so you should refrain from using the boost. There are three units of Exoze combat boats that appear as enemies. Only one unit appears at a time, and the first to appear is Exoze Gold. Red, which destroys Gold, appears, followed by Blue. When you destroy a squadron of Exoze, a bonus prize is added for each unit, and when all units are destroyed, the total amount will be 1.5 million. Note that you start this scene without any optional weapons, but you can obtain pumpkin bombs during the mission.



img-caption

The remaining distance and the position of your ship are displayed in the upper left corner of the screen. When the remaining distance reaches 0m, which is about 29000m at the start, you have cleared the stage.



img-caption

In addition to Exoze, you will also encounter Premiers, Assault Pods, and Detroits. Defeating these enemies will give you Pumpkin Bombs.





The trick is to defeat the child units from the back ~ Exoze Gold ~

-------------------------------------------------- ----------

Exoze is formed with one commander and 15 slaves, and if you shoot down the commander, you can destroy them all. No matter how many slaves you shoot down, the commander will be repaired if he is safe, but you cannot get close enough to attack the commander unless you shoot down the slaves that follow, so the key is how to reduce the number of slaves. It is effective to hit them with machine guns, but pumpkin bombs are also effective only if the waterway is straight. However, be careful that the pumpkin bombs will hit the walls of the waterway where the waterway is curved. The first enemy you will fight is the Exoze Gold formation. Normally, they attack in a single file with only machine guns and artillery, but when they reach a certain point (19,000m, 12,000m, 2,300m remaining), they change into a spiral attack formation and launch a lightning attack. The center of the screen is a safe zone for electric attacks, but you can also dodge them with a power slide.



img-caption

When the ExoZ squadron is in attack formation, it&#39;s a great opportunity. Let&#39;s hit the maximum lock of the pumpkin bomb.



&quot;Just keep hitting it with the machine gun while power sliding!&quot;



100



Damage it as soon as it appears ~ Exozered ~

-------------------------------------------------- ----------

When you destroy all the Exoze Golds, Exoze Red will appear from behind and overtake your ship. During this time, you can fight them more efficiently by using machine guns and pumpkin bombs to inflict damage on them (this fighting method is also effective when the next Exoze Blue appears). Exoze Red will also form an attack formation at certain points (19,000m, 12,000m, and 2,300m remaining) and will fire pistol bullets in rapid succession. In this case, the safe zone is near the center of the screen.



img-caption

Your best chance is right after the period. Match the enemy&#39;s appearance position with the height of your ship, and attack the moment the demo ends.



Be careful when using pumpkin bombs ~ Exoze Blue ~

-------------------------------------------------- ----------

The last enemy to appear is the Exoze Blue. This unit will not change into an attack formation as it will focus on fleeing, but it will attack with powerful bombs and machine guns. Both projectiles can be destroyed, so keep firing the machine gun at all times. One thing to be careful of when fighting the Exoze Blue is the area in which you are fighting. If you are approaching the circular waterway area (→P101), there are many curves, so be careful when using pumpkin bombs.



img-caption

If there are too many bombs to handle, try power sliding. You may lose the battle if you can&#39;t destroy them all with the machine gun.





How to earn prizes in Scene 3

-------------------------------------------------- ----------

In Copan&#39;s Scene 3, the bonus multiplier is quite high, but since there are very few opportunities to get chains or hits, it is a difficult mission to earn prize money. If you dare to earn money, when the enemy is in an attack formation at 19,000m and 12,000m remaining, hit the center commander with a pumpkin bomb at maximum lock. If you are successful, you may get 10 hits or more by involving the surrounding child units. If you aim for this method, you must reduce the durability of the commander to a level that you can defeat it with one hit before changing the formation. In addition, you want to keep all the child units. In order to attack the commander, you must destroy all the child units at once, so you will probably dive once or twice to replenish the child units. It would be best if you can defeat Exoze Gold at 19,000m remaining and Exoze Red at 12,000m using this method. After that, try to get as many hits as possible from the child unit of Exoze Blue that appears at the end.



img-caption

If you use pumpkin bombs to attack the invading troops, you may be able to successfully get Exoze involved and score a hit.



img-caption

The Exoze Blue won&#39;t be in attack formation, but the bonus multiplier is high in the second half of the scene, so aim for Pumpkin Bomb Bebit.



img-caption

The key is to inflict as much damage on the leader&#39;s unit before they form an attack formation. Time your enemy to dive well, and refrain from attacking just before they form an attack formation. If you reduce the number of child units, the number of hits you can get will decrease.



101



Old Town and Waterways

MAP



The waterway where Copan pursues Exoze is made up of eight sections, and some areas have distinctive terrain with many curves and elevation changes. Also, enemies change their attack formation depending on the remaining distance, so be sure to keep track of your current location when fighting.





1Starting point - Saint Bridge

This area has many straight waterways and is easy to fight in. You can only get the pumpkin bomb by defeating the six Detrois that appear at the 2710m remaining point, so you will be fighting with machine guns in most of the area.



2. Old Town Arc de Triomphe

This is also an area with many straight waterways. At 26,000m remaining, the Premier 4th generation will appear with a hanging Gaku-Pot. If you leave the Assault Pod alone, a Poule will jump out, so shoot it down quickly.



3 Bliss Park

There is land until the remaining 2400m point, and you cannot land on the water. It&#39;s a chance to attack. After that, you return to the waterway, but there are many tangerines in this area, and pumpkin bombs are often blocked.



4 Underground reservoir

The waterway is generally straight, making this an area where pumpkin bombs are easy to use. With 19,000m remaining, a squadron of gold and red Exoze will attack in attack formation.



5 Water Tower

Up until the last 13,000m there are many turns and curves, so you want to avoid using pumpkin bombs. The second half is mostly straight lines, and at the last 12,000m the red and gold Exoze squadrons will go into attack formation.



6 Ring Alley Castle

An area with a series of gentle curves. If there are many ExoZes, the pumpkin bombs you lock on to will hit the rear of the formation. The latter half of the course will enter a narrow waterway, which then becomes straight.



7 Centre Station

In this area, you will fly above the ground and over the tracks for a while. You will not dive until you return to the waterway at 4500m remaining. There are many curves while you are on land, so pumpkin bombs are not effective.



8 Westernmost Waterway Street - On the Sea

Finally, you will exit a straight waterway and emerge onto the ocean. If the Exozee that appears with 2,300m remaining is a gold or red Exozee, it will make its final formation change here and form a spiral.





102



SCENE IV

Lakeside castle

6:00pm



Ciel, Copain, and Fam are having breakfast at the Gunner Cafe. As Ciel is reading the newspaper, the topic of the Leeve Exposition comes up. The venue will be the Leeve Museum, and it will be held in a few days. Then Fam&#39;s work will be over, and she will leave the city of Leeve.



&quot;I wonder what inventions will come?&quot;



&quot;Is Fam going back to Neige?&quot;



But Fam seems to be worried. Leaving this town and returning to Neige... The thought of it makes me feel a little cold. Fam has gradually grown to like the town of Leev and the people who live here. As Fam looks troubled by Ciel and Copain&#39;s questions, the phone starts ringing loudly.



&quot;...&quot;



&quot;Why don&#39;t you stay here? There&#39;s a room available.&quot;



&quot;We&#39;re going to invade Vantor&#39;s hideout, so please lend us your strength!&quot;



&quot;Then I have to take this.&quot;



The call was from Ardi. He said he was going to break into Vantor&#39;s hideout and asked Ciel and the others to help him. Ciel hung up the phone and told the two about Ardi. Copain laughed a little before bringing over a package that had been sent by Vantor. Fam, who didn&#39;t know the situation, looked confused.



In the middle of a large lake southeast of Reeve, there was the Ventre House. A huge old castle towering over the lake surface, dyed orange by the sunset. As we approached the castle, we received a challenging radio call from Ventre. Ardi completed his preparations for the attack, and the gunners headed towards the castle&#39;s defenses. The grand operation set in Ventre&#39;s castle began.



103



Mission Chart

-------------------------------------------------- ------

Fam



Number of occurrences A

4-1

Defeat the Formation Squad



Number of occurrences B

4-1

Defeat the defenders guarding the fortress



Number of occurrences C

4-3

Protect your armored vehicle from enemy attacks



Number of occurrences D

4-4

Defeat the Skrets while protecting the armored vehicle.



Ciel



Number of occurrences A

4-1

Defeat the Formation Squad



Number of occurrences B

4-1

Defeat the Drad guarding the fortress



Number of occurrences C

4-3

Protect your armored vehicle from enemy attacks



Number of occurrences D

4-4

Defeat the Skrets while protecting the armored vehicle.



Copan



Number of occurrences A

4-1

Defeat the Formation Squad



Number of occurrences B

4-1

Destroy the cannons guarding the fort



Number of occurrences C

4-3

Protect your armored vehicle from enemy attacks



Number of occurrences D

4-4

Defeat the Skrets while protecting the armored vehicle.





Appearing enemy forces

-------------------------------------------------- ------

A

Fam, Ciel, Copain



Dernier 24-plane formation (Dernier Ax24) x 1

Brogre 8-plane formation

(Progre Cx1 + Brogre Ax7) x 1 (appears when Dernier is below 16)

Brogre 8-plane formation

(Blogre C x 1 + Progress A x 7) x 1 (appears when Dernier is 8 or less)

Progress 8 aircraft formation

(Progressive C x 1 + Progressive A x 7) x 1 (appears when the total number of enemy planes is 10 or less)



B

Fam, Ciel



Dorado x2

2x 4-unit Progre squadrons (only Ciel appears)

(Dernier B Old Castle Defense Force x 1 + Dernier A Old Castle Defense Force x 4) x 1

Dernier 5-plane formation

(Dernier B Old Castle Defense Force x 1 + Dernier A Old Castle Defense Force x 4) x 1 (only Fam appears)

Dernier 5-plane squadron (Dernier B Old Castle Defense Force x 1 + Dernier A Old Castle Defense Force x 4) x 1 (only Fam will appear if you shoot down one unit of the Dernier 5-plane squadron)

Detrois 4-plane formation (Detrois B x 1 + Detrois A x 3) x 2 (can appear multiple times) *1

19x Small Cannons

Medium-sized cannon × 7

Armored turret x4

Large cannon x 3

5 tank formation (Tank A x 3) x 3 (appears multiple times) *2



Copan



Dorado x2

Dernier 5-plane formation

(Dernier B Old Castle Defense Force x 1 + Dernier A Old Castle Defense Force x 4) x 1

19x Small Cannons

Medium-sized cannon × 7

Armored gun turret x4

Large cannon x 3

5-vehicle formation (Tank AX5) x 3 (appears multiple times) *2



C

Fam, Ciel, Copain



Progre 5-unit formation (Progre CX1 + Progre Ax4) x 0-6 *3

Assault pod x 5 (Assault pod C x 5) x 1 (reinforcement)

Assault pod x3 (Assault pod Cx3) x 1 (reinforcement)

Assault pod x 4 (Assault pod C x 4) x 1 (reinforcement)

Assault pod 6 (Assault pod Cx6) x 1 (reinforcement)

5 tank formation (Tank Ax5) x 3 (appears multiple times) *2



D

Fam, Ciel, Copain



Skret x 1

5 tank formations (5 tanks) x 2 (appears multiple times) *2

3 tank formation (Tank Ax3) x 1 (appears multiple times) *2

3 tank formation (Tank Ax3) x 1 (appears multiple times)





※1 If you have Fam, you will have a 5-plane Dernie formation, and if you have Ciel, you will have Dorad, so as long as there are 2 or more units remaining, Detrois will not appear. If only 1 unit of the relevant unit remains, 1 unit of Detrois will be added, and if they are all shot down, 2 units of Detrois will be added.

*2 Battle results will not appear in places where the route is broken.

*3 This unit will not appear if the remaining number of Dernier 5 units for Fam, Drado for Ciel, or Large Cannon for Copan is 3 or more. For each unit that is reduced, 2 units of Progres 5 units will be added.





104





femme SCENE 4



Scene 4, with its beautiful lake reflected in the sunset, is set in Ventre&#39;s hideout. The key point of this scene is how to protect Inspector Ardi&#39;s armored car from the enemy&#39;s fierce attack as it crosses a long bridge towards the fortress.



-------------------------------------------------- ----------

Defeat the 4-1 Formation Squad



Main Enemies



Dernier 24-plane formation (Dernier AX24) x 1



Special Rules



none





First, aim for the leader&#39;s plane and disrupt the formation.

-------------------------------------------------- ----------

The Derniers have excellent mobility and will form three different formations to counter the Gunner Machines. When they are in formation, attacks will have no effect on any machine other than the leader at the front. If you shoot down the leader, the formation will break and you will be able to damage all of the Derniers. However, even if the formation breaks down once, after a while a new leader will be put in place and the formation will be re-formed. If this happens, you will have to shoot down the leader again.



img-caption

Captain&#39;s Machine



&quot;When enemies are in formation, only the leader&#39;s unit can deal damage.&quot;



img-caption

Even if you try to attack a Dernier from behind while it&#39;s in formation, your attack will be repelled by the invincible formation. Immediately after the mission starts, it&#39;s easier to aim from the side, so try attacking with a fireworks missile, which can shoot down the enemy in one shot.





-------------------------------------------------- ----------

4-2 Defeat the defense forces guarding the fortress



Main Enemies



Dernier 6-plane formation (Dernier BX1 + Dernier AX5) x 3



Special Rules



If you destroy all the Derniers within the time limit, you will receive a bonus.





Defeat the three Dernier units guarding the castle walls!

-------------------------------------------------- ----------

In the second mission of Scene 4, each of the three players is responsible for destroying a different target. Fam is in charge of the Dernier squadrons that fly inside the castle. There are three Dernier units in total, but there are only two at the start of the mission. If you destroy one of them, the last unit will be added. The Derniers move around a lot, so if the enemy moves out of an attacking position, use an active turn to put them back in the line of fire. If you destroy the three units within 3 minutes and 30 seconds, you will receive a bonus prize. If you destroy the enemies early, you will be able to act freely within the time limit. You can also attack enemies inside the castle to earn bounties (see page 110).



img-caption

The Dernier squadron moves at high speed along the side of the castle walls, making them difficult to spot. Switch targets to find them.





105



-------------------------------------------------- ----------

4-3 Protect your armored vehicle from enemy attacks



Main Enemies



5-unit assault pod formation (Assault Pod C x 5) x 1, 3-unit assault pod formation (Assault Pod CX3) x 1, 4-unit assault pod formation (Assault Pod CX4) x 1, 6-unit assault pod formation (Assault Pod CX6) x 1



Special Rules



The operation failed when Ardi&#39;s armored car was destroyed.





Fight off the approaching assault pods

-------------------------------------------------- ----------

Here, you will be guarding Ardi&#39;s armored car as it moves across a long bridge towards the castle. You must use the assault pod to repel the enemies that are targeting Ardi. When the assault pod appears, the bell will ring, so immediately press the button to switch targets. The fireworks missile can shoot down one plane with one shot. It is a good idea to use it when you are far away or when you can&#39;t destroy it with the machine gun in time. If you miss the pod, a hammer pulley will jump out from inside and attach itself to the armored car and start attacking. Immediately lock the hound missile to maximum and fire it to drive away the pulleys. Be careful, because if too many pulleys are attached to the armored car, the power output of the armored car will decrease and it will eventually be dropped under the bridge and the game will be over. When the armored car reaches the unprepared yard, you will move on to the next mission.



img-caption

The damage received by Ardi&#39;s armored car is displayed in the upper left corner of the screen, while its current position and current engine power (speed) are displayed in the lower right corner of the screen.



img-caption

The 7th Detroit unit near the castle gate is dangerous if you leave it alone. Make sure to defeat them before the armored car enters the castle.





-------------------------------------------------- ----------

4-4 Defeat the Skrets while protecting the armored vehicle



Main Enemies



Skret x 1



Special Rules



The operation failed when Ardi&#39;s armored car was destroyed.





When the enemy climbs the wall, just attack the pistons.

-------------------------------------------------- ----------

The walking tank, Scret, climbs up the wall and targets Ardi&#39;s armored car. If you destroy all the pistons on each of the four legs, you can drop it from the wall. And while Scret is on the ground, Ardi&#39;s armored car will not be targeted by the enemy. You can attack the pistons with a machine gun, but it&#39;s easier to aim at them from a long distance with a single firework missile. If you want to know which pistons you haven&#39;t destroyed, press the 4 button to match the target icon with the remaining pistons and check. Scret&#39;s weak points are the command tower on the front and the three engine rooms on the back. If you drop Scret from the wall, it will flip over, so after destroying the command tower on the front, let it climb up the wall and drop it, then attack the engine room on the back. Both the command tower and the engine room have high durability, so the maximum attack of the cross missile is effective. You can attack the control tower and engine room while Skret is on the wall, but since Ardi&#39;s armored vehicle will be targeted during that time, limit attacks to weak points only when the enemy is on the ground, and once it has climbed the wall, prioritize destroying the pistons in its legs to bring it down.



img-caption

Pistons will respawn if destroyed. If you have four, the Skret will start climbing the wall.



106



Cielciel SCENE 4



Scene 4 features a variety of missions, including escort missions and ground attacks. The powerful and well-equipped battleship Dorad makes its first appearance, but Ciel must face it for two more days.



-------------------------------------------------- ----------

Defeat the 4-1 Formation Squad



Main Enemies



Dernier 24-plane formation (Dernier AX24) x 1



Special Rules



none





You can also save your firework missiles.

-------------------------------------------------- ----------

Formation&#39;s Derniers can be easily shot down by attacking them from the side with fireworks missiles, but there is also a way to defeat them without using fireworks missiles. That is, slow down the enemy&#39;s movements with the Hound Missiles and then kill them with the machine gun. Only the Hound Missiles can hit the leader&#39;s machine from any angle, so all you have to do is move around to the side or front and hit them with the machine gun. In order to extend the duration of the Hound Missiles, it is a good idea to lock on to the maximum before firing.





img-caption

If you hit an enemy formation with a Hound missile, its speed will slow down and its maneuverability will be significantly reduced, making it easy to get to the side or front.





-------------------------------------------------- ----------

4-2 Defeat the Drad guarding the fortress



Main Enemies



Dorado x 2



Special Rules



If you defeat the Dorados within the time limit, you will receive a bonus.





Sink two Dorados before the time is up!

-------------------------------------------------- ----------

In this mission, you will fight two battleships called Dorados, and if you can sink them within 3 minutes and 30 seconds, you will receive a bonus of 1 million. The red parts of the upper surface of the Dorado&#39;s hull are protected by thick armor, so no attack will work on them. The weak point is the white part of the lower part of the hull. However, even if you lock on to the lower part of the hull and attack with a cross missile, you may end up hitting the fin turrets and wings on the side, the float turret on the lower part, or the pistol turret. It is a good idea to use firework missiles and machine guns to destroy all the enemy&#39;s weapons that are in the way. After destroying the fin turrets and pistol turrets, it should be easier to defeat the enemy by attacking them from behind using heavy bullets instead of cross missiles. Please note that attacking the wings attached to the rear of the side of the hull will not cause any damage at all. The two Dorados move slowly and land when they are in a position to sandwich the bridge on both sides. If Ardi&#39;s armored car comes nearby in this state, the armor will surely be destroyed by the ferocious attack of the Dorados.

Your car will be destroyed, so you definitely want to sink it before that happens.





img-caption

Fin base

When attacking the bottom of the ship, destroy the fin turrets if they are in the way. You can destroy them by attacking the base of the turret, not the turret itself that fires the bullets.



img-caption

Lower hull

To inflict damage on the main body, you must ensure that your attack hits the white part of the hull.



107



-------------------------------------------------- ----------

4-3 Protect the armored vehicle from enemy attacks



Main Enemies



5 Assault Pods (Assault Pod CX5) x 1, 3 Assault Pods (Assault Pod CX3) x 1, 4 Assault Pods (Assault Pod CX4) x 1, 6 Assault Pods (Assault Pod CX6) x 1



Special Rules



The operation failed when Ardi&#39;s armored car was destroyed.





Be careful not to overtake the Assault Pod

-------------------------------------------------- ----------

In this mission, you will work with Fam and Copan to escort Ardi&#39;s armored car. If there are any Dorados that you were unable to defeat in 4-2 at the start of this mission, you must first destroy them. After defeating the Dorados, you will have to intercept the assault pods approaching the armored car (see P105, Fam 4-3). If you overtake the assault pods, you will have a hard time returning to your attacking position, so it is a good idea to shoot firework missiles at each one from a long distance to destroy them for sure. If you are about to overtake the assault pods, stop them with the steam brake and attack the pods with heavy bullets. As with Fam, Detrois&#39; troops are deployed in the trench near the front yard. Don&#39;t forget to destroy them before Ardi&#39;s troops reach the front yard to ensure the safety of the armored car.



img-caption

It&#39;s also important to remember where the blaster pods appear, so stay close to your armored vehicle and be ready to intercept them at any moment.



img-caption

If you have the time, you can destroy nearby tank corps to create chains and earn bounties and bonus multipliers.





-------------------------------------------------- ----------

4-4 Defeat the Skrets while protecting the armored car



Main Enemies



Skret x 1





Special Rules



The operation failed when Ardi&#39;s armored car was destroyed.





It is important to keep damage to police aircraft to zero

-------------------------------------------------- ----------

In order to not only clear the level but also secure a certain amount of bounty money, it is important to defeat the enemy as quickly as possible when fighting with Scret. If you take too long, the six Ordols will be shot down one after another, and 150,000 bounty money will be deducted for each one you lose when you clear the level. In order to reduce damage to the scouts, focus your attacks on the enemy&#39;s weak points and defeat them early. Even if Scret climbs the wall, if the armored car has enough durability, it is a good idea to continue attacking the weak points of the control tower and engine room instead of the pistons. Another method is to destroy the pistons that have just been repaired while Scret is on the ground when it falls to the ground. If all four pistons are destroyed, Scret cannot climb the wall. By taking advantage of this, once you have destroyed all the parts of the weak points on one side, you can let it climb the wall once, then drop it, flip it over, and destroy the weak points on the other side, which will save you a lot of time before defeating it.



img-caption

If you lock on and hit the control tower or checkpoint with a flame missile, you can also damage some pistons.



img-caption

If you&#39;re trying to kill them without letting them climb the wall, keep an eye on the target display, as the icon will reappear when the piston is repaired.





108



Copain SCENE 4



Scene 4 of Copan has the most difficult mission of all, the mission to destroy the cannons at Ventre House. Make sure you remember the location of each cannon and try not to waste your time.



-------------------------------------------------- ----------

Defeat the 4-1 Formation Squad



Main Enemies



Dernier 24-plane formation (Dernier AX24) x 1



Special Rules



none





Aim for the Progress Formation and earn prize money

-------------------------------------------------- ----------

The way to fight the Derniers in the formation is the same as with Fam and Ciel, and the most effective way is to shoot firework missiles from the side of the enemy formation. However, if you want to earn bounties, it&#39;s better to shoot down a certain number of Derniers and increase the bonus multiplier, then attack the Progres formation that appears later. This Progres formation has a large number of aircraft, with 8 aircraft in it, so you can earn bounties by shooting them down all at once with rolling.





img-caption

Aim at the leader in the center of the Progress formation and roll from the front. The faster your ship is, the more hits you&#39;ll get.





-------------------------------------------------- ----------

4-2◎ Destroy the artillery batteries guarding the fortress



Main Enemies



Special rules: Small gun turrets x 19, medium gun turrets x 7, armored gun turrets x 4, large gun turrets x 3



Special Rules



If you destroy all the turrets within the time limit, you will receive a bonus.





One of the most difficult missions! Will it be a struggle to complete?

-------------------------------------------------- ----------

Copan&#39;s opponents here are the artillery turrets at Ventre House, and if they can be destroyed within 3 minutes and 30 seconds, he will receive a bonus of 1 million. This may seem easy since the enemies do not move, but since there are so many of them, it will be difficult to achieve unless you establish a route to destroy them. For now, destroying the artillery turrets around the bridge will ensure the safety of Ardi&#39;s armored car, so it is best to destroy just those artillery turrets first (see the diagram on the right). Please refer to pages 110-111 for how to destroy all the artillery turrets and receive the bonus.



Armored gun turret



This cannon cannot be attacked from the front. Use Hound Missiles to stop its movement and attack it from behind.



Large gun emplacement



A turret that can only be destroyed by chaining. Chain another turret or tank nearby.



&lt;Batteries around the bridge&gt;

To ensure Ardi&#39;s safety, destroy only the artillery shown here. To defeat the large artillery on the left side of the castle, destroy the α artillery with about 3 minutes left, then destroy the β tank. After that, the tank will arrive at the y position, and you will be able to destroy the large artillery.



Small gun battery-blue

Medium-sized gun turret - green

Armored turret - orange

Large cannon - yellow

Tank Corps-Little-Red





109



-------------------------------------------------- ----------

4-3 Protect the armored vehicle from enemy attacks



Main Enemies



5 Assault Pods (Assault Pod CX5) x 1, 3 Assault Pods (Assault Pod CX3) x 1, 4 Assault Pods (Assault Pod CX4) x 1, 6 Assault Pods (Assault Pod CX6) x 1



Special Rules



The operation failed when Ardi&#39;s armored car was destroyed.





Earn enough bounty money to avoid damaging your armored vehicle

-------------------------------------------------- ----------

The objective of this mission is to protect Ardi&#39;s armored car. However, even if the armored car takes some damage, you can prioritize bounty hunting. If you can repel the second wave of assault pods, in most cases the armored car will reach the gate even if you leave it alone. What you want to attack here is the enemy tank unit that you can get a chain from. If you circle around the tank unit&#39;s spawn point (see page 111) and defeat the enemy the moment they appear, you can earn bounties efficiently. If you leave the escort of the armored car to Ciel and Fam, you will be fine, but depending on the situation, you may miss the pod and the armored car may be attacked by many Pooles. Always keep an eye on the output of the armored car, and if it is below 70%, it would be safer to eliminate the Pools with a hound missile. Note that the Detrois troops that appear near the gate in Ciel and Fam&#39;s mission will not appear in Copain.





img-caption

If you see a formation of Progressives gathered together in the sky, dive into them and roll! If all goes well, you can score a lot of hits.



img-caption

Even if the assault pods are stuck together, you still have a chance. If you hit them with a maximum lock firework missile, the prize money is high, so you can make a good profit.





-------------------------------------------------- ----------

4-4 Defeat the Skrets while protecting the armored car



Main Enemies



Skret x 1



Special Rules



The operation failed when Ardi&#39;s armored car was destroyed.





Aim for a massive hit with maximum firework missile lock

-------------------------------------------------- ----------

Here, we will introduce how to defeat Scret while earning bounties. The first thing you want to do is hit the command tower with a maximum lock fireworks missile when Scret is facing up. It will hit 5 twin machine guns x 4 and 4 pistons, which will be 24 hits. If you hit the fireworks missile when some of the twin machine guns or pistons have been destroyed, the bounty will drop significantly, so attack when all parts are complete. The chance is right after the battle with Scret begins. If Ardi&#39;s armored car is about to reach the castle gate in the previous mission, it is a good idea to move to the sky above the garden in advance and prepare to attack the command tower immediately after the battle begins. Even after the command tower is gone, you can still earn this amount as long as Scret is facing up. If you hit the engine room or secondary gun turret with a maximum lock fireworks missile from the horizontal direction of Scret, you will be able to get a considerable number of hits.



img-caption

If you aim for the command tower, you can get a big hit. Try not to damage the command tower as much as possible and do this multiple times.



img-caption

It is also important to maintain the horn multiplier. Destroy as many tanks as possible in the front yard to add to the bonus count.





110



How to earn bounties in SCENE4



There are many cannons installed in the Ventre House. During the 4-2 mission, Fam and Ciel can earn even more bounty money by destroying the cannons installed in various places in the castle before Copan does. However, Copan&#39;s mission, in which the objective is to destroy all the cannons, is extremely difficult, and it is much harder for her to earn bonuses than the other two. Here, we will explain Fam and Shell&#39;s bounty hunting and Copan&#39;s method of destroying all the cannons using a map of the Ventre House. The circled numbers in the explanation correspond to the positions of the cannons on the map on the right page.



Fam



If you fight well, Fam can have more than 1 minute 30 seconds left after destroying all enemies in the 4-2 mission. Once Dernier is gone, you should destroy the castle&#39;s artillery to earn bounties. Among the artillery groups, Tank Corps C, Large Artillery 2 + Tank Corps A-1, Medium Artillery 25 + Tank Corps B-1, and Medium Artillery 7® + Tank Corps C have a large number of chains and a large bounty, so you should definitely destroy them.



img-caption

If you see tank corps A-2 lined up near large artillery 3, try to destroy the artillery. Your chance is right before the time limit ends.



Ciel



When fighting the Dorado, destroy the left and right fin and float turrets to earn bonus multipliers. After sinking the first Dorado, hurry to the castle and earn bounties until about 1 minute 20 seconds remain. The turrets you should aim for are the same as those for Fam. After that, attack the second Dorado that is moving near the bridge. If you destroy the fin and float turrets before the Dorado hits the water, it should be easier to sink it.



img-caption

First, attack the Dorado from behind with a maximum lock on firework missile, then aim the machine gun at the base of the fin turret to destroy it.



Copan



Unlike the other two, Copan does not have the luxury of attacking other people&#39;s targets in this scene. However, if he can destroy all the turrets properly, he will receive a considerable amount of prize money in addition to the 1 million bonus. The most important point when destroying the turrets is the order of attack. The turrets are distributed over a fairly wide area inside the castle, so if you do not destroy them in the most efficient order possible, you will not have enough time just to move around. The diagram on the right is the recommended route for destroying the turrets in this book. Please refer to the order of destroying the turrets shown in circles on the right page. Note that this order of destroying the turrets is different from the order of the turrets displayed when switching targets. In this scene, it is better not to rely on switching targets and to mainly use the behind-the-scenes viewpoint. It is important to remember all of the terrain of Ventre House and the positions of the turrets.



&lt;Copan&#39;s recommended route to defeat the artillery&gt;



111



Small gun battery-blue

Medium-sized gun turret - green

Armored turret - orange

Large cannon - yellow

Tank Corps-Little-Red

Tank spawn point



Tank Corps C will appear from Point C, cross the bridge into the front yard, and line up next to Large Cannon 26 (it takes about 30 seconds from appearance to the destination). If the tank corps is destroyed while crossing the bridge, the bridge will fall and no more tanks will appear from Point C.



Tank Corps A appears from Point A and lines up next to the 225 medium gun turret via Route A-1 on the wall (takes about 1 minute from appearance to destination). However, if the 12 or 1 gun turrets are destroyed, Route A-1 becomes impassable, so the tank turret will take Route A-2 and line up next to the 33 gun turret (takes about 1 minute 30 seconds from appearance to destination).



Tank Corps B appears from Point B and lines up next to the 228 medium gun turret via route B-1 on the wall (time required from appearance to destination is approximately 1 minute 30 seconds). If turrets 2 or 3 are destroyed, route B-1 becomes impassable, so the tank turret will line up next to turret 1 via route B-2 (time required from appearance to destination is approximately 1 minute 35 seconds).



112



SCENEV

Decisive Battle! Sky Fortress Battleship

3:00am



&quot;Ventre under arrest!!&quot;



Ventre was waiting in the great hall on the top floor of the Ventre House. He was sitting motionless at the piano, but the clerks, under Ardi&#39;s orders, arrested him. But something seemed strange. At that moment, the Dragon&#39;s Ring in the great hall rang. Ardi picked up the receiver and heard the voice of Ventre, who was supposed to be there.



&quot;That&#39;s interesting.

I&#39;m looking forward to it.&quot;



What Ardi thought was Vantor and caught was actually a Vantor doll. The challenging message was also recorded on a gramophone. The real Vantor was in the Rives Museum. The plan was to lure the police to their hideout and then attack the museum. Ardi was outwitted by Vantor and slammed the phone down. Outside the window, a large moon was beginning to rise.



After hearing the story from Ardi, Ciel and the others were shocked by Vantle&#39;s actions of stealing the entire museum. However, they must take back the museum, in the name of Reeve&#39;s gunner. The three of them rushed to Vantle&#39;s aerial battleship, which the police were pursuing.



■ The giant battleship Grand Magasin floats in the ocean. In the command tower, Vantle gazes fascinated at the perpetual motion machine he has acquired. Then, Poulet, who is on lookout, informs him that the gunners and police are pursuing them. At Vantle&#39;s command, the battle ships take off one after another. At last, the final battle begins.



&quot;Let&#39;s go, guys!&quot;



113







Mission Chart

-------------------------------------------------- ------

Fam



Number of occurrences: A

5-1

Remove the connecting parts and take back the museum



Number of occurrences B

5-2

Defeat the turret to repair the connection parts



Number of occurrences C

5-3

Destroy the coupler and dismantle the Grand Magasin



Number of occurrences D

5-4

Defeat the enemies and protect the shell





Number of occurrences E



5-5

Successfully execute a coordinated attack with your allies



5-6

Attack the perpetual motion machine and defeat the mechanical soldiers.



If you succeed in time



Number of occurrences F



5-7

Defeat Vantor







Ciel



Number of occurrences: A

5-1

Defeat the attacking ships



Number of occurrences B

5-2

Defeat the Drado and protect Fam from the mechanical soldiers. Defeat the attacking battle craft.



Number of occurrences C

5-3

Defeat Rival



If successful

5-3&#39;

Destroy the culprit and dismantle Grand Magasin





If you fail 5-3 or if you succeed 5-3&#39;



Number of occurrences D

5-4

Destroy the stubborn command tower



If successful

5-4&#39;

Break the parts and destroy the engine.





Number of occurrences E



5-5

Successfully perform a coordinated attack with a teammate



5-6

Attack the perpetual motion machine and defeat the mechanical soldiers.



If you succeed in time



Number of occurrences F



5-7

Defeat Vantor



Copan



Number of occurrences: A

5-1

Defeat the attacking ships



Number of occurrences B

5-2

Defeat the Dorados and protect Fam. Defeat the attacking ships.



Number of occurrences C

5-3

Destroy the coupler and dismantle the Grand Magasin



Number of occurrences D

5-4

Break the parts and destroy the engine.





Number of occurrences E



5-5

Successfully execute a coordinated attack with your allies



5-6

Attack the perpetual motion machine and defeat the mechanical soldiers.



If you succeed in time



Number of occurrences F



5-7

Defeat Vantor





Appearing enemy forces

-------------------------------------------------- ------

A



Fam

Progre 5-unit formation (Progre Cx1 + Progre BX4) *1

5 Progressives (Blogre C x 1 + Blogre AX2 + Blogre BX2) *1

Progre 5-unit formation (Blogre CX1 + Progre Ax4) *1





Ciel, Copain



3 Progressives (Blogre Ax3) x 6 (appears multiple times)

5 Progres in formation (1 Brogre C + 4 Progres BX) x 2 (appears multiple times)



B



Fam

Museum Captured Cannon x1

5-unit Brogre squadron (Progre C x 1 + Brogre B x 4) x 2 (appears multiple times)





Ciel, Copain



Dorado x1

Vainqueur Garde (only appears with Ciel, indestructible)

5-unit Brogre squadron (Progre CX1 + Progre B x 4) x 4 (appears multiple times) *2



C

Fam, Ciel, Copain



Dernier 4-plane formation (Dernier BX4) x 1 (appears multiple times)

5-unit Brogre formation (1 Progre C + 4 Progre A) x 2 (appears multiple times)

5 Progres in formation (1 Progre C + 4 Progre A) x 1 (can appear multiple times) *3

3 tank formation (Tank B x 3) x 4 (appears multiple times)

Phantom x 1 (only Ciel appears)



D

Fam, Ciel, Copain

Balene B x 1 (appears multiple times)

Dernier 5-plane formation x 1 (appears multiple times, Copan only 5-plane formation x 4)

Progre 5-plane formation x 4 (appears multiple times, Copan only 5-plane formation x 1)

3 tank formations (Tank Day x 3) x 3 (appears multiple times)

Tank (Tank B x 1) x 4 (can appear multiple times, only Copan has a 3-car formation x 4)



E

Fam, Ciel, Copain



Vaincourt Garde x 1

Tank (Tank B x 1) x 6 (appears multiple times)

Progre 5-unit formation (Blogre CX1 + Blogre BX4) /

Ternier 4-plane formation (Dernier B x 4) x 4 (appears multiple times) *3



F

Fam, Ciel, Copain



Balene Ventre spec x 1

Progressive 3-machine formation (Blog Ax3) x 10



Blogre 3-unit formation (Blog Ax3) x 10

*1 A total of 6 squadrons will appear (can appear any number of times).

*After shooting down 2 Dorados, the number of squadrons will increase to 6 x 5 aircraft.

*3 It will appear when you destroy even one of the Grand Magasin&#39;s couplers.

*4 A squadron of 5 Progres and a squadron of 4 Derniers will appear, totalling 4 units.





114





Femme SCENE 5



Your opponent in the final battle is the Grand Magasin, the gigantic battleship of Vantre. Work with your allies to dismantle this battleship. After that, a final battle with the enhanced mechanical soldier, Vantre Garde, awaits.



-------------------------------------------------- ----------

5-1 Remove the connecting parts and take back the museum



Main Enemies



Museum connection parts x 8



Special Rules



none





Work with police planes to retake the museum

-------------------------------------------------- ----------

First, recapture the museum connected to the garden at the very end of Grand Magasin. The museum is secured in place by eight museum connection parts. You can recapture the museum by destroying all of these connection parts. However, if you destroy about four connection parts, the museum capture turret installed in the garden will activate and attack. If you defeat the museum capture turret, you can resume destroying the connection parts.



img-caption

Immediately after the mission begins, you can see the museum from a distance. The board connecting the museum to the outer ring is the connecting part.



-------------------------------------------------- ----------

5-2 Defeat the turret that is repairing the connection parts



Main Enemies



Museum Captured Cannon x 1



Special Rules



Defeating the Museum Capture Battery will start a different mission.





If you don&#39;t take out the guns quickly, it&#39;s dangerous!

-------------------------------------------------- ----------

The Museum Captured Cannon attacks your ship while moving at high speed on the rails outside the garden. Furthermore, this cannon has the ability to repair broken connection parts, so no matter how many parts you break while the cannon is moving, they will be repaired immediately. In order to recapture the museum, you must first destroy this cannon. However, since the cannon cannot withstand machine gun attacks, you must attack it using cross missiles. You should be able to destroy the Museum Captured Cannon by firing three cross missiles that are locked on at maximum speed and detonating them.



img-caption

The museum captured cannon is a dot in its entirety, so if you lock on to it with a cross missile and fire it, you can be sure to inflict damage.



img-caption

The museum capture guns attack the outside of the garden with Gatling guns and the inside with Bistor guns. There are four quadruple Gatling guns (16 guns), so they are powerful and numerous, so you want to avoid attacking from the outside of the museum if possible.



115



-------------------------------------------------- ----------

5-3 Destroy the coupler and dismantle the Grand Magasin



Main Enemies



Grand Magasin x 1



Special Rules



none





Destroy all 7 couplers

-------------------------------------------------- ----------

Once you&#39;ve recaptured the museum, it&#39;s time to begin your attack on Grand Magasin. Here, you&#39;ll need to destroy the couplers that connect each block of Grand Magasin and dismantle them. You can attack the couplers directly, but they are very durable and not easy to destroy. However, each coupler is equipped with two rows of six machine gun turrets, and by chaining these machine gun turrets together, you can deal great damage to the couplers.



img-caption

Coupler

If you break the couplers, each section will be detached from the main body. If you break all the couplers, only the control section and engine section will remain.







-------------------------------------------------- ----------

5-4 Defeat the enemies and protect Ciel



Main Enemies



Dernier 4-plane formation (Dernier BX4) x 1,

Progre 5 (Progre AX5) x 4



Special Rules



If Ciel is shot down, the operation will fail.





Wipe out the enemy formations surrounding Ciel!

-------------------------------------------------- ----------

Once all the couplers are destroyed, numerous artillery turrets will appear from the control tower. Ciel is in charge of attacking the control tower, so Fam will be his guard here. A large number of Progres and Derniers will be chasing Avenir, so shoot them down to save Ciel. Ciel&#39;s danger level is displayed in the upper left of the screen. If this number reaches 100%, Avenir will be shot down and the game will be over, so be careful.



img-caption

When the alarm bell goes off, Shell is being attacked by an enemy squadron. Press the 4 button immediately to go to Shell&#39;s rescue.



img-caption

If the enemy formation is crowded, try attacking with maximum lock-on of the Fireworks Missiles. You can get a lot of hits.



&quot;Pay attention to Ciel&#39;s danger level in the top left of the screen!&quot;





116





Cielciel SCENE 5



In Ciel&#39;s scene 5, strong enemies such as Drado and Vaincourt appear one after another. Rival also appears again. There is a risk of running out of fuel at the end of the scene, so you must play efficiently.



-------------------------------------------------- ----------

5-1 Defeat the attacking ships



Main Enemies



Progre 3-plane formation (Progre AX3) x 3



Special Rules



none





Let&#39;s just defeat the Progress squadrons

-------------------------------------------------- ----------

Ciel is first tasked with intercepting the Progress squadrons that launch one after the other from the tip of Grand Magasin. Each Progress squadron is made up of three aircraft. Even if one squadron is wiped out, they will be replenished for a while so that three squadrons always appear in the area, but after a certain amount of fighting, new squadrons will no longer appear. If you wipe out the Progress squadrons remaining in the area at this time, Dorad and Vaincour will appear and you will move on to the next mission.





img-caption

When fighting the Progressives, it&#39;s a good idea to mainly use the machine gun and save your optional weapons for later battles.





-------------------------------------------------- ----------

5-2 Defeat the Drado and protect Fam from the mechanical soldiers / Defeat the attacking battle craft



Main Enemies



1x Dorado, 1x Vaincourt Garde



Special Rules



If Fam is shot down, the operation will fail.





Attack while evading fierce anti-aircraft fire!

-------------------------------------------------- ----------

The Dorado that appears here has the mechanical soldier Vainqueur Garde hanging from it, and is trying to hinder Fam as he is recapturing the museum. If you leave the Dorado alone, the danger level of Fam displayed in the upper left corner of the screen will increase, and when it reaches 100%, Blanche will be shot down and the game will be over. Sink it as quickly as possible. Attacking Vainqueur is ineffective, so you should focus your attacks on the Dorado. The Dorado&#39;s weak point is the white bottom of the hull, just like in Scene 4, but this time, the float and pistol turrets have been removed because the parts to hang the mechanical soldier have been attached. It will be easier to hit the bottom of the hull with a maximum lock of the cross missile from the front, slightly below, of the Dorado. The enemy&#39;s attacks are tough, and Vainqueur&#39;s Gatling gun will shoot you down in an instant if you get in the line of fire, so it is dangerous if you do not boost or power slide near the Dorado. After destroying the Dorado, you&#39;ll be returned to Mission 5-1, where you&#39;ll continue fighting Progres until Fam retakes the museum.



img-caption

You can also roll while power sliding. Hold down 0 or Men while power sliding to get closer to the enemy.



img-caption

Lower hull





117



-------------------------------------------------- ----------

5-3 Defeat Rival/Destroy the coupler and dismantle the Grand Magasin



Main Enemies



Phantom x 1



Special Rules



If you destroy the Phantom within 3 minutes, a different mission will start.





Kill them quickly and join in dismantling the Grand Magasin.

-------------------------------------------------- ----------

Once Fam has recaptured the museum, she and Copan begin dismantling Grand Magasin. However, Ciel is suddenly challenged to a fight by Rival. Ciel is given three minutes. The fighting method should be the same as in Scene 3. If you can shoot down the Phantoms early, Ciel will be able to join in dismantling Grand Magasin (see P115 Fam 5-3 and P119 Copan 5-3). If you want to earn the bounty money, it is important to spend as little time as possible fighting Rival.



img-caption

If you beat Rival in Scene 3, a modified and more powerful Phantom will appear. Its durability has increased.



-------------------------------------------------- ----------

5-4 Destroy the sturdy control tower / Destroy the parts and destroy the engine room



Main Enemies



Grand Magasin Command Department x 1



Special Rules



If you destroy the control tower within the time limit, a different mission will begin.





Be careful of the number of remaining cross missiles

-------------------------------------------------- ----------

After dismantling Grand Magasin, Ciel will be in charge of attacking the command tower. Since the command tower can only be damaged by chains, it is a good idea to attack the cannon equipped under the command tower with only one cross missile. However, since a large number of cross missiles will be needed in the battle with the mechanical soldiers that follows, it is recommended to keep at least 30 in stock. If you are worried about the number of cross missiles in stock, you will need to be creative, such as defeating the tank unit near the command tower to replenish them or destroying the cannon with heavy bullets to save them. If you clear this mission in a short time, you will help destroy the engine room of Copan (see Copan 5-4 on page 119). Also, if the fuel gauge is completely red at the end of the mission, there is a risk that you will run out of fuel in the battle with the Vaincourt Garde or the Vantre ship immediately after. If you are worried about the remaining fuel, avoid unnecessary actions and destroy the command tower as quickly as possible.



img-caption

Control Tower Machine Gun Battery A

If you use the bottom command tower machine gun turret A as the starting point of the chain, you can make a total of 9 chains.



img-caption

For the command tower gun, you want to aim accurately at the lowest gun. Lock on to it once and fire a cross missile.



&quot;Make sure you aim for the bottom turret to get the biggest chain!&quot;



From 5-5 onwards, see P122



118



Copain SCENE 5



If you&#39;re focused on bounty hunting, Grand Magasin is packed with parts you can&#39;t miss. In this scene, the key is to destroy the parts before your allies destroy them.



-------------------------------------------------- ----------

5-1 Defeat the attacking ships



Main Enemies



Progressive 3-plane formation (AX3) x 3



Special Rules



none





Aim at the enemy as soon as they take off to earn bonus multipliers.

-------------------------------------------------- ----------

Copan will intercept the Progres departing from the rear of Grand Magasin. From now on, in order to earn more prize money, it is important to earn bonus counts as efficiently as possible. Approach the enemy ship while shooting down the Progres, and when you get close to the launch point, retreat at full speed, and then shoot down the Progres that have been dispatched from the enemy ship. Repeat this movement to earn bonus multipliers. You will also fight the Progres after the end of mission 5-2.





img-caption

You can defeat enemies efficiently by doing somersaults back and forth around the launch area. Create a pattern of progressive formations.





-------------------------------------------------- ----------

5-2 Defeat the Drado and protect Fam / Defeat the attacking ships



Main Enemies



Dorado x 1



Special Rules



If Fam is shot down, the operation will fail.





The Dorados were sunk immediately after their launch.

-------------------------------------------------- ----------

After fighting Progres for a certain amount of time, the Dorado will launch from Grand Magasin Command Center towards the museum. While the Dorado is there, the danger level of Fam displayed in the upper left corner of the screen will increase, and if this number reaches 100%, Blanche will be shot down and the game will be over, so you must hurry and sink the Dorado. However, there is an easy way to sink this Dorado. When the enemy ship launches, destroy the fuel tank hanging above the Dorado&#39;s head and it will fall. If you hit the Dorado with this fuel tank, you can deal damage. The damage is very great, and if you hit two fuel tanks, you will be able to sink the enemy for sure.



img-caption

The faster you sink the Dorados, the longer the mission to shoot down the Progres will take. Be sure to sink the fuel tanks.



img-caption

Once it launches, the only thing you can do is damage it bit by bit with machine guns and fireworks missiles. I&#39;d like to sink it somehow...



&quot;It&#39;s easier if you use firework missiles to take down the fuel tanks!&quot;





119



-------------------------------------------------- ----------

5-3 Destroy the coupler and dismantle the Grand Magasin



Main Enemies



Grand Magasin x 1



Special Rules



none





The best earning point in the entire series!

-------------------------------------------------- ----------

The original objective of this mission is to destroy the couplers that connect the various parts of the Grand Magasin (see P115 Fam 5-3). However, you cannot earn a large bounty just by destroying the couplers. The Grand Magasin is packed with parts that can create chains and hits, so you will want to destroy as many of them as possible. It is also important to carefully determine which part to destroy first. Please refer to P122-123 for the key parts and how to earn bounty money. This mission offers the highest bounty money of the entire game. If you have increased the bonus multiplier in the battles leading up to it, you can earn tens of millions of bounties here.



img-caption

The machine gun turrets on the sides of the tip and tip attack section can be destroyed with an 8-chain, which will destroy the lower turret as well, adding 9 hits to the total.



img-caption

One thing that&#39;s easy to overlook is the pumpkin bombs on the left and right propulsion sections. When destroyed, they roll forward and destroy the firework missiles, creating a 10-chain.







-------------------------------------------------- ----------

5-4 Destroy the parts and destroy the engine room



Main Enemies



Crank x 8



Special Rules



none





Destroy those troublesome cranks all at once by rolling them.

-------------------------------------------------- ----------

While Ciel attacks the command tower, Copan attacks the engine room below the command tower. The engine room can be destroyed by destroying the eight cranks. However, the cranks are not susceptible to machine gun attacks, and it is difficult to hit them even if you lock on and attack with a pumpkin bomb. If you hit them with a hound missile, their movement will slow down, so it should be easier to hit them by approaching them from above the crank and firing two pumpkin bombs without locking on. You can also destroy the cranks by boosting to the limit and then repeatedly rolling to catch the tank below the engine room. If you are having trouble here, Ciel will come and destroy the cranks using a heavy bullet. If it doesn&#39;t work, you can leave it to Ciel.



img-caption

Switch your viewpoint to behind-the-scenes, and when you&#39;re directly above the crank, dive down and fire a pumpkin bomb straight down to hit it easily.



From 5-5 onwards, see P122





120





Femme Ciel Copain SCENE 5



After clearing mission 5-4, each character will fight against the mechanical soldier Vainqueur Garde, who has been powered up by having a perpetual motion machine built into him. To defeat the mechanical soldier, it is important for the three of them to coordinate their attacks.



-------------------------------------------------- ----------

5-5 Successfully perform a coordinated attack with your allies



Main Enemies



Vaincourt Garde (first stage of rampage) x 1



Special Rules



none





Be careful how you hit your cross missiles.

-------------------------------------------------- ----------

In order to defeat the mechanical soldier Vainqueur Garde, you must first have a successful coordinated attack by the three of them to expose the perpetual motion machine. Fam and Ciel have to do the same action during the coordinated attack. First, Copan will stop the mechanical soldier&#39;s movement with a pumpkin bomb, so fire a cross missile with maximum lock. However, do not detonate it at this stage. Shell and Fam should hit the enemy with a total of six cross missiles with maximum lock (basically three per person), and then detonate them all at once with the machine gun. If a cross missile is shot with maximum lock, it will self-destruct about 18 seconds after hitting the enemy. Since detonation must be done all at once, all cross missiles, including those shot by allies, must be shot within 18 seconds. If even one cross missile self-destructs before detonation, or if all cross missiles cannot be detonated at once, the attack will be invalid and you will have to start again. Also, if too much time has passed since the pumpkin bomb hit the mechanical soldier, the mechanical soldier will start moving again, and the attack up to that point will be invalid. If you run out of Cross Missiles during a mission, destroy the tanks on the deck to replenish your Cross Missiles. You can get 3 Cross Missiles per tank.





&quot;If you fire a cross missile directly above the mechanical soldiers, they will explode easily!&quot;



img-caption

Cross missiles must hit their target six times before they can be detonated. When it&#39;s time to detonate them, your teammates will give you instructions.





Don&#39;t miss the timing to detonate

-------------------------------------------------- ----------

Copan&#39;s combo attack is easier than that of Ciel or Fam. First, hit Vainqueur with the pumpkin bomb&#39;s maximum lock. The mechanical soldier will stop moving, and Shell and Fam will start firing cross missiles. After six hits, Copan can detonate them with the machine gun. It&#39;s a good idea to fly around the mechanical soldier and wait so that you can launch a machine gun attack as soon as your allies give you the command to detonate the cross missiles.



img-caption

To hit the target with the pumpkin bomb, you&#39;ll want to get close and fire it. Dodge the machine soldier&#39;s attacks with a power slide.







121





-------------------------------------------------- ----------

5-6 Attack the perpetual motion machine and defeat the mechanical soldiers



Main Enemies



Vaincour Garde (Riding stage 2, Rampage stage 3) x 1



Special Rules



If you defeat Vainqueur Garde within 150 seconds, a different mission will begin.





Counter various attacks with power slides

-------------------------------------------------- ----------

If the combined attack is successful, the perpetual motion machine installed on the Vainqueur will be exposed, and the second stage of the run will begin. To stop the mechanical soldier, this perpetual motion machine must be destroyed. Maximum locking of cross missiles or pumpkin bombs is effective. Unlike the first stage, where the robot is stopped for a long time, the second stage will have a variety of attacks. Of these, the one to watch out for is the highly homing spear missile. Unlike the Triborn, you will take damage the moment it hits, so if it comes flying, dodge it with a power slide. If you destroy the perpetual motion machine, Vainqueur will enter the third stage of rampage, and in this state you will need to destroy the perpetual motion machine again. In the third stage of rampage, the propeller thrower, which rips off the propeller on the deck and throws it, is a threat. When the propeller flies close to you, you will hear a loud flying sound, so power slide immediately.



img-caption

The propellers thrown by the rampaging mechanical soldier in its third stage can be destroyed by aiming them at the front from a long distance and firing machine gun fire at them.



img-caption

The mechanical soldier in the third rampage stage will attack anything around it. It will attack with a propeller twice and defend with a pipe four times.



img-caption

For Probef, pull it close and power slide it. If you ram into Probef while it&#39;s invincible, you can destroy it.





-------------------------------------------------- ----------

5-7 Defeat Vantor



Main Enemies



Balane x 1



Special Rules



Defeating Balaine earns you a bonus





If you defeat Vaincour quickly, you&#39;ll get a reward mission.

-------------------------------------------------- ----------

If you can clear the series of battles against the Vainqueur Garde in missions 5-5 and 5-6 (rampage stage 1 to rampage stage 3) within 180 seconds, you will be given the additional mission of discovering the escaping Vainqueurs and attacking the Vainqueur ship. The red Balene (Vainqueur ship) will not attack you at all, but if you sink this battleship you will receive a bonus prize of 2 million. This is your last chance to earn money, so it&#39;s a good idea to fight using optional weapons without hesitation.



img-caption

The Wantl ship is loaded to capacity and slow to move. I hesitate to attack, but I must be ruthless.





122





How to earn prizes in Scene 5

-------------------------------------------------- ----------

The mission where you can earn the most prize money in the entire game is the Grand Magasin Dismantling Mission. It is no exaggeration to say that most of the total prize money you will earn upon clearing the mission will be earned in this mission in Scene 5. However, there is a condition to aiming for a large prize money here. That is, you must increase the bonus multiplier as much as possible before the dismantling mission begins. Also, since the bonus multiplier increases even further during the dismantling mission, try to postpone attacks on parts with a high number of chains or hits as much as possible, and try to destroy them once the bonus multiplier has increased.





Fam

-------------------------------------------------- ----------

In the first mission, Fam destroys the museum connection parts, but if you continue the mission as is, you will not be able to increase the bonus multiplier that much. Aim for the formation of five Progres flying nearby and keep earning bonus counts. However, if you spend too much time here, you may run out of fuel towards the end, so it is a good idea to return to the mission when the green fuel gauge is about half full. After recapturing the museum, you will start dismantling the Grand Magasin, but the first thing you want to destroy is the machine gun turret (above) on the side of the tip and tip attack parts. The tip and tip attack parts are blocks that are easily dropped by other gunners, but if the coupler is destroyed, all the parts in the detached blocks will be destroyed. Similarly, the left and right propulsion parts are also easily dropped, so it is a good idea to attack them before other blocks.



img-caption

The timing at which each block is destroyed by an ally&#39;s attack varies, so unless you&#39;re lucky, you might not be able to earn a high score.



img-caption

In the mission to destroy the command tower, you&#39;d normally support Shell, but Fam should also attack the command tower and get the bounty.



Ciel

-------------------------------------------------- ----------

In Ciel&#39;s case, the problem is that he will be late to the dismantling mission due to the battle with Rival. Meanwhile, Fuam and Copan are dismantling Grand Magasin. Therefore, taking into account the slight delay, you should prioritize parts that are efficient for bounty money (high number of chains and hits) without being greedy.



img-caption

Another good way to earn points is to chain the Command Tower Machine Gun Turret A from above, deliberately minimizing damage to the Command Tower and increasing the number of attacks.



Copan

-------------------------------------------------- ----------

The demolition mission is the same as Fam&#39;s. The key is how to handle the engine destruction mission, which is very inefficient for bounty hunting. Leave the crank attack to Ciel, and attack the tank that appears on the underside of the engine with firework missiles, or aim to hit the Progress squadron flying around by rolling.



img-caption

When there are a lot of tanks under the engine room, try to charge in by rolling. You can get a lot of hits.





one two three



&lt;Grand Magasin side view&gt;



Control Tower

-------------------------------------------------- ----------

During mission 5-4 for each character, a cannon will appear and can be destroyed. There are three rows of cannons, each with a maximum of nine chains. These are the parts you want to aim for on Ciel and Fam.





Machine gun turret + main gun

-------------------------------------------------- ----------

The upper and lower machine gun turrets will chain together for 8. If you chain the upper machine gun turret for 7 or more, the turret will fall, destroying the main gun and the lower machine gun turret, and adding 9 hits. You only need to equip the upper turret.



balloon

-------------------------------------------------- ----------

After hitting the balloon with a few machine gun shots to inflate it, you can break one of the balloons to create a 5-chain. Don&#39;t forget to destroy the balloon at the rear of the ship before detaching the garden.



Pumpkin Bombs and Firework Missiles

-------------------------------------------------- ----------

When you destroy the pumpkin bomb, it will roll forward and destroy all the firework missiles, creating a chain of 10. Attack the high-durability pumpkin bomb with cross missiles or the pumpkin bomb&#39;s maximum lock.



Medium-sized guns and others

-------------------------------------------------- ----------

If you destroy the machine gun turret from the medium turret, you will get a 10-chain. This is the highest chain number in the game. Since it will be destroyed by your allies relatively quickly, attack it as a priority.





&lt;Top view of Grand Magasin&gt;



About the couplers

-------------------------------------------------- ----------

The coupler machine gun turrets around the coupler each have 6 chains. Destroying these coupler machine gun turrets will separate the ship&#39;s blocks, so destroy the other parts before attacking.



Rigid balloon

-------------------------------------------------- ----------

If you destroy the joints that connect the balloons, you will get a 10 second bonus count for each one. If you do this early in the dismantling process, you can increase the bonus multiplier quickly.



Large gun emplacement

-------------------------------------------------- ----------

If you destroy the upper one of the two large cannons, the cannon will fall and destroy the lower one. It won&#39;t count as a hit, but you can get a 4 second bonus count for each cannon.





124



Ranking Certification

RANKING



Once you have cleared the entire story, you will be taken to the ranking screen after the ending, where your Gunner Rank will be certified on a 7-point scale from SS to E. For Fam, Ciel, and Copain, your play will be evaluated in the following 4 categories, and the category you reach at the end of the evaluation of your total winnings will be your Gunner Rank for that play. Only if you receive the highest rating in all categories will your Gunner Rank be certified as SS, and you will be able to use the hidden feature of the cut-out camera. In Rival, your Gunner Rank will be determined directly from the total winnings you have earned when you have cleared the entire story. However, there is no special luxury in getting an SS in Rival.



img-caption

Gunner rank starts with the mouth, and if a good evaluation is given in each category, the rank will go up by one, and if the evaluation is bad, the rank will go down by one.





Fam, Ciel, Copain

-------------------------------------------------- ----------



1 Total kills

-------------------------------------------------- ----------

Throughout the entire scene, you will be evaluated based on the total number of enemy planes shot down and parts destroyed.



Shoot down 400 or more enemies: 1 rank up

Shoot down less than 400: No change in rank





2 Event Evaluation

-------------------------------------------------- ----------

Each character is evaluated based on the number of bonus events they successfully complete. For more information on bonus events, see page 36.



Fam, Ciel

Success count: 9~7 1 rank up

Success count: 6~3 No change in rank

Success count: 2~0 1 rank down



・Copan

Success count: 8~6 1 rank up

Success count: 5~3 No change in rank

Success count: 2~0 1 rank down



3 Number of falls

-------------------------------------------------- ----------

Your rating will be determined based on the total number of times your aircraft crashes throughout all scenes.



Crashes less than 10 times: 1 rank up

Crash count: 20 or less Rank: No change

Crashes: 21 or more times - 1 rank down





4Total Prize Money Won

-------------------------------------------------- ----------

Your rating will be based on the amount of prize money you have won when you have cleared the entire game, and whether or not you have saved, paused, and resumed play during the game.



Prize money over 18,000,000, 0 restarts, 2 rank up

Prize money of 10,000,000 or more: 1 rank up

Prize money 3,000,000 or more No change in rank

Prize amount less than 3 million: 1 rank down





Rival

-------------------------------------------------- ----------

Rival will be ranked in one of four levels, from SS to B, based on his total winnings. However, if he is defeated by Ciel even once, he will automatically be ranked B.



Prize money over 13,000,000 Rank SS

Prize money over 10,000,000 Rank S

Prize money 7,000,000 or more Rank A

Prize money less than 7,000,000 Rank B







125

Secret File











126



Conditions for hidden elements to appear



New game modes and characters will become available when you complete each character&#39;s story while fulfilling certain conditions. Those that require a certain amount of winnings will appear if the amount of winnings earned during the game is greater than that of your companions when you complete the story.



img-caption

The added game modes will be selectable by changing the question mark in the main menu.





album

-------------------------------------------------- ----------

You will now be able to view event photos from missions you have successfully completed in the game, as well as commemorative photos taken when you end the game in Album Mode (→ P130).



Appearance conditions



Complete all of Fam&#39;s stories





Copan can be used

-------------------------------------------------- ----------

You will be able to play Copan&#39;s story on &quot;Hard&quot; difficulty. You will also be able to use Chevalier in all modes.



Appearance conditions

Complete all of Fam&#39;s stories





Rival available

-------------------------------------------------- ----------

You will be able to play Rival&#39;s story on &quot;Hard&quot; difficulty (→ P132). You will also be able to use Phantom in all modes.



Appearance conditions

Complete Ciel&#39;s story by defeating Rival twice in both Scene 3 and Scene 5.





Scene Select

-------------------------------------------------- ----------

You will be able to use the scene select function to start each character&#39;s story from any scene you like (→ P135).



Appearance conditions

Complete all stories for Fam, Ciel, Copain, and Rival.





Time Attack

-------------------------------------------------- ----------

You can now play a time attack mode, competing to see who can destroy 100 enemy aircraft in the fastest time possible (→ P127).



Appearance conditions

Complete all of Ciel&#39;s stories while being ranked number 1 in total prize money.





survival

-------------------------------------------------- ----------

You will now be able to play a survival mode in which you compete to see how many planes you can shoot down by shooting down enemy planes and refueling (→ P128).



Appearance conditions

Complete all of Copan&#39;s stories while coming in first in total prize money.



Aldi available

-------------------------------------------------- ----------

You will only be able to use Aldi&#39;s Courage in Time Attack and Survival modes.



Appearance conditions

Complete all of Fam, Ciel, and Copain&#39;s stories with the highest total prize money for each of them.



No cut-out camera available

-------------------------------------------------- ----------

You will now be able to use a continuous camera, which allows you to distance yourself from the viewpoint during the game and observe how your aircraft is flying objectively (→ P135).



Appearance conditions

Complete all of Fam&#39;s, Ciel&#39;s, or Copain&#39;s stories with a Gunners Rank of SS.









127

Time Attack

-- Quickly defeat 100 enemies --

This mode challenges you to see how quickly you can shoot down all 100 enemy combat boats, a total of 14 battleships. Every time you shoot down 10 boats, your lap time is displayed in the bottom right of the screen, and the game ends the moment you shoot down 100 boats. There is no concept of prize money in this mode, and no matter how many machine guns and optional weapons you use, it will not affect the result. The most important thing is to quickly capture and shoot down the target. How you use the optional weapons and EX actions of your aircraft is also the key to shortening your time.



image-caption (top-right image)

If you aim for one squadron, it&#39;s important to take them down without missing a shot. If you leave some of them, they&#39;ll scatter all over the place, which will take time.

image-capion (top right 2nd image)

The Fireworks Missile is useful in many ways. If you can take out a whole squadron at once, it will make shooting down those pesky Derniers a breeze.

image-capion (top right 3rd image)

Although the Chevalier has poor turning ability, its appeal is its ability to shoot down entire squadrons that are on the verge of passing each other by rolling.

image-caption (hardi image)

Aircraft with excellent turning ability and top speed are more likely to achieve good results.



=Tips for shortening time=

・First, get the optional weapon

At the start of the time attack, your plane does not have any optional weapons. You&#39;ll want to wipe out the enemy planes in front of you right at the start and get your hands on some Fireworks Missiles.

・Shooting down the captain&#39;s plane will be postponed

When shooting down enemy formations with machine guns, try to aim at the formation&#39;s planes deployed to the left and right as much as possible. The leader plane in the center is easy to aim at, so you may be tempted to attack it, but if you take him down first, the formation will fall apart and it will be difficult to shoot him down later. If you leave the leader plane behind, even if he gets far away from the enemy formation, you can lock on to him with a fireworks missile and take him down all at once.

How to fight Dernier

The troublesome opponents in this mode are the two Dernier squadrons. They chase your plane from behind and fire machine gun bullets at you, so if you&#39;re not careful, you could be shot down. They are also highly maneuverable, and if you start chasing them, they will twist and circle around to escape. If you&#39;re using a plane with poor turning ability, you won&#39;t be able to catch them head-on if you&#39;re too close. Chasing them will just waste your time, so move away for a while, or use a power slide to correct direction and capture them, then lock on to the fireworks missiles and take them down.



Enemy planes that appear in time attack



Premier 8 aircraft formation (Premier C x 1 + Premier A x 7) x 4

Premier 8 aircraft formation (Premier C x 1 + Premier B x 7) x 2

Premier 8-plane formation (Premier Dx8) x2

Progress 6 aircraft formation (Progress C x 1 + Progress A x 5) x 2

Progress 6 aircraft formation (Progress C x 1 + Progress B x 5) x 2

Dernier 6-plane formation (Dernier B x 1 + Dernier A x 5) x 2







129



survival



Defeat enemies, refuel, and increase your kill count.



In this mode, you shoot down the combat boats and battleships that appear one after another, and try to see how many enemy planes you can kill before you run out of fuel. In this mode, the time you gain when you kill an enemy plane serves as fuel, and when this time runs out, the game ends. In other words, killing enemy planes quickly means you can fight for a longer period of time, which in turn leads to more kills. The types and numbers of enemy planes that appear are fixed, and when they are all killed, the same enemies are set again and the next round begins. However, at that time, the time you can earn by shooting down enemy planes is reduced, so the further you go in the round, the easier it is to run out of fuel.





img

Boss class enemy aircraft that appear in one lap

Defeat Gauche

Defeat Dorowatt

Defeat Grandir

Defeat Fantoum (Avenir)



Only one boss class enemy will appear in each area at any one time, and each time you shoot it down, the next boss class unit will appear in order, as shown in the diagram above. The next round will not begin until all of them have been shot down.



Tips for increasing kill count



- Defeat nearby enemies first

In order not to waste fuel, try to spend as little time as possible just moving. If the enemy plane you&#39;re targeting is far away, it&#39;s recommended that you don&#39;t chase it closely, but switch targets and start taking down the closest enemy plane. While you&#39;re doing that, there are many cases where the enemy will come towards you.



● Be sure to destroy the weapons and parts of large enemy aircraft. In order to gain more kills and fuel, be sure to destroy the weapons and parts of large enemy aircraft.

Destroy all the parts and destroy the main body. Whether you pay attention to this point or not will make a big difference in the number of kills you can get per round. Chains and hits don&#39;t increase your fuel, so you don&#39;t need to worry about them, but it is effective to use firework missiles to destroy multiple parts at once and aim to shorten the time.



● Use optional weapons efficiently

Don&#39;t overuse optional weapons, but use them to shoot down enemy aircraft more efficiently. For example, firework missiles should be used to destroy the armaments of a battleship in one go, or to destroy a group of departing battleships, while cross missiles and pumpkin bombs should be saved to destroy the shields of Gosh and Drowat. Instead, make effective use of EX actions.



- Be careful not to get shot down by enemy aircraft.

The time it takes to crash and recover will be a huge loss. When the balance meter starts shaking and a warning sound is heard, don&#39;t push yourself too hard and move away to a safe place.





img-caption

In any case, avoid careless play. However, being too careful will mean you run out of fuel. Move quickly and carefully.



img-caption

Even if you shoot down a boss-class enemy, there will be a delay before it disappears from the area. During that time, your fuel will continue to decrease, so be careful.



&quot;I recommend Blanche, who has high durability and can quickly push back enemy units with active turns!&quot;











130

album



This mode lets you enjoy two types of photos: event photos taken when you clear a story mission, and commemorative photos taken by Vantle when the game is over. When you clear Fam&#39;s story, you won&#39;t have all of them, but you can fill in the gaps by fulfilling the following conditions. Basically, you can collect event photos by successfully completing the given missions, but to take photos 4 in Scene 2 and 4 in Scene 3, you must fail the previous mission and clear the added mission.



image-caption

The small squares lined up in the center of the screen correspond to each photo, and when you move the cursor over them the picture will appear within the frame.



How to take commemorative photos

1. Game over in Ciel&#39;s story

Game Over in Copan&#39;s story

Game over in the 3 Fam story

4. Game Over in Rival&#39;s story

5. Ciel loses Merville in Copain Scene 1

6. Annihilate the cargo fleet in Scene 2

7 In Scene 4, the danger level of Ardi&#39;s armored vehicle reaches 100% or its power is reduced and it falls into the lake.

In scene 5 of 8Fam, Ciel&#39;s danger level during attacks such as orders becomes 100%.

9. In Ciel and Copan scene 5, Fam&#39;s danger level during the museum recapture reaches 100%.

In scene 5 of 10, the game ends in the battle with Vainqueur Garde after the second stage of rampage.



SCENE 1 How to upload event photos

1st Mission: Ciel 1-1, Copan 1-1

Complete &quot;Defeat the Combat Boat&quot;

2nd Mission: Ciel 1-2

Complete &quot;Defeat Starbull in front of the hangar&quot; before Fam leaves Merville on her own.

3rd Mission: Copan 1-2

Complete &quot;Defeat the mechanical soldiers on the deck&quot;

4th Mission: Fam 1-1

Complete &quot;Fall Through the Hangar Door&quot;

5 Mission: Fam 1-2

Complete &quot;Defeat the Combat Boat&quot;

6 Missions: Fam 1-3, Ciel 1-3, Copan 1-3

Fam defeats the last tractor during the mission &quot;Defeat the defenses protecting the tractor&quot;, while Ciel and Copain defeat the last tractor during the mission &quot;Destroy the tractor and recover Merville&quot;.

7 Mission: Fam 1-3

Complete &quot;Defeat the defense forces protecting the tow plane&quot;

8 Mission: Fam 1-4

Complete &quot;Destroy the aerial battleship&#39;s guns and support Ciel.&quot;



How to upload photos from an event in SCENE 2

1st Mission: Fam 2-1

Complete &quot;Defeat the Combat Craft and Protect the Cargo Fleet&quot;

2nd Mission: Ciel 2-2

Complete &quot;Defeat Balaine and protect the cargo fleet&quot;

3rd Mission: Copan 2-2

Complete &quot;Defeat the Assault Pods and Protect the Freighter Convoy&quot;

4th Mission: Copan 2-3

Complete &quot;Drive away the Phule with the Hound Missiles&quot;

5 Mission: Fam 2-2

Complete &quot;Defeat the Combat Craft and Protect the Cargo Fleet&quot;

6 Mission: Ciel 2-3

Complete &quot;Defeat the fleeing combat craft&quot;

7 Mission: Fam 2-3

Complete &quot;Defeat Grandir while defeating many enemies.&quot;

8 Missions: Fam 2-3, Ciel 2-4, Copan 2-3

Destroy the lightning generator and complete &quot;Defeat Grandir while defeating many enemies.&quot;





131





SCENE 3 How to upload event photos

1st Mission: Ciel 3-1

Shoot down the Phantom and complete &quot;Defeat the Black Gunner&quot;

2nd Mission: Copan 3-1

Shoot down the Exoze Blue and complete the mission &quot;Defeat the Exoze before it leaves the waterway.&quot;

3rd Mission: Fam 3-1

Complete &quot;Stop the Machine Invasion&quot; before the telescope is stolen.

4th Mission: Fam 3-2

Complete &quot;Recover the telescope from the robotic soldier&quot;



How to upload photos from SCENE 4 events

1st Mission: Fam 4-1

Complete &quot;Defeat the Formation Squad&quot;

2nd Mission: Ciel 4-2

Shoot down two Dorados within the time limit and complete &quot;Defeat the Dorados guarding the fortress&quot;

3rd Mission: Copan 4-2

Complete &quot;Destroy the Artillery Guarding the Fortress&quot; by destroying all the artillery within the time limit.

4th Mission: Fam 4-2

Complete &quot;Defeat the defense forces guarding the fortress&quot; by destroying the three Dernier units within the time limit.

5 Mission: Fam 4-3

Complete &quot;Defend the armored vehicle from enemy attacks&quot;

6 Mission: Fam 4-4

Complete &quot;Defeat Skret while protecting the armored vehicle.&quot;



How to upload photos from SCENE 5 events

1st Mission: Ciel 5-1

Complete &quot;Defeat the Combat Boats&quot;

2nd Mission: Copan 5-1

Complete &quot;Defeat the Combat Boats&quot;

3 Mission: Ciel 5-2

Complete &quot;Defeat Torad and protect Fam from the mechanical soldiers.&quot;

4th Mission: Copan 5-2

Complete &quot;Defeat Drado and protect Fam&quot;

5 Mission: Fam 5-1

Complete &quot;Remove the connecting parts and take back the museum&quot;

6 Mission: Fam 5-2

Complete &quot;Defeat the turret that repairs the connection parts&quot;

7 Mission: Fam 5-3

Complete &quot;Destroy the coupler and dismantle the Grand Magasin&quot;

8 Mission: Ciel 5-3

Complete &quot;Defeat Rival&quot; by destroying the Phantom.

9 Mission: Ciel 5-4

Complete &quot;Destroy the Strong Control Tower&quot; within 150 seconds with the engine room intact.

10th Mission: Copan 5-4

Complete &quot;Destroy the engine by breaking the parts&quot; within 150 seconds.

11th Mission: Fam 5-4

Complete &quot;Defeat the enemies and protect Ciel&quot;

12 Mission: Fam 5-5

Complete &quot;Perform a coordinated attack with your allies.&quot;

13 Mission: Fam 5-6

Complete &quot;Attack the perpetual motion machine and defeat the mechanical soldiers.&quot;

14th Mission: Fam 5-7, Ciel 5-7, Copan 5-7

Complete &quot;Defeat Vantor&quot;

15 Mission: Rival 2-2

Complete &quot;Attack the perpetual motion machine and defeat the mechanical soldiers.&quot;





132



Rival Story



Unlike the other characters, the story of the fourth character, Rival, only has two scenes in total. Moreover, all missions are simple, with only a direct confrontation with one specific target. However, the difficulty level is &quot;difficult&quot;, and none of the enemy planes can be defeated easily. The biggest point in clearing the game is how well you can use the Phantom, which has excellent attack power and top speed but low durability. Also, the conditions for ranking when you clear all of Rival&#39;s stories are different from those of the other characters (→P124).





SCENE1

Reeve&#39;s Gunner

1-1

Defeat Reeve&#39;s Gunner



SCENE2

Decisive Battle! Sky Fortress Battleship

2-1

Defeat Ciel



2-2

Attack the perpetual motion machine and defeat the mechanical soldiers.





●About winning the prize money of Rival

-------------------------------------------------- ----------

Rival&#39;s bounty system is completely different from other characters. After the scene starts, the bounty decreases from 9 million every second, and when you defeat the enemy plane and clear the scene, you get the remaining bounty as an event bonus. In other words, the faster you clear the scene, the more bounty you get. By the way, the battle bonus decreases as you fire the machine gun, but since there is no OW settlement, the bounty will not be reduced even if you use up all the optional weapons.



caption

For each mission, the prize money of 9 million will be deducted by 30,000 per second until the clearing conditions are met. When the mission is cleared, you will receive the remaining amount.





●About improving Phantom&#39;s performance

-------------------------------------------------- ----------

If you are defeated by Shell in Scene 1, your Phantom will be modified in Scene 2 and its performance will be improved, and it will become the Phantom Custom in the next Scene 2. If you are defeated by Ciel in Scene 2-1, you will move straight to 2-2, so it will not become the Phantom Custom.



img-caption

Its durability has increased to 210, and its balance recovery has increased to D, making it harder to destroy. In addition, its target sight has become larger, making it easier to hit with its machine gun, but the power of each bullet has been reduced from 10 to 8. Other performance is the same as that of the Phantom.





bottom-image

In the town of Neige, at the Rival Club, a certain person calls Rival. It&#39;s a job request for Rival, a gunner with connections to the underworld.



&quot;Prepare the Phantom.&quot;



The perpetual motion machine is the talk of the town right now. His job this time is to retrieve the important parts used within it. It also seems that Vantle is also after the perpetual motion machine. Rival first wants Vantle to steal the perpetual motion machine, and then he plans to take it for himself, so he flies off in his beloved robot to the city of Reeve.



&quot;Be careful.&quot;





133



SCENE I

Reeve&#39;s Gunner

10:00am



-------------------------------------------------- ----------

Defeat the Gunner in 1-1 Leve



Main Enemies

Avenir x 1



Special Rules

Shoot down Avenir 3 times within 5 minutes to get bonus





One-on-one combat with Shell&#39;s Avenir

-------------------------------------------------- ----------

Although Avenir only uses machine guns and steam brakes, it has very high turning ability and will immediately turn towards your ship and fire machine guns. If the Phantom crashes even once, the scene will be cleared as soon as it revives and you will lose, so you must avoid being shot down. You want to take a position where you are always chasing the enemy ship by using boost and power slide to correct direction. On the other hand, if Avenir&#39;s durability is reduced to 0, it will crash while emitting smoke for a while, but then it will recover to full durability and revive. To defeat Avenir, you must shoot it down three times within the 5-minute time limit. Furthermore, Avenir will recover its reduced durability over time, so it is necessary to deal large amounts of damage continuously. An effective strategy is to lock on to Avenir one by one and fire the hound missiles intermittently, slowing its movement and continuing to hit it with the machine gun. If you crash it, follow it, aim the lock site at the crashing Avenir, and fire the bat missiles the moment it revives and locks on, and you can effectively follow up with it.



img-caption

The duration of the Hound Missile&#39;s stalling effect shortens each time an enemy plane crashes, until it is eventually shaken off.



img-caption

When they move away, lock on to the bat missile and chase them, but since Fool will focus on running away, he&#39;s easier to aim at.



img-caption

If Avenir applies the steam brakes to try and get past your ship, it&#39;s your chance to attack!



img-caption

You can also deal massive damage by applying a steam brake and firing a heavy bullet.



img-caption

Hold down the ◎ button to reveal the lock site as Avenir falls...••.



img-caption

If you fire your optional weapon immediately after locking on, you have a relatively high chance of hitting the target.





134





SCENE II

Decisive Battle! Sky Fortress Battleship

3:00am



-------------------------------------------------- ----------

2-1 Defeat Ciel



Main Enemies

Avenir x 1



Special Rules

Shoot down Avenir 3 times within 3 minutes to get bonus





A rematch with Ciel, with an even shorter time limit

-------------------------------------------------- ----------

It&#39;s basically the same as Scene 1, but the time limit has been shortened to 3 minutes. Also, since Grand Magasin is nearby, if you shoot down Avenir above the deck, you will be blocked by obstacles and will not be able to follow up immediately after the crash. You can destroy Grand Magasin&#39;s armaments to get chains and hits, but if you take a detour, Avenir will target you, so be careful. Here too, if you are shot down even once, you will be defeated and the scene will change to the next mission.



img-caption

The Clan Macasan&#39;s Nakago will attack if you get close. It&#39;s fine to absorb them and save your shells, but if you want to shoot them down, you&#39;ll have to.





-------------------------------------------------- ----------

2-2 Attack the perpetual motion machine and defeat the mechanical soldiers



Main Enemies

Vaincourt Garde (Final stage of rampage) x 1



Special Rules

none





Hit and run is the key to victory

-------------------------------------------------- ----------

You will fight the mechanical soldier Vaincour Garde on the remains of the Grand Magasin that fell into the sea. The mechanical soldier&#39;s weak point is the perpetual motion machine on the front of the fuselage, but you can&#39;t do much damage to it with optional weapons. It&#39;s a good idea to keep attacking with the machine gun steadily, look for an opening to approach, and then hit it with a heavy bullet and retreat, and then repeat this process. The mechanical soldier&#39;s onslaught of floating artillery, tri-bone, and spear missiles is a threat, but if you know how to deal with each of them, it&#39;s easy to avoid them. Specifically, the floating artillery fires its shells precisely at your ship, so approach it while drawing it in as close as possible, and when you can no longer escape, use a power slide to switch to the other side. Also, the floating artillery disappears when your ship touches it, so you can use a power slide to reduce its number. When you are being followed by a tri-bone, you can easily get past it by power sliding. For the persistent spear missiles, you can take advantage of their strong homing ability and crash them into surrounding obstacles as shown in the right image. They can also be destroyed by shooting them, so you can destroy them by catching them in the explosion of a fireworks missile immediately after launching.



img-caption

Immediately after firing a heavy bullet, you will take 1.5 times the damage, making you more likely to be shot down.



img-caption

If a Yari missile is fired, if you boost around the wreckage just above the water&#39;s surface, the Yari missile will collide with the water&#39;s surface or an obstacle and destroy itself.





135



Scene Select

-------------------------------------------------- ----------

You can now start each character&#39;s story from any scene. After this feature is added, the scene select screen will always be displayed when you start a story. However, please note that if you select a scene and start the game, even if you clear the scene, you will not proceed to the next scene and will be returned to the scene select screen. The prize money and engines you have earned cannot be carried over to other scenes. If you want to play through the story, select &quot;Start from the beginning&quot; in the scene select.



img-caption

This is a useful function for concentrating on practicing scenes that you are weak at or for studying efficient ways to earn prize money.





Cut-out Camera

-------------------------------------------------- ----------

While playing, you can choose between a normal view, which captures the targeted enemy aircraft on the screen, and a behind-the-scenes view, which follows your ship from behind. With the addition of this function, you can choose a new view, which fixes the camera and continues to follow your ship objectively. If you select &quot;Cut-off Camera&quot; in the pause menu and press the ◎ button to confirm, the camera will be set where your ship was at that time. Furthermore, while using the cut-off camera, you can zoom the camera by pressing and moving the right stick of the analog controller up and down. However, this view is not suitable for game strategy at all. It is not very useful other than for enjoying the majestic figure of your ship flying around and the surrounding scenery. If you want to go back to the normal view, open the pause menu again, select &quot;Clinging Camera&quot; and press the ◎ button to return to the normal view.



img-caption

The attitude of your ship is displayed as a paper airplane in the upper right corner of the screen. Press the SELECT button to turn the display on/off.



img-caption

You can enjoy controlling your aircraft as if you were flying a radio-controlled plane, but shooting down enemies is an extremely difficult task.





About Developer Records

-------------------------------------------------- ----------

Even if you get the most prize money in each character&#39;s story, or get the best record in time attack or survival, you can&#39;t say you&#39;ve mastered &quot;Sky Gunner&quot;. It&#39;s only at this stage that you&#39;ll be given the right to challenge the developer records set by the development staff. If you meet the following conditions in each mode, the developer records will be displayed.



Developer record appearance conditions

● Story Mode: Complete Rival&#39;s story.

● Time Attack Get 1st place in the Time Attack

● Survival Get 1st place in Survival



img-caption

Only by surpassing this unprecedented developer record can one call one a true Gunner.









136

Sky Gunner Developer Interview

bonus

Visit PixelArts!

Esaka City, Osaka Prefecture

15:00 pm

Tell us about the secrets of Sky Gunner

&quot;Sky Gunner&quot; is captivating with its exhilarating gameplay and playful worldview. What thoughts and feelings did the staff have when they created this game? We spoke to developer PixelArts.



Aiming to create a shooting game like no other



- &quot;Sky Gunner&quot; is Pixel Arts&#39; first game, but the plan was solidified from the beginning.



Enomura: When this group of people gathered, it was all chaos. We wanted to make a 3D action RPG that allowed multiple players to play at the same time, with enemies swarming about, something that wasn&#39;t available on the PlayStation at the time, but to be honest, it was difficult. So we came up with various other ideas, but it was hard to solidify the image just on paper, so I started programming the ideas that came up. Among them was the prototype for &quot;Sky Gunner,&quot; where the player&#39;s ship was an airplane. It was a prototype based on the concept of making a 3D shooting game that wasn&#39;t just a flight simulator.



-What exactly is the concept?



Hiraki: Breaking away from existing 3D flight games and creating a new direction for a shooting game. I wanted to make a game that truly feels like a shooting game in a 3D space, and that&#39;s the foundation of &quot;Sky Gunner&quot;. On top of that, what I valued was the sense of exhilaration, the feeling of flying in the sky. And the excitement. I just wanted to bring out a lot of things and create a sense of chaos. In terms of the worldview, I didn&#39;t want to go in the direction of sci-fi or modern military stuff that would appeal to enthusiasts, but rather a character-based world that would gently embrace the player.



Kotani: To put it more simply, we started off with the idea of creating a shooter that would make people who don&#39;t usually play shooters want to play it after just one look! So we thought about how we could get people to pick it up and play it, and we came up with a world view and characters that anyone could embrace, an exciting screen, and a good atmosphere.

We took to heart what Hiraki had said and worked to give shape to each of them one by one.



Enomura: Up until now, 3D shooters and flight games have mainly focused on stoic dogfights. That has been popular among enthusiasts, but it has not really caught on with the general public. So, from the perspective of what is bad about flight games and what is frustrating about them, we started thinking about a game system that feels good.



Nakamura: When we first started planning the game, we were developing it for the PlayStation.



Kotani: One thing that hasn&#39;t changed since the beginning is the unique camera perspective that keeps enemies on screen at all times.



Hiraki: I thought that one of the bad things about 3D shooters was the slow tempo caused by the time you lose sight of the enemy. So I thought, what about a game where you keep your eyes on the enemy all the time?



Kotani: By including the enemy on the screen it looks more spectacular, and you won&#39;t miss any scenes of them being shot down.



Hiraki: It&#39;s visually interesting.



Kotani: However, this had the drawback of making it difficult to see ahead of the player&#39;s ship. So we decided to prevent the player from hitting obstacles right from the start, and so the flight assist system was born. Furthermore, we thought that if that was the case, we didn&#39;t need to make the game so strict, so we came up with a system to avoid crashing by repeatedly pressing a button. In this way, the main system was created one after another.



Hiraki: It&#39;d be boring if you were suddenly attacked and killed in a game. It&#39;s more fun to frantically mash the button to revive. Incidentally, during development, the number of button mashes required to avoid falling was successfully reached 320 times. I was the one who adjusted the number of button mashes required to revive, but once I mashed the button to the limit, my fingers got burned by the friction heat, and I couldn&#39;t mash for about three days. Then when I fixed it, I&#39;d get that &quot;ouch!&quot; again (laughs).



-What were some of the most difficult aspects of development?



Nakamura: The programmers must have had a hard time.



Kotani: More than anything, the most difficult issue was figuring out how to handle collision detection.



Enomura: We use a unique algorithm for collision detection, so we can simultaneously calculate a large amount of collision detection, but that&#39;s only for things that don&#39;t move. For example, there are many moving models with complex shapes like Merville and Gauche, and if you set up collision detection so that your ship and enemy ships avoid each of them, the processing speed becomes very slow. It was difficult to design maps and battleships while taking that into consideration.

If we make it that way, it won&#39;t become a game, but if we don&#39;t make it too complicated, it won&#39;t have the excitement that is one of the concepts.



Hiraki: You kept saying &quot;speed, speed.&quot;



Enomura: That&#39;s because we kept telling ourselves to &quot;faster, faster&quot; until the very end (laughs).



Nakamura: It&#39;s been going great all this time (laughs).



- Speaking of complex models, I was amazed at how the clouds were rendered. You can actually stick something in them.



Enomura: We have a production programmer named Imai, who was in charge of all the soft modeling, like the clouds and splashes. I&#39;m very pleased with how this softness has been achieved, something that hasn&#39;t been seen in any other games before.



Nakamura: Clouds are one of the important situations in &quot;Sky Gunner.&quot;



Hiraki: From the beginning, I was concerned about the screen effects that were unique to the PlayStation. At the time, no other manufacturers had touched the cloud effect, so I asked them to make it.



- Were those kinds of clouds already created when you were developing the game for the PlayStation?



Enomura: The way of expression is completely different from now, but I thought that soft clouds were necessary for the world view of &quot;Sky Gunner,&quot; so I did a lot of research on that aspect back then.



Nakamura: On the PlayStation, just producing clouds used up the machine&#39;s capabilities.



Kotani: It really wasn&#39;t a game at all (laughs).



Enomura: That&#39;s right. Once 20 clouds appeared, it was no longer a game (laughs).



=Meticulous craftsmanship=



-What would you particularly like players to see?



Hiraki: I would say everything, but it&#39;s the sheer number of things. I think the excitement, the sense of chaos, creates an atmosphere that you can&#39;t get anywhere else.



Enomura: If there is nothing to compare the size and speed of your plane to, it&#39;s hard to get a sense of distance. That&#39;s why we added a lot of things, like towns, clouds, and huge battleships and airships, so that you can naturally feel the space. Also, we added a lot of enemies. I want you to experience the pleasure of shooting down enemy planes one after another.



- There&#39;s no compromise. There&#39;s no lie in what&#39;s happening in the space. The other companions are properly completing their respective missions, aren&#39;t they? I think that&#39;s something that&#39;s been skipped over in previous games.



137



Kotani: When Copan is rolling, you can really tell, even from a distance. Oh, it&#39;s spinning.



Hiraki: It&#39;s possible because there are so many different things in the space, and they&#39;re all moving around. Personally, I really like that about it.



Nakamura: The fact that your companions are your allies but also your rivals in bounty hunting is what makes the game so interesting.



Hiraki: There are many times when it feels like a nuisance (laughs). Like when the Heavy Bullet takes down enemies on its own.



Enomura: I was waiting to try and get the chain, but it was snatched right before my nose. That guy from Copan! (laughs).



- Of course, there are many things that appear in the film, but the production and the attention to detail is amazing. There are little Poulet walking around on the battleship&#39;s ridge, which is nearly 2 kilometers high, and people standing in the town.



Enomura: It&#39;s pretty pachinko-ish, though. People in town.



Kotani: To be honest, we wanted to do more. At first, we were all postmen.



-In scene 3 of Fam, when Vainqueur attacks aiming for the telescope, the townspeople are watching happily by the river.



Hiraki: I guess everyone in Reeve loves festivals. In &quot;Sky Gunner,&quot; no one dies. I didn&#39;t want it to be too bleak in the pursuit of realism, so I decided from the initial concept that it was absolutely no good for living things to die. That&#39;s also something I value in &quot;Sky Gunner.&quot;



Kotani: We also decided not to destroy any buildings in the city.



Nakamura: I take good care of each and every Poulet. When I shoot them down, they open their parachutes and escape. I&#39;d be scared if the Poulet I shot down was covered in blood (laughs).



Hiraki: Well, I wasn&#39;t really conscious of it at first when I made it, but if you look closely, you&#39;ll see that there are a lot of little details, like this thing in this place.



Watanabe: In Scene 1, in the Merville, it might be hard to notice Poulet attacking the greenhouse underneath the ship.



Nakamura: If you look closely, you&#39;ll see that there are many things related to poole.



- Whenever a part of the battleship breaks, he says &quot;I&#39;ll repair it,&quot; and he appears at the broken part and fixes it neatly with a hammer. It was also funny to see Pulp pop out of the assault pod (laughs).



Hiraki: It wasn&#39;t well-received within the development team because it wasn&#39;t interesting as an event, but I like it as a situation. There is a cushion stuck inside the assault pod, and that is also properly expressed in the texture. It&#39;s hard to see, though (laughs).



Enomura: Also, Poulet was hanging from the Grandir when its shaft was broken and the aircraft was tilted.



Hiraki: You hang on desperately for a while, but eventually you slip away.



Kotani: I was saying that I really wanted it to fall, and before I knew it, it did.



Hiraki: That was the attention to detail of Imai-kun, who was in charge of directing. Oh, he even did that for me (laughs).



- When you damage a plane that Poulet is riding on, such as Premier or Progres, animals, flower pots, pods, tea cups, umbrellas, etc. fly off, right? What are those?



Hiraki: That&#39;s Poulet&#39;s treasure (laughs).



Watanabe: I always take it with me even during operations (laughs).



-There&#39;s a face drawn on the Hound missile too.



Kotani: It&#39;s shaped like a dog. During development, Rival had bone chaff as a counter weapon against the Hound Missile. When the Hound Missile sees the bone, it wants to bite it.



- I was also surprised that when Rival fires his heavy bullet, 16 shells fall, and that the time on the town&#39;s clock tower matches the time set in the scene.



Hiraki: I thought, &quot;No one would normally notice this,&quot; but I still put it in. But I think that&#39;s what makes it &quot;Sky Gunner.&quot; It&#39;s all thanks to the designers who paid attention to the small details.



(right side of page)

==Character Introduction==

Game Designer

Yoshikazu Hiraki

·job description

Deciding on the concept and specifications for &quot;Sky Gunner&quot;, presenting it to the staff, and creating game data and model data.

・Tea time essentials

Mitarashi dumplings

・Precious treasure

Special Fam wallpaper

・Your optional weapons

Naming Dictionary

・Your EX action

Escapism



Main System Programmer

Daisuke Enomura

·job description

Creating everything below the &quot;game&quot; level, such as the rendering system, file system, resource management system, and low-level libraries

・Tea time essentials

Baby Star Ramen Snacks

・Precious treasure

algorithm

・Your optional weapons

WZ Editor (without this, the functionality is only 1/100)

・Your EX action

Composition



Character Designer

Kei Watanabe

·job description

Designing characters, gunner machines, and some enemies, and creating graphic data

・Tea time essentials

Welch&#39;s (grape flavor) and Minute Maid Pink Grapefruit (only available in plastic bottles)

・Precious treasure

Poulet figure

・Your optional weapons

Pencil and eraser

・Your EX action

God&#39;s waste



Co-Director

Yuji Nakamura

·job description

While watching the game objectively, look at the schedule and think about how to allocate your efforts

・Tea time essentials

Shimada Ramune

・Precious treasure

Idea notebook

・Your optional weapons

hat

・Your EX action

Animal Welfare



Director

Hiroyuki Kotani

·job description

Imagine the overall feel of the game

・Tea time essentials

Angel French (I bought it as a gift because I wanted to eat it myself)

・Precious treasure

Sega Mark III (FM sound source, 3D glasses, rapid-fire option, trackball included)

・Your optional weapons

Kentaro Bomb (son)

・Your EX action

Quick Forget



(end of page)



138



==Charming Characters==



- The atmosphere of the characters and illustrations is one of the most appealing aspects of this game, but was there anything you paid particular attention to when designing them?



Watanabe: I was very conscious of approaching people who don&#39;t usually play games. Hiraki told me to first aim for a clear silhouette so that people who see the character will like it and it will be easy to draw a portrait later. But in the end, I was allowed to do it pretty much as I wanted. It was full of my hobbies.



Hiraki: You did it (laughs).



Kotani: She&#39;s cute, but she&#39;s not trying to be pandering in any weird way.



Watanabe: I think that character Poulet in particular is one that shows that.



-By the way, why do the people living in this world have tails?



Watanabe: This is a remnant from the early character design. Initially, the design was based on the concept of a birdman, but as development progressed, it gradually took on its current form. At first, it had a very animal-like appearance.



Kotani: I didn&#39;t want to simply make the characters out of deformed animals.



Hiraki: That&#39;s right.



Kotani: They probably evolved from something different than humans originally, but they were a different species of human, not an animal.



Hiraki: Well, I think it has evolved in a good direction (laughs).



-Are there age settings for each character?



Hiraki: I&#39;m just thinking about it, but I&#39;m using Ciel as the standard. In human terms, Ciel is in his mid-teens, Copan is one or two years older, Fam is a little older than that and Rival is a little older than that. Then, Ardi is in his late 40s and Vantre is about 60 years old.



- Among the main characters, Fam is the oldest. Does she see Ciel and Copain as children?



Hiraki: Given her personality, I don&#39;t think she would be too concerned about it, but she is younger after all.



Kotani: But Ciel and Copain have high levels of skill as gunners. I think Fam also fully acknowledges their abilities.



Watanabe: Ciel is a bright and kind girl.



Hiraki: Although he does seem to be overshadowed by Copan and Fam. He is a leader who keeps things together when it&#39;s time to. I&#39;m sure he has a personality that makes him well-liked by everyone.



Enomura: Serious and responsible.



Hiraki: But he&#39;s still a bit childish. Like a boy, he likes machines and inventions.



-Compared to that, Copan...



Hiraki: Copan loves girls. And he&#39;s the kind of person who thinks that fun is everything. He doesn&#39;t do anything troublesome. So he leaves the maintenance of the flying boat to the manufacturer.



Nakamura: I hate anything that&#39;s troublesome.



-Do you like listening to the radio, Copan? It seems like you always carry it with you.



Watanabe: Copan has his own radio. He brings it onto the cargo ship at the beginning of Scene 2.



Hiraki: It&#39;s not that I just like listening to the radio, but I think I listen to the radio to gather information, wondering if there&#39;s anything interesting to listen to.



-What does the note attached to the cockpit of the Chevalier that Copain is riding in say?



Watanabe: That must be a note about girls. There&#39;s no doubt about that (laughs).



Nakamura: I write down my contact information, upcoming birthdays, and other trivial things so I don&#39;t forget them (laughs).



Kotani: What&#39;s more, the notes aren&#39;t always from the same girl. They might change before you know it (laughs).



(left side, ciel concept art)

(1)

These are the earliest designs for the main characters. They have a more animal-like feel. It seems that the image of each character was solidified around this time.

(2)

Ciel&#39;s initial design. The key point is that she is wearing goggles, like an airship pilot.

(3)

An early design of Ciel that is close to her current appearance. She has a mischievous look on her face.

(4)

Ciel&#39;s trademark is the big zipper. In addition to zipping up his jacket, it also serves the purpose of being a tie.

(5)

This is the only outfit Ciel wears in the game. She doesn&#39;t seem to have much interest in dressing up.



(end left side, ciel concept art)



139



--Does the Gunner Cafe in the town of Reeve have regular customers? Or is it only open to Gunners?



Hiraki: Regular customers come too. It&#39;s just called Gunner Cafe by the people of Leeve because Ciel and Copain live there, but I&#39;m sure it has a real name. It&#39;s definitely not a store exclusively for Gunners. It was probably just Ciel at first, and then Copain moved in, so it got the name.



Kotani: What a nasty regular (laughs).



Hiraki: So it&#39;s a place that anybody can come to and stay if they want.



- The owner or manager of Gunner Cafe was originally a Gunner, so do you think he&#39;s understanding of Ciel and the others who do pretty much whatever they want?



Hiraki: I wonder about that? Gunner is a profession that everyone aspires to in this world, and there are very few of them. I guess they&#39;re pretty pampered.



Enomura: Copan became a gunner because he wanted to be pampered (laughs).



-There&#39;s also a maintenance shop behind the store, right?



Hiraki: In this kind of world, hotels have to have facilities to maintain flying boats.



-Do you have a dedicated mechanic?



Hiraki: No, there are no such people, especially at the Gunner Cafe. Normally, a gunner wouldn&#39;t let anyone else touch his or her flying boat.



- I heard that Avenir was built by Ciel&#39;s grandfather, but did your father also ride it?



Hiraki: I&#39;m not sure about that. My dad might have had his own plane.



-If your grandfather has a connection to Avenir, does that mean Ciel&#39;s family has been gunners for generations?



Watanabe: I wonder? Maybe Ciel&#39;s mother is a gunner, not his father (laughs).



-Are there many female gunners?



Hiraki: It&#39;s not that women can&#39;t become gunners, but the number is still small. Fam wasn&#39;t a gunner because she wanted to, but because her qualities were discovered.



Watanabe: It could be who he was inspired by...



Hiraki: Ah, that was a slip of the tongue (laughs). Well, Gunner was close to Fam, and she may have been influenced by him.



-Why is Fam, a rookie gunner, in charge of guarding Melville?



Hiraki: This may be a part of the Horloger Company&#39;s disliked culture, but by providing new aircraft to female gunners, which is still rare, they are using them as a good advertisement for the company. As for Fam, he probably took on the role because his acquaintance, Ardi, was in charge of security.



-At the beginning of Scene 1, Fam has long hair. Is that a wig?



Watanabe: Yes. That&#39;s the setting. I didn&#39;t notice it when I was first drawing it, but when I tried to draw the scene where she lets her hair down, I thought, &quot;Oh, this is too short&quot; (laughs).



- Since Fam is older than Ciel and Copain, does she have a more mature vibe?



Hiraki: That&#39;s right. She may be the most reliable, but I wouldn&#39;t say she&#39;s particularly cautious. She can be bold at times. She&#39;s someone who can take appropriate action depending on the situation. She&#39;s a very sociable person, so she&#39;s probably very popular with those around her. She&#39;s probably a beautiful woman in that world, after all.



-Is Copan not interested in Fam?



Watanabe: I don&#39;t know about that.



Hiraki: Maybe Fam isn&#39;t his type. I&#39;m sure Copan likes girls who are easy-going like him.



(right side of page. copain and femme concept)

(1)

The design concept of Copan is belts, and there are many belts in the shoes that she wears.

(2)

Copan waking up

(3)

An early design of Copan wearing long trousers. His gentlemanly image hasn&#39;t changed much.

(4)

Underwear for flight suits. When I&#39;m working, I prioritize ease of movement when choosing clothes. Fam has quite a lot of costumes, so most of the luggage I brought was clothes?

(5)

Fam&#39;s initial design. It had a more mature feel to it.

(6)

The flight suit has pockets on the chest and inside of the skirt for storing maps and other items.



(end right side of page. copain and femme concept)



140



-Rival is quite wealthy, isn&#39;t he?



Hiraki: He is one of the richest men in the world.



Enomura: One of the remaining billionaires is Vantor.



-I heard that the Phantom that Rival pilots and the Avenir that Ciel pilots are sister machines?



Watanabe: They were made around the same time, but while Rival customized the Phantom to his liking, Ciel took good care of Avenir while preserving its original shape.



Hiraki: Originally, the Phantom was probably shaped similarly to Ciel&#39;s machine, but Rival used his wealth to modify it, saying he didn&#39;t need this part, he didn&#39;t need that part. It&#39;s likely that the Phantom will not continue to have the same shape in the future.



- Even in the game, when you lose a battle with Ciel, your character immediately changes shape.



Nakamura: Removing all unnecessary parts in order to win is a bit like Mini 4WD (laughs).



Watanabe: The cockpit area only has a control stick and instruments.



Hiraki: I think Rival has a natural talent for sensing the condition of the aircraft.



Kotani: That&#39;s why it falls so easily (laughs).



-Does Rival&#39;s personality change when he wears glasses?



Hiraki: It has nothing to do with the glasses, but I love fighting so much that when I&#39;m in that situation, I lose my mind. Just like there are people whose personalities change when they get behind the wheel. Well, when you become a gunner like him, you get requests from all over the place, and you go anywhere, not just to your base in Neige, but depending on the occasion, but you only do jobs that satisfy your desires. For Rival, the standard for whether or not to accept a job is not the amount of money, but how much it will entertain him.



Enomura: It&#39;s just a part of my hobby.



Hiraki: Also, there seems to be some kind of power dynamic in the underworld, and we end up taking on Yabame work as well.



-Are you &quot;that person&quot; from Rival&#39;s story?



Hiraki: I can&#39;t go into details, but that person is involved with the parts built into the perpetual motion machine.



-Was the perpetual motion machine made by Horloger?



Hiraki: Horloger only invested in the development of the perpetual motion machine, but it was actually made by someone else.



Watanabe: I&#39;m sure the person who asked Rival to retrieve the perpetual motion machine was at Merville&#39;s party.



- Now that I think about it, if you look closely at the prologue illustration, you can see that Vantles is riding on Merviille from the very beginning, right?



Hiraki: Yes, he was. However, he was not at the party where they were showing off the perpetual motion machine, but in the VIP room.



-Is Vantle&#39;s face not well known?



Hiraki: He&#39;s a special celebrity, known only to those in the know. He went on a rampage for a year 10 years ago and then disappeared for a while, so most people have forgotten about him because of the time gap. I guess you&#39;d remember him if you heard about him. Also, he&#39;s probably famous in the areas where he went on a rampage, but he&#39;s probably not well known in other places.



- Did Inspector Ardi, who was guarding the party venue, only know Vantle&#39;s name?



Enomura: Actually, I had contacted him once before. I missed it then.



Hiraki: Even in the game, Ardi couldn&#39;t enter the VIP room at Merville, so he didn&#39;t meet Vantor. He was just in charge of security at the venue.



Watanabe: Well, Vantle also used a false name when he boarded the Merville. Vantle is a troublesome character because he became more and more mellow as I was drawing him (laughs).



Kotani: It looks great from any angle.



Watanabe: His tail has gotten older and lost its firmness.



Kotani: When you were young, you had a beautiful tail.



--Was Vantle always rich? Or did he become rich after the legendary incident ten years ago?



(left side concept art for rival and ventre)



Rival&#39;s defining feature is the dramatic difference between how he looks with and without his glasses.



An early design for Rival. His two-tone black and white outfit reflects his dual personality, and remains the same to this day.



Vantor, who is a great lover of art, also seems to be particular about his hairstyle. Apparently, he had a full head of hair when he was young...



An early design by Ventre, characterized by clothing using pocket watches instead of buttons.



The character design was based on the concept of &quot;being able to draw a portrait.&quot; Vantle is based on this shape.



(end left side concept art for rival and ventre)



141



Hiraki: He was originally incredibly wealthy.



- Just like Rival finds meaning in fighting, is Vantor the type of person who finds meaning in his life through a career of crime?



Hiraki: No, he&#39;s not that type of person.



Kotani: In a word, he is the king of materialistic desires.



Hiraki: It&#39;s not that they&#39;re stealing with any malicious intent, but for example, if they want a perpetual motion machine, they start to think that it&#39;s theirs at that point. So they think it&#39;s strange that they don&#39;t have it, and they take it.



Watanabe: In Ventre, it would probably be cheaper to buy things with money (laughs). In scene 3 of Copain, Exoze steals a street lamp, which is for decorating Grand Magasin. If you look closely at Grand Magasin in scene 5, you can find the street lamp.



Nakamura: In scene 3 of Fam, when the telescope is stolen by the mechanical genius Vaincour, it is also installed in the Grand Magasin.



Hiraki: The Vaincourt itself was stolen. The Squret was also stolen using Poulet. However, he didn&#39;t use it as is, but made some modifications to the design himself.



- Is it Poulet who actually assembles the weapons that Vantle uses?



Hiraki: I guess Vantles drew up the blueprints and then let Poulet build it.



-Is Poulet the only one of Vantor&#39;s subordinates?



Hiraki: The only minion I have is Poulet. It&#39;s lonely (laughs).



Watanabe: Well, maybe he does have a family. In the planning stage, he had a granddaughter.



-Vantl, you have a family, don&#39;t you?



Hiraki: If we don&#39;t do that, the characters will become dark.



Kotani: Although he may look like that, he&#39;s actually a very gentle grandfather when it comes to his grandchildren.



Hiraki: I wonder. They&#39;re the Vantor family, so I guess everyone in the family is a little off (laughs).



-There are different stages in Poulet, right?



Hiraki: There are two butler Poulets who report directly to Vantle. They are in charge of taking care of Vantle, making tea and answering the phone, but they are the top Poulets, and below them are many combat Poulets. And if a regular Poulet performs well, they will be treated as a Captain Poulet.



-Do each Poulet have its own personality and feelings?



Hiraki: Yes there is.



- If Poole achieves success in a mission, he will be promoted, right? Conversely, can he be demoted?



Hiraki: Of course there are. If the captain poole slacks off too much, he gets scolded and becomes an ordinary poole.



Kotani: They say &quot;It&#39;s a bonus!&quot; but I wonder what they&#39;ll use the money for. Do they even get holidays?



Watanabe: What do you think?



-How many Poulets are there?



Watanabe: There are over 100 now.



Hiraki: In reality, there will probably be way more than 100 of them in the game (laughs).



- The Phules are perfect automata (fully automated dolls) and therefore will never break down, but what happens to the Phules that sink into the water?



Watanabe: I&#39;m sure he&#39;ll find a way to return to Lord Vantre on his own.



Hiraki: I like walking along the bottom of the water.



-Even though Poulet loses to Ciel and the others and the plan fails, he doesn&#39;t learn from it, right?



Kotani: Because he&#39;s a perfect automaton (laughs).



Hiraki: For Ventre, the poulet is already perfect when it is made. He says that he doesn&#39;t need to learn anything new because it&#39;s already perfect.



Kotani: If we were to improve it, it would no longer be perfect. That&#39;s why I don&#39;t want to do that (laughs).



(image right side poulet concept)

An explanation of how the Triborns are launched from the pods of Premier and Progre. Although it was not realized in the game, various actions for Poule were planned.



A rejected performance from Prog C.

The engine cooling pulley is cute.



Poole&#39;s job is tough, too, as he is caught in an explosion and sinks into the ocean.



Dear Ventre,



A scrapped scene when Exozeled is shot down.



Surface combat squadron spec Poole. Features diving goggles.



A bomb that looks just like an angry Vantle is thrown by Poulet, who is piloting the Exoze Blue.



Originally, the tank was designed for Poulet to ride in with his face sticking out, but the designs were split into two types: regular tanks and Poulet tanks.



(end image right side poulet concept)


142



Hiraki: In the game, Ciel and the others are excellent and drive Phule away, but when it comes to fighting regular gunners, Phule is actually stronger.



Watanabe: In fact, Poulet and his friends had defeated the rank-and-file Gunners 10 years ago. Just before Vantle began his activities, he created Poulet, and used it to wreak havoc for a year. At that time, Inspector Ardi had a fight with Vantlet. However, Ardi never saw Vantlet&#39;s face directly. At the time, Ardi was a police inspector, but he didn&#39;t have much authority. If he had been an inspector at that time, he might have been able to catch him. After that, Vantlet stopped his activities abruptly. I wonder why? Maybe he had been creating the Grands Magasins around that time?



Hiraki: It took 10 years to build, but it was torn down in just one night (laughs).



Enomura: Ardi, you were a police inspector 10 years ago, so does that mean you were a gunner when you were in your 20s?



Watanabe: Why did Ardi quit being a Gunner? When you think about it, he&#39;s also a mysterious person.



Kotani: I think being a gunner is a job that you can only do when you&#39;re young. You&#39;re a rootless person. My wife told me, &quot;You should quit being a gunner soon&quot; (laughs).



Watanabe: Now that I think about it, when I first drew Ardi&#39;s daughter, Fille, her hair didn&#39;t have any curls at the back. I added that later (laughs).



Hiraki: This one also had many twists and turns before it was completed.



Kotani: The curls in the back of my hair are proof of Ardi&#39;s DNA (laughs). People around me probably say, &quot;Wow, Fille, you look just like your dad!&quot; (laughs).



==Unique Meganics and Worldview==



-Was Watanabe also in charge of the mechanics?



Watanabe: Not all of it. Maybe about half? I first drew the main character&#39;s robot, thinking that this design might be good, and then worked it out with the modeler who actually made the model. We made a three-dimensional object.



Hiraki: We went through a lot of different processes. To be honest, I don&#39;t know much about the actual aircraft. I think that because I don&#39;t know much about it, I was able to use only the best parts of the airplane freely and conveniently.



Watanabe: I put a lot of emphasis on appearance. Each unit has a certain concept and is designed with that as a motif. Avenir is a sky &quot;gunner&quot;, so it was decided that it would be equipped with a big gun. Chevalier is a unit created with the image of a knight&#39;s armor and spear. Blanche is a wing.



-How advanced is the technology in that world compared to Earth?



Hiraki: I imagine it to be around the 19th century industrial revolution. So steel frames were a new culture.



-Are flying machines a common thing?



Hiraki: That&#39;s right. In terms of civilization, it&#39;s a world where air routes have developed before land and sea routes. That&#39;s why flying boats and airships are very common. People fly casually to get to nearby places. In the game, ordinary planes only appear in the opening animation.



Kotani: It&#39;s a world where flying isn&#39;t difficult, and if you try, you&#39;ll somehow be able to fly.



Hiraki: I don&#39;t really understand the logic behind it, but that&#39;s how it is (laughs).



-There are many flying boats that take off and land from water, but are there any airplanes that need runways?



Hiraki: Yes. This time, the setting happened to be the waterside town of Leev, so there are a lot of flying boats, but you can think of it as having many different types of flying machines.



-What are the origins of the names of the characters and aircraft?



Hiraki: The name is based on French, but I only took the sounds and changed the spelling of words that were difficult to read. I put a lot of thought into finding a name that was short, had a nice ring to it, and would stick in your mind right away.



Kotani: The meaning is pretty straightforward though. For example, the tank is a Char. It&#39;s exactly the same (laughs).



(left side hardi and fille concept art)



Ardi&#39;s early design features a striking combination of hat and pipe. It makes him look more like a great detective than a skilled police inspector.



Ardi&#39;s only daughter, Fille. Apparently the initial design had a slightly different hairstyle.



(end left side hardi and fille concept art)



(left side japanese to french words)



Origin of the name



Ciel Sky

Copan Partner

Femme Female

Rival Rival

Aldi Bold

Ventre belly

Phule Hina

Fille Daughter



Avenir Future

Chevalier Knight

Blanche Trendsetter

Phantom

Courage Courage



Premier First

Progressive Evolution

Dernier Last



Exoze Flying Fish

Detroit Strait

Stable

Gauche Right

Dorwat Left

Balane Whale

Grand Deal Big

Dorado Sea Bream

Char Tank

Skeleton

Grands Magasins Department Store

Vaincourt Victor

Fidele the faithful dog

Serge the Dog

Guard Dog



Artisan

Associate Workmate

Horloger Watch Shop

Durr Hard



Ordor Order

Merville is amazing

Oblon Horizontal

Allongée Long and thin

Etrois narrow width



(end left side japanese to french words)



143



Nakamura: I wonder if when a French person plays the game, when Gauchet and Droite fight, they&#39;ll say, &quot;Knock him down on the right! Knock him down on the left!&quot; (laughs)



== Directly taught by the development staff? Amazing bounty hunting techniques ==



-What are some tips for bounty hunting?



Hiraki: Above all, don&#39;t fall. If you fall, your prize money will decrease significantly, and the most painful thing is that you will lose time and your bonus count will decrease. If you lose your balance and the warning sound goes off, don&#39;t push yourself.



--Please tell us the key points for bounty hunting for each character. Let&#39;s start with Fam.



Hiraki: There are some pretty important points in Scene 4 for Fam. It&#39;s in the way she fights the defense force in the first half and in the final fight against Scret.



-When you&#39;re taking out the defenders, do you go off on a side trip and take out the fortress batteries?



Hiraki: That&#39;s right. It&#39;s true that you can earn a good amount from the artillery, but it&#39;s enough if you only destroy the parts that are involved in the chain. From an efficiency standpoint, what&#39;s more important is to raise the bonus multiplier to about x30 with Detrois in the moat in order to earn money in the later battle with Scret. In that state, if you destroy the twin machine guns on Scret and get a chain, you can earn 6 to 7 million in one go. When the bonus multiplier starts to drop, there&#39;s a place further back where tanks appear, so you can raise the bonus multiplier there in the same way and earn money with the chain again. Either way, it&#39;s all about how much you can raise the multiplier in a short amount of time.



-What is Ciel&#39;s key to earning money?



Hiraki: It&#39;s harder to earn points with Ciel than with other characters. Battles like the one against the group of Star Bulls in Scene 2 are key. Quickly kill a few of them with Cross Missiles to increase the bonus multiplier, then destroy the side turrets with Fireworks Missiles to earn points with hits. Using different optional weapons is key. Another thing to consider is the chains at the Grand Magasin command tower in Scene 5. Instead of chaining from the lower turret, chain from the upper turret downwards, limiting damage to the command tower while chaining multiple times.



Nakamura: If you keep doing it for too long you&#39;ll run out of fuel though.



Hiraki: In scene 5, if your teammates destroy the couplers, you won&#39;t be able to earn a lot of bounty money. If Rival comes, you have to shoot him down right away and destroy all the couplers yourself. Fam and Copan are just randomly targeting different places.



-How to make money with Copan?



Hiraki: The first condition is to succeed in the turret destruction mission in scene 4 as efficiently as possible. When Exoze Gold and Exoze Red become round in scene 3, it is also important to get as many child units involved as possible and defeat them with pumpkin bombs. If you do it well, you can earn 12 to 14 hits. There is no point in doing it if you don&#39;t increase the bonus multiplier, so until then, defeat child units, Detrois, and assault pods to earn bonus counts. After defeating the assault pod in scene 2 to increase the bonus multiplier, it is also recommended to earn points by defeating the squadron of Premiers that fired the pods all at once. These Premiers fly in a straight line, so if you roll them all together, you can get 7 to 10 hits.



-That Premiere isn&#39;t available at Target, is it?



Hiraki: That&#39;s right. But you can&#39;t make much money unless you can avoid killing invisible enemies. The key to Copan is how to use rolling. Also, the explosion range of the fireworks missiles when locked on is wide, so when Premier&#39;s large force comes to attack the cargo ship in Scene 2, instead of hitting them with fireworks missiles from the front, you can lock on from behind and catch most of them in one go. It&#39;s also important to kill Baleine before Ciel.



Kotani: I can&#39;t find my target (laughs). Are you looking for it?



Hiraki: Yes. Look and search. If you roll into the Valene, most of its parts will break, so turn around and roll into it again to avoid overheating. Then hurry back to the assault pod and earn money. Once you get used to it, you&#39;ll know where everything is, and you&#39;ll be able to earn a lot more.



Nakamura: I didn&#39;t know that (laughs).



Hiraki: If you try different things out of the ordinary, you&#39;ll be surprised at how much money you can make. We&#39;ve made it so that it doesn&#39;t become just a pattern game, so try to discover different things and increase your winnings.



==About the sequel==



-Do you have any plans for your next project?



Nakamura: Yes. We&#39;re still in the process of preparing it though.



Hiraki: There are a lot of things I want to do. Personally, I think it&#39;s still not complete.



Kotani: To be honest, I really wanted to play the game with multiple players at the same time.



Nakamura: That&#39;s when it becomes a battle for points. Teammates start shooting at each other (laughs).



Enomura: Chevalier&#39;s rolling is the strongest, isn&#39;t it? (laughs) In terms of 3D shooting, I can see a sense of exhilaration that I&#39;ve never seen before, so I feel like the world is about to open up even more.



Kotani: With a game, even a slight change in the flow or rhythm changes the necessary elements, right? I feel like there are still a lot of parts of the system borrowed from existing games, so there&#39;s still a bit of dissonance. Now that I understand what I can do, I&#39;d like to pursue a system that matches that. I hope I can make it even more fun by taking advantage of this experience. But I guess I&#39;m also half interested in making a sequel, and half interested in making a different work.



Enomura: If I can come up with a good idea, I guess? I&#39;m not sure if my next work will be a continuation of &quot;Sky Gunner&quot;. But this world has a unique atmosphere and is very attractive, so I&#39;d like to keep it as much as possible.



Kotani: I&#39;d like to continue making games that don&#39;t involve blood (laughs).



























`;
