import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "semantic-ui-css/semantic.min.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import $ from "jquery";
import {
  Layout,
  Homepage,
  Media,
  Comic1,
  Comic2,
  Comic3,
  Comic4,
  Encyclopedia,
  OpeningTranslation,
  TakeTwoTranslation,
  Manga,
  Hentai,
  MagazineZAugust,
  MagazineZSeptember,
  FAQ,
  DeveloperInterview,
  UnlockPhotos,
} from "./App";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById("root"));
export default function Main() {
  return (
    // <React.StrictMode>
    <>
      <Router>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Homepage />} />
            <Route path="manga" element={<Manga />} />
            <Route path="comic-1" element={<Comic1 />} />
            <Route path="comic-2" element={<Comic2 />} />
            <Route path="comic-3" element={<Comic3 />} />
            <Route path="comic-4" element={<Comic4 />} />
            <Route path="magazine-z-august" element={<MagazineZAugust />} />
            <Route
              path="magazine-z-september"
              element={<MagazineZSeptember />}
            />
            <Route path="hentai" element={<Hentai />} />
            <Route path="encyclopedia" element={<Encyclopedia />} />
            <Route path="media" element={<Media />} />
            <Route path="faq" element={<FAQ />} />
            <Route
              path="developer-interview"
              element={<DeveloperInterview />}
            />
            <Route path="unlock-photos" element={<UnlockPhotos />} />
            <Route path="official-lyrics" element={<OpeningTranslation />} />
            <Route path="official-lyrics-2" element={<TakeTwoTranslation />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Route>
        </Routes>
      </Router>
    </>
    // </React.StrictMode>
  );
}

root.render(<Main />);

$(document).ready(function () {
  // $(function () {
  $("#jukebox").css("bottom", "-" + $("#jukebox").height() + "px");
  console.log($("#jukebox").height());
});

function hide_jukebox() {
  var $el = $("#jukebox"); //record the elem so you don't crawl the DOM everytime
  var bottom = $el.css("bottom");
  if (bottom == "0px") {
    $("#jukebox").animate({
      bottom: "-" + $("#jukebox").height() + "px",
    });
  }
}

$(window).click(function (event) {
  var $target = $(event.target);
  var $el = $("#jukebox"); //record the elem so you don't crawl the DOM everytime
  var bottom = $el.css("bottom");

  if (!$target.closest("#jukebox").length && bottom == "0px") {
    hide_jukebox();
  }
});
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
